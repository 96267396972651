<div appPanel (closePanelEmitter)="close(false)"></div>

<div class="modal_content">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles">
      <div class="background -red"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <div class="circle-3"></div>

      <img src="assets/images/illustrations/warning.png" alt="" />
    </div>

    <form [formGroup]="confirmForm" (ngSubmit)="close(true)" class="modal-text form--nnki">
      <div class="modal-picto -picto-svg -red">
        <svg-icon
          src="assets/images/svg-icons/icon-warning.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title -red">
        {{ title }}
      </div>

      <div class="modal-theme">
        <p [innerHTML]="content"></p>

        <ng-container *ngIf="data.textToInput">
          <p appCopyRestricted>
            {{ 'reset.check_sentence' | translate }}&nbsp;:&nbsp;
            <strong>{{ data.textToInput }}</strong>
          </p>

          <div class="row-form-nnki width100">
            <mat-form-field [floatLabel]="'auto'" class="form-field-nnki">
              <mat-label>{{ data.textToInput }}</mat-label>
              <input
                matInput
                formControlName="text"
                type="text"
                (focus)="formUtilsService.onInputFocus('text')"
                (blur)="formUtilsService.onInputBlur('text')"
              />
            </mat-form-field>
            <div class="form-field-error" *ngIf="formUtilsService.showErrorMessage('text')">
              {{ formUtilsService.getErrorMessage('text') }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="modal-buttons">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="submit"
          class="btn-nnki btn-nnki-red btn-nnki-uppercase"
        >
          {{ buttonText }}
        </button>
      </div>

      <div class="h-[60px] sm:hidden"></div>
    </form>
  </div>
</div>
