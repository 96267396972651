import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { EcniTweetsService } from '../../../core/services/ecni-tweets.service';
import { ModalService } from '../../../core/services/modal.service';
import { SanitizerService } from '../../../core/services/sanitizer.service';
import { UserService } from '../../../core/services/user.service';
import { HttpSocialService } from './../../../core/services/http/http-social.service';
import { IsApplicationService } from './../../../core/services/is-application.service';

import { EcniUser } from './../../models/ecni-user.model';

@Component({
  selector: 'app-student-thumbnail',
  templateUrl: './student-thumbnail.component.html',
  styleUrls: ['./student-thumbnail.component.scss'],
})
export class StudentThumbnailComponent implements OnInit {
  @Input() student: EcniUser;
  @Input() waitingRequest = false;

  @Output() waitingRequestProcessed = new EventEmitter();

  constructor(
    public ecniTweetsService: EcniTweetsService,
    public sanitizerService: SanitizerService,
    public userService: UserService,
    public modalService: ModalService,
    private httpSocialService: HttpSocialService,
    public isApplicationService: IsApplicationService
  ) {}

  ngOnInit(): void {}

  approveRequest() {
    if (this.student) {
      this.student.followIsLoading = true;
      this.httpSocialService
        .approve(this.student.id)
        .pipe(
          finalize(() => {
            this.student.followIsLoading = false;
          })
        )
        .subscribe((res) => {
          if (res.success) {
            this.waitingRequestProcessed.emit(this.student);
            this.ecniTweetsService.emitMyUserWaitingChanged(-1);
            this.ecniTweetsService.emitMyUserFollowersChanged(1);
          }
        });
    }
  }

  rejectRequest() {
    if (this.student) {
      this.student.followIsLoading = true;
      this.httpSocialService
        .reject(this.student.id)
        .pipe(
          finalize(() => {
            this.student.followIsLoading = false;
          })
        )
        .subscribe((res) => {
          if (res.success) {
            this.waitingRequestProcessed.emit(this.student);
            this.ecniTweetsService.emitMyUserWaitingChanged(-1);
          }
        });
    }
  }
}
