import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate() {
    if (this.userService.loggedUserValue) {
      if (this.userService.isFullyRegistered()) {
        return true;
      } else {
        this.router.navigate(['/user/register/complete-registration']);
        return false;
      }
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }
}
