import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ModalWarningData, ModalWarningResult } from '../../models/modal-data';

import { ModalActions } from '../../utils/modal-actions';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { confirmWithTextInputValidator } from '../../validators/validators';
import { FormUtilsService } from '../../../core/services/form-utils.service';

@Component({
  selector: 'app-modal-warning',
  templateUrl: './modal-warning.component.html',
  styleUrls: ['./modal-warning.component.scss'],
  providers: [FormUtilsService],
})
export class ModalWarningComponent implements OnInit {
  title;
  content;
  buttonText;
  closeAction;
  routerLink;

  confirmForm = this.formBuilder.group({
    text: [''],
  });

  constructor(
    public dialogRef: MatDialogRef<ModalWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalWarningData,
    private router: Router,
    private formBuilder: NonNullableFormBuilder,
    public formUtilsService: FormUtilsService
  ) {
    this.title = this.data.title;
    this.content = this.data.content;
    this.buttonText = this.data.buttonText;
    this.closeAction = this.data.closeAction;
    this.routerLink = this.data.routerLink;
  }

  ngOnInit() {
    if (this.data.textToInput) {
      this.confirmForm.controls.text.setValidators([
        Validators.required,
        confirmWithTextInputValidator(this.data.textToInput),
      ]);
      this.confirmForm.controls.text.updateValueAndValidity();
    }
    this.formUtilsService.initForm(this.confirmForm);
  }

  close(confirm: boolean) {
    this.formUtilsService.setFormSubmitted();
    if (!this.confirmForm.valid) {
      return;
    }
    if (!this.closeAction) {
      const modalResult: ModalWarningResult = {
        confirm,
      };
      this.dialogRef.close(modalResult);
    } else {
      if (this.closeAction === ModalActions.actionNavigation) {
        this.router.navigate(this.routerLink);
        this.dialogRef.close();
      }
    }
  }
}
