import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'img[appFallback]',
})
export class ImageFallbackDirective implements OnInit {
  @Input() src;
  @Input() appFallback;

  @HostBinding('attr.src') srcBinding;

  constructor() {}

  ngOnInit() {
    this.srcBinding = this.src;
  }

  @HostListener('error', ['$event'])
  onError(): void {
    this.srcBinding = this.appFallback;
  }
}
