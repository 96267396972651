import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';

import { EcniUser } from '../../../models/ecni-user.model';

@Component({
  selector: 'app-friend-invite',
  templateUrl: './friend-invite.component.html',
  styleUrls: ['./friend-invite.component.scss'],
})
export class FriendInviteComponent implements OnInit {
  @Input() user: EcniUser;
  @Input() selected: boolean;

  constructor(public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}
}
