import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

import { TabRoute } from '../../shared/models/tab-route.model';

import { isOnDismissedElement } from '../../shared/utils/events-functions';
import { getLastRouteNode } from '../../shared/utils/router-functions';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  itemChange$ = new BehaviorSubject<number>(-1);
  routeTrigger$: Observable<object>;

  lastRouteChange: Date;

  swiping = false;
  panning = false;

  configuredRoutes: TabRoute[];

  constructor(private router: Router) {}

  setupRouting(configuredRoutes) {
    this.configuredRoutes = configuredRoutes;
    this.routeTrigger$ = this.itemChange$.pipe(
      startWith(-1),
      pairwise(),
      map(([prev, curr]) => {
        this.lastRouteChange = new Date();

        setTimeout(() => {
          this.swiping = false;
        });

        let offsetStart = 0;
        let offsetEnd = 0;
        let time = '0s';

        if (prev !== -1 && curr !== -1 && prev !== curr) {
          time = '0.4s';
          if (prev < curr) {
            offsetEnd = -100;
          } else if (curr < prev) {
            offsetStart = -100;
          }
        }

        return {
          value: curr,
          params: {
            offsetStart,
            offsetEnd,
            time,
          },
        };
      })
    );
  }

  onPan(event) {
    if (isOnDismissedElement(event)) {
      return;
    }

    if (
      !this.swiping &&
      !this.panning &&
      Math.abs(event.velocityX) > Math.abs(event.velocityY) &&
      event.distance > 30
    ) {
      this.panning = true;
      if (event.direction === 4) {
        if (this.itemChange$.getValue() > 0) {
          this.router.navigate(this.configuredRoutes[this.itemChange$.getValue() - 1].routerLink);
          this.swiping = true;
        }
      } else if (event.direction === 2) {
        if (this.itemChange$.getValue() < this.configuredRoutes.length - 1) {
          this.router.navigate(this.configuredRoutes[this.itemChange$.getValue() + 1].routerLink);
          this.swiping = true;
        }
      }
    }

    if (event.isFinal) {
      this.panning = false;
    }
  }

  slideEnded() {
    this.itemChange$.next(this.itemChange$.getValue());
  }

  getIndexOfTab(id: string) {
    if (!this.configuredRoutes) {
      return null;
    }
    return this.configuredRoutes.findIndex((route) => {
      return route.id === id;
    });
  }

  getLastRouteNodeData(activatedRoute: ActivatedRoute | ActivatedRouteSnapshot) {
    const lastNode: ActivatedRoute = getLastRouteNode(activatedRoute) as ActivatedRoute;
    if (lastNode) {
      return lastNode.data;
    } else {
      return of(null);
    }
  }

  get lastRouteRouterEvents() {
    return this.router.events.pipe(
      filter((event) => {
        return event instanceof RoutesRecognized;
      }),
      filter((event: RoutesRecognized) => {
        return !!(event && event.state && event.state.root);
      }),
      map((event: RoutesRecognized) => {
        return this.getLastRouteNodeData(event.state.root);
      })
    );
  }
}
