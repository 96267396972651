export function handleApiError(err) {
  if (err.error && err.error.errors) {
    let returnError = '';
    err.error.errors.forEach((error) => {
      returnError += error + ' ';
    });
    return returnError;
  } else if (err.error && err.error.error) {
    return err.error.error;
  } else if (err.error && err.error.message) {
    return err.error.message;
  } else {
    return null;
  }
}
