import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Dialog } from '@capacitor/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { GoogleAnalyticsService } from './google-analytics.service';
import { HttpVersionService } from './http/http-version.service';
import { IsApplicationService } from './is-application.service';
import { LanguageService } from './language.service';

declare const cordova: any;
@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  newVersionAvailable = false;
  newBreakingVersionAvailable = false;

  newVersion: string;
  newVersionAvailableDismissed = false;

  constructor(
    private router: Router,
    private isApplicationService: IsApplicationService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private httpVersionService: HttpVersionService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  init() {
    if (this.isApplicationService.isApplication()) {
      // check every hour if a new version is available / first check now
      timer(0, 3600 * 1000).subscribe(() => {
        this.checkNewVersionAvailable();
      });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.newBreakingVersionAvailable) {
          // Gère le cas ou l'utilisateur revient du store sans avoir mis à jour l'app
          document.addEventListener('resume', () => {
            this.openVersionUpdateForce();
          });
          this.openVersionUpdateForce();
        } else if (this.newVersionAvailable && !this.newVersionAvailableDismissed) {
          this.openVersionUpdateWarn();
          this.newVersionAvailableDismissed = true;
        }
      });
    }
  }

  checkNewVersionAvailable() {
    this.httpVersionService.checkVersion(environment.version).subscribe((res) => {
      if (res.success && res.version.new_mobile_available) {
        this.httpVersionService.getCurrent().subscribe((resVersion) => {
          if (resVersion.success) {
            this.newVersion = resVersion.version.version_mobile;

            if (res.version.new_breaking_mobile_available) {
              this.newBreakingVersionAvailable = true;
            } else if (res.version.new_mobile_available) {
              this.newVersionAvailable = true;
              this.newBreakingVersionAvailable = false;
            } else {
              this.newVersionAvailable = false;
              this.newBreakingVersionAvailable = false;
            }
          }
        });
      } else {
        this.newVersionAvailable = false;
        this.newBreakingVersionAvailable = false;
      }
    });
  }

  openVersionUpdateWarn() {
    forkJoin([
      this.translate.get('version.new'),
      this.translate.get('version.nouvelle', {
        new: this.newVersion,
        current: environment.version,
      }),
      this.translate.get('version.telecharger'),
      this.translate.get('version.plus_tard'),
    ]).subscribe(([trad1, trad2, trad3, trad4]) => {
      Dialog.confirm({
        title: trad1,
        message: trad2,
        okButtonTitle: trad3,
        cancelButtonTitle: trad4,
      }).then((confirmResult) => {
        if (confirmResult.value) {
          this.openMarket(false);
        } else {
          this.googleAnalyticsService.sendEvent(
            'refuse update app',
            'update',
            'from ' + this.newVersion + ' to last available'
          );
        }
      });
    });
  }

  openVersionUpdateForce() {
    forkJoin([
      this.translate.get('version.new'),
      this.translate.get('version.nouvelle2', {
        new: this.newVersion,
        current: environment.version,
      }),
      this.translate.get('version.telecharger'),
    ]).subscribe(([trad1, trad2, trad3]) => {
      Dialog.alert({
        title: trad1,
        message: trad2,
        buttonTitle: trad3,
      }).then(() => {
        this.openMarket(true);
      });
    });
  }

  openMarket(force: boolean) {
    let appId = '';
    if (this.isApplicationService.isAppAndroid()) {
      appId = this.languageService.googlePlayAppId;
    } else if (this.isApplicationService.isAppIos()) {
      appId = this.languageService.appStoreAppId;
    }

    this.googleAnalyticsService.sendEvent(
      'accept update app',
      'update',
      'from ' + this.newVersion + ' to last available'
    );

    cordova.plugins.market.open(appId);

    if (force) {
      this.openVersionUpdateForce();
    }
  }
}
