import { Injectable } from '@angular/core';

import { Camera, CameraResultType } from '@capacitor/camera';
import { finalize } from 'rxjs/operators';

import { HttpUserService } from './http/http-user.service';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  loadingUpload = false;

  constructor(private userService: UserService, private httpUserService: HttpUserService) {}

  uploadAvatarDesktop(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.loadingUpload = true;
      const file: File = fileList[0];

      this.httpUserService
        .uploadAvatarFormData(file)
        .pipe(
          finalize(() => {
            this.loadingUpload = false;
          })
        )
        .subscribe(() => {
          this.userService.updateUser();
        });
    }
  }

  uploadAvatarMobile() {
    this.loadingUpload = true;

    Camera.getPhoto({
      quality: 50,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      width: 800,
      height: 800,
    }).then(
      (picture) => {
        this.httpUserService
          .uploadAvatarBase64(`data:image/jpeg;base64,${picture.base64String}`)
          .pipe(
            finalize(() => {
              this.loadingUpload = false;
            })
          )
          .subscribe(() => {
            this.userService.updateUser();
          });
      },
      (err) => {
        console.error(err);
        this.loadingUpload = false;
      }
    );
  }

  deleteAvatar() {
    this.loadingUpload = true;
    this.httpUserService
      .deleteAvatar()
      .pipe(
        finalize(() => {
          this.loadingUpload = false;
        })
      )
      .subscribe(() => {
        this.userService.updateUser();
      });
  }
}
