import { Challenge, ChallengeCandidate } from './challenge.model';
import {
  parseIntMember,
  parseFloatMember,
  parseBooleanMember,
  parseDateMember,
} from '../utils/parse-functions';
import { Exam } from './exam.model';
import { CandidateAnswer } from './answer.model';
import { Folder } from './folder.model';
import { ExamCustom } from './exam-custom.model';
import { CandidateQuestion } from './question.model';
import { CandidateEcosAnswer } from './ecos.model';

export class Candidate {
  id: number;
  finishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  grade: number;
  gradeMax: number;
  first: boolean;
  positionWorld: string;
  positionPromo: string;
  positionCity: string;
  positionFac: string;

  exam_id: number;

  children: Candidate[];

  candidate_answers: CandidateAnswer[];
  candidate_questions: CandidateQuestion[];

  initialTimeLeft: number;
  timeLeft: number;

  // ecos
  candidate_ecos_answers: CandidateEcosAnswer[];

  constructor(input: any) {
    if (input.grade_max && !input.gradeMax) {
      input.gradeMax = input.grade_max;
    }

    parseIntMember(input, 'id');
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'gradeMax');
    parseBooleanMember(input, 'first');

    parseIntMember(input, 'exam_id');

    parseDateMember(input, 'finishedAt');
    parseDateMember(input, 'createdAt');
    parseDateMember(input, 'updatedAt');

    parseIntMember(input, 'initialTimeLeft');
    parseIntMember(input, 'timeLeft');

    Object.assign(this, input);

    if (this.children) {
      this.children = this.children.map((candidate) => {
        return new Candidate(candidate);
      });
    } else {
      this.children = [];
    }

    if (this.candidate_answers) {
      this.candidate_answers = this.candidate_answers.map((candidate_answer) => {
        return new CandidateAnswer(candidate_answer);
      });
    } else {
      this.candidate_answers = [];
    }

    if (this.candidate_questions) {
      this.candidate_questions = this.candidate_questions.map((elt) => {
        return new CandidateQuestion(elt);
      });
    } else {
      this.candidate_questions = [];
    }
  }
}

export class ExamCandidates {
  first: Candidate;
  currentCandidate: Candidate;
  otherCandidates: Candidate[];
  currentParentCandidate: Candidate;
  userGrade: number;
  folders: Folder[];
  nbCandidates: number;

  nbCandidatesCityFac: number;
  nbCandidatesFac: number;
  nbCandidatesYear: number;

  challenge: Challenge;

  constructor(input: any) {
    parseIntMember(input, 'userGrade');
    parseIntMember(input, 'nbCandidates');

    parseIntMember(input, 'nbCandidatesCityFac');
    parseIntMember(input, 'nbCandidatesFac');
    parseIntMember(input, 'nbCandidatesYear');
    Object.assign(this, input);

    if (this.first) {
      this.first = new Candidate(this.first);
    }

    if (this.currentCandidate) {
      this.currentCandidate = new Candidate(this.currentCandidate);
    }

    if (this.otherCandidates) {
      this.otherCandidates = this.otherCandidates.map((candidate) => {
        return new Candidate(candidate);
      });
    } else {
      this.otherCandidates = [];
    }

    if (this.currentParentCandidate) {
      this.currentParentCandidate = new Candidate(this.currentParentCandidate);
    }

    if (this.folders) {
      this.folders = this.folders.map((folder) => {
        return new Folder(folder);
      });
    } else {
      this.folders = [];
    }

    if (this.challenge) {
      this.challenge = new Challenge(this.challenge);
    }
  }
}

export class RetakeCandidate {
  candidate: Candidate;
  exam: Exam | ExamCustom;

  challengeCandidate: ChallengeCandidate;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.candidate) {
      this.candidate = new Candidate(this.candidate);
    }

    if (input.exam) {
      if (input.exam.code) {
        this.exam = new ExamCustom(this.exam);
      } else {
        this.exam = new Exam(this.exam);
      }
    }

    if (this.challengeCandidate) {
      this.challengeCandidate = new ChallengeCandidate(this.challengeCandidate);
    }
  }
}

export class CorrectionCandidate {
  candidate: Candidate;
  exam: Exam;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.candidate) {
      this.candidate = new Candidate(this.candidate);
    }

    if (this.exam) {
      this.exam = new Exam(this.exam);
    }
  }
}

export class StartExam {
  candidate: Candidate;

  challengeCandidate: ChallengeCandidate;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.candidate) {
      this.candidate = new Candidate(this.candidate);
    }

    if (this.challengeCandidate) {
      this.challengeCandidate = new ChallengeCandidate(this.challengeCandidate);
    }
  }
}
