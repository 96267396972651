import { Injectable } from '@angular/core';

import { timer, BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavbarInfos } from '../../shared/models/menu.model';

import { HttpMenuService } from './http/http-menu.service';
import { LOGIN_STATE, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  checkTimeSubscription: Subscription;
  private notificationsUnread = new BehaviorSubject<NavbarInfos>(null);

  constructor(private userService: UserService, private httpMenuService: HttpMenuService) {}

  init() {
    this.userService.loginState$.subscribe((loginState) => {
      if (loginState === LOGIN_STATE.LOGGED) {
        // update toutes les 5 minutes
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateNotificationsUnread();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.notificationsUnread.next(null);
      }
    });
  }

  updateNotificationsUnread() {
    this.httpMenuService.getNavbarInfos().subscribe((res) => {
      this.notificationsUnread.next(res);
    });
  }

  getNotificationsUnread() {
    return this.notificationsUnread.pipe(
      filter((res) => {
        return res !== null;
      })
    );
  }
}
