import { environment } from '../../../environments/environment';

export class QueryEndpoints {
  static queryCityFacs = `${environment.api_base_url}/cities`;
  static queryFacs = `${environment.api_base_url}/facs`;
  static queryCityYears = `${environment.api_base_url}/years`;
  static queryDisciplines = `${environment.api_base_url}/disciplines`;
  static queryItems = `${environment.api_base_url}/items`;
  static queryNumbers = `${environment.api_base_url}/chiffres`;
}
