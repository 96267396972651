<div class="loader-dots">
  <span
    [style.width.px]="size"
    [style.height.px]="size"
    *ngFor="let dot of [0, 0, 0]"
    [style.marginLeft.px]="size / 2"
    [style.marginRight.px]="size / 2"
    [class.-white]="white"
  ></span>
</div>
