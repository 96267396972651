import { Directive, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Directive({
  selector: '[appBase]',
})
export class BaseDirective implements OnDestroy {
  protected alive$ = new Subject<boolean>();

  constructor() {}

  ngOnDestroy(): void {
    this.alive$.next(false);
  }
}
