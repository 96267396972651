import { parseIntMember } from '../utils/parse-functions';

export class NavbarInfos {
  nbNonRead: number;

  constructor(input: any) {
    parseIntMember(input, 'nbNonRead');
    Object.assign(this, input);
  }
}
