import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  version: string;

  constructor() {}

  init() {
    if (environment.env === 'mobile') {
      this.version = environment.version + '-m';
    } else {
      this.version = environment.version;
    }
  }
}
