class ScreenWidth {
  screenXSMax: number;
  screenSM: number;
  screenSMMax: number;
  screenMD: number;
  screenMDMax: number;
  screenLG: number;

  constructor(SMBreakpoint, MDBreakpoint, LGBreakpoint) {
    this.screenXSMax = SMBreakpoint - 1;
    this.screenSM = SMBreakpoint;

    this.screenSMMax = MDBreakpoint - 1;
    this.screenMD = MDBreakpoint;

    this.screenMDMax = LGBreakpoint - 1;
    this.screenLG = LGBreakpoint;
  }
}

export const screenWidthConstants = new ScreenWidth(768, 992, 1200);
