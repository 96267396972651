import { parseIntMember, parseBooleanMember, parseFloatMember } from '../utils/parse-functions';
import { EcniUser } from './ecni-user.model';

export class LoginPayload {
  username: string;
  password: string;
  client_id: string;
  client_secret: string;
  grant_type: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class RefreshTokenPayload {
  refresh_token: string;
  client_id: string;
  client_secret: string;
  grant_type: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class SocialLoginPayload {
  access_token: string;
  client_id: string;
  client_secret: string;
  grant_type: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class AppleSigninPayload {
  authorization: {
    code: string;
    id_token: string;
    state?: string;
  };

  override?: {
    clientId?: string;
    teamId?: string;
    redirectUri?: string;
  };

  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class RegisterPayload {
  username: string;
  email: string;
  plainPassword: {
    first: string;
    second: string;
  };
  firstName: string;
  lastName: string;
  year: number;
  cityFac: number;
  fac: number;
  block_marketing_mail: boolean;

  constructor(input: any) {
    parseIntMember(input, 'year');
    parseIntMember(input, 'cityFac');
    parseIntMember(input, 'fac');
    Object.assign(this, input);
  }
}

export class PatchPayload {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  picture?: string;
  address?: string;
  cp?: number;
  city?: string;
  country?: string;
  cellphone?: string;
  year?: number;
  cityFac?: number;
  fac?: number;
  current_password?: string;
  plainPassword?: {
    first: string;
    second: string;
  };
  daltonien?: boolean;
  private?: boolean;
  randomAnswers?: boolean;

  constructor(input: any) {
    parseIntMember(input, 'year');
    parseIntMember(input, 'cityFac');
    parseIntMember(input, 'fac');
    Object.assign(this, input);
  }
}

export class ResetEmailPayload {
  username: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class ResetPasswordPayload {
  password: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class LoginResult {
  access_token: string;
  expires_in: number;
  refresh_token: string;

  constructor(input: any) {
    if (input.oauth) {
      if (input.oauth.token) {
        input.access_token = input.oauth.token;
      }
      if (input.oauth.expires) {
        input.expires_in = input.oauth.expires;
      }
      if (input.oauth.refresh_token) {
        input.refresh_token = input.oauth.refresh_token;
      }
    }

    parseIntMember(input, 'expires_in');
    Object.assign(this, input);
  }
}

export class RegisterResult {
  message: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class PatchResult {
  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class ResetEmailResult {
  success: boolean;
  error: string | BooleanConstructor;

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    Object.assign(this, input);
  }
}

export class CheckEmailResult {
  emailUsed: boolean;

  constructor(input: any) {
    parseBooleanMember(input, 'emailUsed');
    Object.assign(this, input);
  }
}

export class CheckUsernameResult {
  success: boolean;
  exists: boolean;

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    parseBooleanMember(input, 'exists');
    Object.assign(this, input);
  }
}

export class UserProfileResult {
  user: EcniUser;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.user) {
      this.user = new EcniUser(this.user);
    }
  }
}

export class UserResult {
  user: EcniUser;
  participation: number;
  success: number;

  constructor(input: any) {
    parseFloatMember(input, 'participation');
    parseFloatMember(input, 'success');
    Object.assign(this, input);

    if (this.user) {
      this.user = new EcniUser(this.user);
    }
  }
}

export class FacebookConnectResult {
  success: boolean;

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    Object.assign(this, input);
  }
}
