import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LinkService } from '../../../core/services/link.service';

import { EcniDocument } from '../../models/ecni-document.model';
import { ItemImage } from '../../models/item.model';
import { ModalQuestionImagesApplicationData } from '../../models/modal-data';

@Component({
  selector: 'app-modal-list-images-application',
  templateUrl: './modal-list-images-application.component.html',
  styleUrls: ['./modal-list-images-application.component.scss'],
})
export class ModalListImagesApplicationComponent implements OnInit {
  images: (EcniDocument | ItemImage)[];

  constructor(
    public dialogRef: MatDialogRef<ModalListImagesApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalQuestionImagesApplicationData,
    private linkService: LinkService
  ) {
    this.images = data.images;
  }

  ngOnInit(): void {}

  openPhotoViewer(image: EcniDocument | ItemImage) {
    this.linkService.openBlank(image.imageUrl);
  }

  close() {
    this.dialogRef.close();
  }
}
