import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/inherited/base/base.component';

import { LanguageService } from './language.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ColorService extends BaseComponent {
  renderer: Renderer2;
  colorScheme: 'light' | 'dark' = 'light';

  userPreference: 'LIGHT' | 'DARK' | 'DEFAULT';
  osPrefersDarkMode: boolean;

  constructor(
    private languageService: LanguageService,
    rendererFactory: RendererFactory2,
    private userService: UserService
  ) {
    super();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init() {
    this.renderer.addClass(document.body, this.languageService.siteThemeClass);

    this.userService.loggedUser$.pipe(takeUntil(this.alive$)).subscribe((res) => {
      this.userPreference =
        res && res.preferences && res.preferences.dark_mode ? res.preferences.dark_mode : 'LIGHT';
      this.updateThemeMode();
    });

    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      this.osPrefersDarkMode = darkMediaQuery.matches;
    } else {
      this.osPrefersDarkMode = false;
    }
    this.updateThemeMode();

    try {
      darkMediaQuery.addEventListener('change', (e) => {
        this.osPrefersDarkMode = e.matches;
        this.updateThemeMode();
      });
    } catch (e1) {
      try {
        // tslint:disable-next-line: deprecation
        darkMediaQuery.addListener((e) => {
          this.osPrefersDarkMode = e.matches;
          this.updateThemeMode();
        });
      } catch (e2) {
        console.error(e2);
      }
    }
  }

  updateThemeMode() {
    if (this.userPreference === 'LIGHT') {
      this.colorScheme = 'light';
    } else if (this.userPreference === 'DARK') {
      this.colorScheme = 'dark';
    } else if (this.osPrefersDarkMode) {
      this.colorScheme = 'dark';
    } else {
      this.colorScheme = 'light';
    }

    if (this.colorScheme === 'dark') {
      this.renderer.addClass(document.body, 'dark-theme-mode');
      this.renderer.removeClass(document.body, 'light-theme-mode');
    } else if (this.colorScheme === 'light') {
      this.renderer.addClass(document.body, 'light-theme-mode');
      this.renderer.removeClass(document.body, 'dark-theme-mode');
    }
  }

  getSiteMainColor() {
    if (this.languageService.isEcni()) {
      return '#0054F2';
    } else if (this.languageService.isPrepSSM()) {
      return '#0ec164';
    } else if (this.languageService.isPrepMIR()) {
      return '#d32030';
    }
  }

  getSiteLightSidebar() {
    if (this.languageService.isEcni()) {
      return '#ebf3fe';
    } else if (this.languageService.isPrepSSM()) {
      return '#dcffeb';
    } else if (this.languageService.isPrepMIR()) {
      return '#ebf3fe';
    }
    return '#ebf3fe';
  }

  getAppGreen() {
    return '#03d1bd';
  }

  getAppGreenThree() {
    return '#0ec164';
  }

  getGreenFive() {
    return '#CDF6F2';
  }

  getAppGreenSix() {
    return '#b4ec51';
  }

  getAppBlueSeven() {
    return '#0054f2';
  }

  getAppBlueEight() {
    return '#008dfa';
  }

  getAppPurpleFour() {
    return '#b471f3';
  }

  getAppPurpleFive() {
    return '#F0E3FD';
  }

  getAppGrayEight() {
    return '#dadfe4';
  }

  getAppOrangeOne() {
    return '#f74e13';
  }

  getAppOrangeTwo() {
    return '#eeb635';
  }

  getAppRed() {
    return '#fc4747';
  }

  getAppRedTwo() {
    return '#f10b28';
  }

  getAppYellowOne() {
    return '#eeb735';
  }

  getTextColor() {
    if (this.colorScheme === 'light') {
      return '#000000';
    } else if (this.colorScheme === 'dark') {
      return '#ffffff';
    }
  }

  getTrainingSuccessColor(success: number) {
    if (success < 20) {
      return this.getAppRedTwo();
    } else if (success < 40) {
      return this.getAppOrangeOne();
    } else if (success < 60) {
      return this.getAppYellowOne();
    } else if (success < 80) {
      return this.getAppGreenSix();
    } else {
      return this.getAppGreenThree();
    }
  }
}
