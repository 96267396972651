import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { filter, map } from 'rxjs/operators';

import {
  Challenge,
  ChallengeAchievement,
  ChallengeHomeRanking,
  ChallengeInvitation,
  ChallengeInvitePostData,
  ChallengePostData,
  ChallengeRankingLevel,
  ChallengeSummary,
} from '../../../shared/models/challenge.model';
import { QueryCollection } from '../../../shared/models/collection.model';
import { EcniUser } from '../../../shared/models/ecni-user.model';
import { ExamCustom, ExamCustomPostData } from '../../../shared/models/exam-custom.model';
import {
  ChallengeInvitationQueryData,
  ChallengeQueryData,
} from '../../../shared/models/query-data.model';
import { ChallengeRanking, ChallengeVictories } from './../../../shared/models/challenge.model';
import { QueryData } from './../../../shared/models/query-data.model';

import { ChallengeEndpoints } from '../../../shared/endpoints/challenge.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpChallengeService {
  constructor(private http: HttpClient) {}

  createExamChallenge(options: ExamCustomPostData) {
    return this.http.post(ChallengeEndpoints.createExamChallenge, options).pipe(
      map((examRaw: any) => {
        const exam = new ExamCustom(examRaw);
        return exam;
      })
    );
  }

  create(examId: number, finishedAt: string) {
    const data: ChallengePostData = {
      examId,
      finishedAt,
    };

    return this.http.post(ChallengeEndpoints.challenges, data).pipe(
      map((res: any) => {
        if (res.success) {
          const challenge = new Challenge(res.data);
          return challenge;
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  inviteUsers(code: string, users: EcniUser[]) {
    const data: ChallengeInvitePostData = {
      users: users
        .map((user) => {
          return user.id;
        })
        .toString(),
    };

    return this.http.post(ChallengeEndpoints.invite(code), data).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  query(options: ChallengeQueryData) {
    return this.http
      .get(ChallengeEndpoints.challenges, {
        params: options as any,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((challengeRaw) => {
            return new Challenge(challengeRaw);
          });
          return new QueryCollection<Challenge>(res);
        })
      );
  }

  getInvitationsForUser(options: ChallengeInvitationQueryData) {
    return this.http
      .get(ChallengeEndpoints.getInvitationsForUser, {
        params: options as any,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((invitationRaw) => {
            const invitation = new ChallengeInvitation(invitationRaw);
            return invitation;
          });
          res.metadata = {
            totalItems: res.data.length,
            totalPages: 1,
            itemsPerPage: 0,
            currentPage: 1,
          };
          return new QueryCollection<ChallengeInvitation>(res);
        })
      );
  }

  acceptInvitation(id: string) {
    return this.http.get(ChallengeEndpoints.acceptInvitation(id));
  }

  refuseInvitation(id: string) {
    return this.http.get(ChallengeEndpoints.refuseInvitation(id));
  }

  getChallenge(code: string) {
    return this.http.get(ChallengeEndpoints.challenge(code)).pipe(
      map((res: any) => {
        if (res.success) {
          const challenge = new Challenge(res.data);
          return challenge;
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  deleteChallenge(code: string) {
    return this.http.delete(ChallengeEndpoints.challenge(code));
  }

  getSummary() {
    return this.http.get(ChallengeEndpoints.getSummary).pipe(
      map((res: any) => {
        if (res.success) {
          const summary = new ChallengeSummary(res.data);
          return summary;
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  getOverallRankings() {
    return this.http.get(ChallengeEndpoints.getOverallRankings).pipe(
      map((res: any) => {
        if (res.success) {
          const ranking = new ChallengeHomeRanking(res.data);
          return ranking;
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  getUserRanking(disciplineId: number | 'overall', userId: number | 'self') {
    return this.http.get(ChallengeEndpoints.getUserRanking(disciplineId, userId)).pipe(
      map((res: any) => {
        if (res.success) {
          const ranking = new ChallengeRanking(res.data);
          return ranking;
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  getUserRankingLevels(userId: number | 'self') {
    return this.http.get(ChallengeEndpoints.getUserRankingLevels(userId)).pipe(
      map((res: any) => {
        if (res.success) {
          res.data = res.data.map((rankingRaw) => {
            const ranking = new ChallengeRankingLevel(rankingRaw);
            return ranking;
          });

          res.data = res.data.filter((ranking) => {
            return ranking.level !== null;
          });

          res.data.sort((a, b) => {
            return a.level > b.level ? -1 : 1;
          });

          return res.data as ChallengeRankingLevel[];
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  getUserVictories(userId: number | 'self') {
    return this.http.get(ChallengeEndpoints.getUserVictories(userId)).pipe(
      map((res: any) => {
        if (res.success) {
          return new ChallengeVictories(res.data);
        } else {
          throw new Error(res.data);
        }
      })
    );
  }

  getAchievements(userId: number, options: QueryData) {
    return this.http
      .get(ChallengeEndpoints.getAchievements(userId), {
        params: options as any,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((raw) => {
            return new ChallengeAchievement(raw);
          });
          return new QueryCollection<ChallengeAchievement>(res);
        })
      );
  }

  likeAchievement(id: string) {
    return this.http.get(ChallengeEndpoints.likeAchievement(id));
  }

  unlikeAchievement(id: string) {
    return this.http.get(ChallengeEndpoints.unlikeAchievement(id));
  }

  shareExamDone(id: number) {
    return this.http.post(ChallengeEndpoints.shareExamDone(id), null);
  }
}
