import { parseIntMember } from '../utils/parse-functions';

export class Fac {
  id: number;
  name: string;
  display: string;
  value: string;

  constructor(input: any) {
    parseIntMember(input, 'id');
    Object.assign(this, input);
    if (this.id) {
      this.value = this.id.toString();
    }

    this.display = this.name;
  }
}
