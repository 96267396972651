import { environment } from '../../../environments/environment';

export class MessageGroupEndpoints {
  static query = `${environment.api_base_url}/message-groups`;
  static unread = `${environment.api_base_url}/message-groups/unread`;

  static messages(code: string) {
    return `${environment.api_base_url}/message-groups/${code}/messages`;
  }

  static read(code: string) {
    return `${environment.api_base_url}/message-groups/${code}/read`;
  }

  static user(code: string, userId: number) {
    return `${environment.api_base_url}/message-groups/${code}/users/${userId}`;
  }
}
