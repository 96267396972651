import {
  parseBooleanMember,
  parseDateFromTimestampMember,
  parseDateMember,
  parseIntMember,
} from '../utils/parse-functions';
import { NavigationExtras } from '@angular/router';

export class EcniNotification {
  id: string;
  title: string;
  description: string;
  longMsg: string;
  iconType: string;
  iconSize: number;
  textLinkShort: string;
  textLink: string;
  templateName: string;

  isRead: boolean;
  sentAt: Date;

  redirectionLink: string;
  redirectionRouterLink: { commands: string[]; extras: NavigationExtras };

  constructor(input: any) {
    parseBooleanMember(input, 'isRead');
    parseDateMember(input, 'sentAt');

    // api typo fix
    if (input.sendAtTime && !input.sentAt) {
      input.sentAt = input.sendAtTime;
      parseDateFromTimestampMember(input, 'sentAt');
    }

    Object.assign(this, input);
  }
}

export class NotificationsPreferences {
  block_mail: number;
  block_push: number;

  block_exam_push: number;
  block_exam_mail: number;
  block_ancrage_push: number;
  block_ancrage_mail: number;
  deactivate_modal_ancrage_today: number;
  block_item_push: number;
  block_item_mail: number;
  block_challenge_push: number;
  block_challenge_mail: number;
  block_article_push: number;
  block_article_mail: number;
  block_marketing_push: number;
  block_marketing_mail: number;

  play_challenge_sound: number;
  dark_mode: 'LIGHT' | 'DARK' | 'DEFAULT';
  use_old_item_number: number;
  additional_time_in_exam: number;
  hide_items_in_exam_preview: number;
}

export class SetNotificationsPreferencesResult {
  success: boolean;

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    Object.assign(this, input);
  }
}

export class BlockNotificationsResult {
  success: boolean;
  error: string;

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    Object.assign(this, input);
  }
}
