import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Numbers } from '../../shared/models/numbers.model';
import {
  Offer,
  PricingBlock,
  PricingSection,
  SortedOffers,
} from '../../shared/models/premium.model';
import { HttpQueryService } from './http/http-query.service';
import { LanguageService } from './language.service';
import { cloneDeep } from 'lodash';
import { HttpPremiumService } from './http/http-premium.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  numbers: Numbers;

  freePricingBlocks: PricingBlock[];
  premiumPlusPricingBlocks: PricingBlock[];
  elitePricingBlocks: PricingBlock[];

  pricingSections: PricingSection[];

  constructor(
    private httpQueryService: HttpQueryService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private httpPremiumService: HttpPremiumService
  ) {
    this.httpQueryService.queryNumbers().subscribe((res) => {
      this.numbers = res;
      this.initPricingBlocks();
      this.initPricingTableSections();
    });
  }

  initPricingBlocks() {
    if (this.languageService.isEcni()) {
      this.freePricingBlocks = [
        {
          title: this.translateService.instant('pricing.exams_a_laffiche_detail'),
        },
        {
          title: this.translateService.instant('item.images'),
        },
      ];
    } else {
      this.freePricingBlocks = [
        {
          title: this.translateService.instant('pricing.exams_a_laffiche'),
        },
        {
          title: this.translateService.instant('item.images'),
        },
      ];
    }

    if (this.languageService.isEcni()) {
      this.premiumPlusPricingBlocks = [
        {
          title: `${this.numbers.examens} ${this.translateService.instant('examens.examens')}`,
        },
        {
          title: `${this.numbers.ecos} ECOS`,
        },
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'pricing.q_trainings'
          )}`,
        },
        {
          title: this.translateService.instant('training.ancrage'),
        },
        {
          title: `${this.numbers.conftube} ${this.translateService.instant('premium.videos')}`,
        },
        {
          title: `${this.numbers.items} ${this.translateService.instant('pricing.cours_premium')}`,
        },
      ];
    } else if (this.languageService.isPrepSSM()) {
      this.premiumPlusPricingBlocks = [
        {
          title: `${this.numbers.examens - this.numbers.conftube} ${this.translateService.instant(
            'examens.examens'
          )}`,
        },
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'pricing.q_trainings'
          )}`,
        },
        {
          title: this.translateService.instant('pricing.ancrage'),
        },
        {
          title: this.translateService.instant('premium.turbo'),
        },
        {
          title: this.translateService.instant('item.images'),
        },
      ];
    } else if (this.languageService.isPrepMIR()) {
      this.premiumPlusPricingBlocks = [
        {
          title: `${this.numbers.conftube} ${this.translateService.instant('premium.videos')}`,
          strong: true,
          text: `+ ${this.numbers.conftube * 5} ${this.translateService.instant(
            'pricing.quiz_video'
          )}`,
        },
        {
          title: `${this.numbers.examens - this.numbers.conftube} ${this.translateService.instant(
            'examens.examens'
          )}`,
        },
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'pricing.q_trainings'
          )}`,
        },
        {
          title: this.translateService.instant('pricing.ancrage'),
        },
        {
          title: this.translateService.instant('item.images'),
        },
      ];
    }

    if (this.languageService.isPrepSSM()) {
      this.elitePricingBlocks = [
        {
          title: `${this.numbers.conftube} ${this.translateService.instant('premium.videos')}`,
          strong: true,
          text: `+ ${this.numbers.conftube * 5} ${this.translateService.instant(
            'pricing.quiz_video'
          )}`,
        },
        {
          title: `${this.numbers.examens - this.numbers.conftube} ${this.translateService.instant(
            'examens.examens'
          )}`,
        },
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'pricing.q_trainings'
          )}`,
        },
        {
          title: this.translateService.instant('pricing.ancrage'),
        },
        {
          title: this.translateService.instant('premium.turbo'),
        },
        {
          title: this.translateService.instant('item.images'),
        },
      ];
    }
  }

  initPricingTableSections() {
    if (this.languageService.isEcni()) {
      this.pricingSections = [
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'sidebar.training'
          )}`,
          icon: 'icon-training',
          rows: [
            {
              title: this.translateService.instant('pricing.mode_ancrage'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.mode_personnalise'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
          ],
        },
        {
          title: `${this.numbers.examens} ${this.translateService.instant('examens.examens')}`,
          icon: 'icon-exams',
          rows: [
            {
              title: this.translateService.instant('pricing.exam_affiche'),
              availableInFree: true,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.tous_examens'),
              text: this.translateService.instant('pricing.list_exams_premium'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.rejouer'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.poser_question'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
          ],
        },
        {
          title: `${this.numbers.ecos} ECOS`,
          icon: 'icon-ecos',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: true,
          rows: [],
        },
        {
          title: `${this.numbers.items} ${this.translateService.instant('pricing.cours')}`,
          icon: 'icon-course',
          rows: [
            {
              title: this.translateService.instant('item.images'),
              availableInFree: true,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.consutables'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.imprimable'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
          ],
        },
        {
          title: `${this.numbers.conftube} ${this.translateService.instant('premium.videos')}`,
          icon: 'icon-video-menu',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: true,
          rows: [],
        },
        {
          title: `${this.translateService.instant('challenge.challenge')}`,
          icon: 'icon-challenge',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: true,
          rows: [],
        },
      ];
    } else if (this.languageService.isPrepSSM()) {
      this.pricingSections = [
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'sidebar.training'
          )}`,
          icon: 'icon-training',
          rows: [
            {
              title: this.translateService.instant('pricing.mode_ancrage'),
              availableInFree: false,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
            {
              title: this.translateService.instant('pricing.mode_personnalise'),
              availableInFree: false,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
          ],
        },
        {
          title: `${this.numbers.conftube} ${this.translateService.instant('premium.videos')}`,
          icon: 'icon-video-menu',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: false,
          availableInElite: true,
          rows: [],
        },
        {
          title: `${this.translateService.instant('premium.turbo')}`,
          icon: 'icon-turbo',
          rows: [
            {
              title: this.translateService.instant('premium.turbo_text'),
              availableInFree: false,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
          ],
        },
        {
          title: `${this.numbers.examens - this.numbers.conftube} ${this.translateService.instant(
            'examens.examens'
          )}`,
          icon: 'icon-exams',
          rows: [
            {
              title: this.translateService.instant('pricing.exam_affiche'),
              availableInFree: true,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
            {
              title: this.translateService.instant('pricing.tous_examens'),
              text: this.translateService.instant('pricing.list_exams_premium'),
              availableInFree: false,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
            {
              title: this.translateService.instant('pricing.rejouer'),
              availableInFree: false,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
            {
              title: this.translateService.instant('pricing.poser_question'),
              availableInFree: false,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
          ],
        },
        {
          title: `${this.translateService.instant('pricing.cours')}`,
          icon: 'icon-course',
          rows: [
            {
              title: this.translateService.instant('item.images'),
              availableInFree: true,
              availableInPremiumPlus: true,
              availableInElite: true,
            },
          ],
        },
        {
          title: `${this.translateService.instant('challenge.challenge')}`,
          icon: 'icon-challenge',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: true,
          availableInElite: true,
          rows: [],
        },
      ];
    } else if (this.languageService.isPrepMIR()) {
      this.pricingSections = [
        {
          title: `${this.numbers.questionsiso} ${this.translateService.instant(
            'sidebar.training'
          )}`,
          icon: 'icon-training',
          rows: [
            {
              title: this.translateService.instant('pricing.mode_ancrage'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.mode_personnalise'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
          ],
        },
        {
          title: `${this.numbers.conftube} ${this.translateService.instant('premium.videos')}`,
          icon: 'icon-video-menu',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: true,
          rows: [],
        },
        {
          title: `${this.numbers.examens - this.numbers.conftube} ${this.translateService.instant(
            'examens.examens'
          )}`,
          icon: 'icon-exams',
          rows: [
            {
              title: this.translateService.instant('pricing.exam_affiche'),
              availableInFree: true,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.tous_examens'),
              text: this.translateService.instant('pricing.list_exams_premium'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.rejouer'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
            {
              title: this.translateService.instant('pricing.poser_question'),
              availableInFree: false,
              availableInPremiumPlus: true,
            },
          ],
        },
        {
          title: `${this.translateService.instant('pricing.cours')}`,
          icon: 'icon-course',
          rows: [
            {
              title: this.translateService.instant('item.images'),
              availableInFree: true,
              availableInPremiumPlus: true,
            },
          ],
        },
        {
          title: `${this.translateService.instant('challenge.challenge')}`,
          icon: 'icon-challenge',
          showAvailable: true,
          availableInFree: false,
          availableInPremiumPlus: true,
          rows: [],
        },
      ];
    }
  }

  getSortedOffers() {
    return this.httpPremiumService.getOffers().pipe(
      map((res) => {
        const sortedOffers: SortedOffers = {
          premium_plus: [],
          elite: [],
          conversion: [],
        };
        res.forEach((offer) => {
          if (offer.name === 'conversion') {
            sortedOffers.conversion.push(offer);
          } else if (offer.subscription) {
            sortedOffers[offer.subscription].push(offer);
          }
        });

        sortedOffers.premium_plus.sort((a, b) => {
          return a.price - b.price;
        });

        sortedOffers.elite.sort((a, b) => {
          return a.price - b.price;
        });

        return sortedOffers;
      })
    );
  }
}
