import { parseIntMember, parseFloatMember } from '../utils/parse-functions';

export class StudentRanking {
  user_id: number;
  pictureUrl: string;
  username: string;
  city: string;
  fac: string;
  year: string;
  grade: number;
  positionWorld: string;
  positionPromo: string;
  positionCity: string;
  positionFac: string;

  constructor(input: any) {
    parseIntMember(input, 'user_id');
    parseFloatMember(input, 'grade');

    Object.assign(this, input);
  }
}

export class FacRanking {
  name: string;
  position: number;
  grade: number;
  nb: number;

  constructor(input: any) {
    parseIntMember(input, 'position');
    parseFloatMember(input, 'grade');
    parseIntMember(input, 'nb');

    Object.assign(this, input);
  }
}
