import { Pipe, PipeTransform } from '@angular/core';
import { NavigationExtras } from '@angular/router';

@Pipe({
  name: 'redirectionLink',
})
export class RedirectionLinkPipe implements PipeTransform {
  transform(value: string): { commands: string[]; extras: NavigationExtras } {
    const parts = value.split('?');

    const commands: string[] = [];
    if (parts[0]) {
      const commandsArray = parts[0].split('/');
      commandsArray.forEach((command) => {
        commands.push(command);
      });
    }

    const extras: NavigationExtras = {
      queryParams: {},
    };
    if (parts[1]) {
      const paramsArray = parts[1].split('&');

      paramsArray.forEach((param) => {
        const paramSplit = param.split('=');
        extras.queryParams[paramSplit[0]] = paramSplit[1];
      });
    }

    return {
      commands,
      extras,
    };
  }
}
