import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

// import * as Sentry from '@sentry/browser';

import { environment } from '../environments/environment';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

// import { filterSentryEvent } from './shared/utils/sentry-filter';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');

const providers: Provider[] = [];

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}
//   handleError(error) {
//     console.error(error);
//     Sentry.captureException(error.originalError || error);
//   }
// }

// if (environment.env === 'prod' || environment.env === 'mobile') {
//   Sentry.init({
//     dsn: 'https://68b87c7e7382460c98962192dcd4f314@sentry.io/2517946',
//     release: 'ecni-front-angular@' + environment.version,
//     environment: environment.env + '-' + environment.defaultLanguage,
//     beforeSend(event) {
//       event = filterSentryEvent(event);
//       return event;
//     },
//   });

//   providers.push({ provide: ErrorHandler, useClass: SentryErrorHandler });
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.env === 'prod',
      registrationStrategy: 'registerWhenStable:10000',
    }),
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
