import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EcniUser } from '../../shared/models/ecni-user.model';
import { ModalCreateChallengeData } from '../../shared/models/modal-data';

import { ModalCreateChallengeComponent } from '../../shared/modals/modal-create-challenge/modal-create-challenge.component';
import { ModalPremiumPlusComponent } from '../../shared/modals/modal-premium-plus/modal-premium-plus.component';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  stackedModals: MatDialogRef<any>[] = [];

  constructor(private userService: UserService, private matDialog: MatDialog) {}

  openModalChallenge(user?: EcniUser) {
    if (!this.userService.isPremiumPlus()) {
      this.matDialog.open(ModalPremiumPlusComponent, {
        maxWidth: '100vw',
      });
      return;
    }

    let modalData: ModalCreateChallengeData;
    if (user) {
      modalData = {
        usersSelected: [user],
      };
    }

    this.matDialog.open(ModalCreateChallengeComponent, {
      maxWidth: '100vw',
      data: modalData,
    });
  }

  addModalToStack(modal: MatDialogRef<any>) {
    this.stackedModals.push(modal);

    setTimeout(() => {
      if (modal.componentInstance.modalId === 'modalUserProfile') {
        this.cleanStackedModals();
      }
    });
  }

  removeModalFromStack(modalToRemove: MatDialogRef<any>) {
    this.stackedModals = this.stackedModals.filter((modal) => {
      return modal.id !== modalToRemove.id;
    });
  }

  cleanStackedModals() {
    let modalProfileCount = 0;
    this.stackedModals.forEach((modal) => {
      if (modal.componentInstance.modalId === 'modalUserProfile') {
        modalProfileCount++;
      }
    });

    if (modalProfileCount > 1) {
      let stopCleaning = false;
      this.stackedModals.forEach((modal) => {
        if (!stopCleaning) {
          modal.close();
        }
        if (modal.componentInstance.modalId === 'modalUserProfile') {
          stopCleaning = true;
        }
      });
    }
  }
}
