import { Injectable } from '@angular/core';

import { Howl } from 'howler';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  sounds: Howl[] = [];

  constructor(private userService: UserService) {}

  initSound(id: string, src: string, loop: boolean, volume?: number) {
    if (!volume) volume = 1;
    if (!this.sounds[id]) {
      this.sounds[id] = new Howl({
        src: [src],
        loop,
        volume,
      });
    }
  }

  playSound(id: string) {
    if (this.sounds[id]) {
      this.sounds[id].play();
    }
  }

  stopSound(id: string) {
    if (this.sounds[id]) {
      this.sounds[id].stop();
    }
  }

  fadeSound(id: string) {
    if (this.sounds[id]) {
      this.sounds[id].fade(1, 0, 5000);
    }
  }

  canPlayChallengeSounds() {
    if (
      !this.userService.loggedUserValue.preferences ||
      this.userService.loggedUserValue.preferences.challenge_play_sound === null ||
      this.userService.loggedUserValue.preferences.challenge_play_sound === true
    ) {
      return true;
    } else {
      return false;
    }
  }
}
