import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { MaintenanceApi } from '../../../shared/models/maintenance.model';

import { MaintenanceEndpoints } from '../../../shared/endpoints/maintenance.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpMaintenanceService {
  constructor(private http: HttpClient) {}

  isMaintenance() {
    return this.http.get(MaintenanceEndpoints.isMaintenance).pipe(
      map((res: any) => {
        return new MaintenanceApi(res);
      })
    );
  }
}
