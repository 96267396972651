import { CookiesService } from './cookies.service';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router, private cookiesService: CookiesService) {}

  sendEvent(action: string, category: string, label: string) {
    gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
}
