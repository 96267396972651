export class QueryCollection<T> {
  metadata: Metadata;
  data: T[];

  constructor(input) {
    Object.assign(this, input);

    if (this.metadata) {
      this.metadata = new Metadata(this.metadata);
    }
  }
}

export class Metadata {
  totalItems: number;
  totalPages: number;
  itemsPerPage: number;
  currentPage: number;

  constructor(input) {
    Object.assign(this, input);
  }
}

export class NameIdModel {
  id: string;
  name: string;
}
