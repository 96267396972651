import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IsDesktopGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (environment.env !== 'mobile') {
      return true;
    } else {
      this.router.navigate(['/user/login']);
    }
    return false;
  }
}
