<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content w-full">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div
    class="modal-inside -image"
    [ngClass]="{ '-zoom-in': currentImageCanBeZoomed, '-zoom-out': currentImageIsZoomed }"
  >
    <button
      *ngIf="images.length > 1"
      class="change-image-btn left-4 sm:mr-4"
      (click)="previousImage()"
    >
      <svg-icon
        src="assets/images/svg-icons/icon-arrow-left.svg"
        [svgStyle]="{ 'width.px': '12' }"
      ></svg-icon>
    </button>

    <div
      class="image-container"
      [ngClass]="{ '-zoom-in': currentImageCanBeZoomed, '-zoom-out': currentImageIsZoomed }"
    >
      <img
        [src]="images[currentImageIndex].url"
        alt=""
        class="displayed-image"
        (click)="toggleZoom()"
        #currentImage
      />
    </div>

    <button
      *ngIf="images.length > 1"
      class="change-image-btn right-4 sm:ml-4"
      (click)="nextImage()"
    >
      <svg-icon
        src="assets/images/svg-icons/icon-arrow-right.svg"
        [svgStyle]="{ 'width.px': '12' }"
      ></svg-icon>
    </button>
  </div>
</div>
