import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';

import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { ModalUpdateData } from '../../shared/models/modal-data';

import { ModalUpdateVersionComponent } from '../../shared/modals/modal-update-version/modal-update-version.component';
import { HttpVersionService } from './http/http-version.service';
import { IsApplicationService } from './is-application.service';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class SiteUpdateService {
  newVersionAvailable = false;
  newBreakingVersionAvailable = false;

  newVersion: string;
  updateContent: string;
  newVersionAvailableDismissed = false;

  constructor(
    private router: Router,
    private isApplicationService: IsApplicationService,
    private matDialog: MatDialog,
    private httpVersionService: HttpVersionService,
    private swUpdate: SwUpdate
  ) {}

  init() {
    if (!this.isApplicationService.isApplication() && environment.production) {
      // check every hour if a new version is available / first check after 1 minute (leave time to sw to download new files)
      timer(60 * 1000, 3600 * 1000).subscribe(() => {
        this.checkNewVersionAvailable();
      });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.newBreakingVersionAvailable) {
          this.openVersionUpdate(true);
        } else if (this.newVersionAvailable && !this.newVersionAvailableDismissed) {
          this.openVersionUpdate(false);
          this.newVersionAvailableDismissed = true;
        }
      });

      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates
          .pipe(filter((versionEvent) => versionEvent.type === 'VERSION_READY'))
          .subscribe(() => {
            this.newVersion = '/';
            this.newVersionAvailable = true;
          });
      }
    }
  }

  checkNewVersionAvailable() {
    this.httpVersionService.checkVersion(environment.version).subscribe((res) => {
      if (res.success && res.version.new_available) {
        this.httpVersionService.getCurrent().subscribe((resVersion) => {
          if (resVersion.success) {
            this.newVersion = resVersion.version.version;
            this.updateContent = resVersion.version.update_content;

            if (res.version.new_breaking_available) {
              this.newBreakingVersionAvailable = true;
            } else if (res.version.new_available) {
              this.newVersionAvailable = true;
              this.newBreakingVersionAvailable = false;
            }
          }
        });
      } else {
        this.newVersionAvailable = false;
        this.newBreakingVersionAvailable = false;
      }
    });
  }

  openVersionUpdate(force: boolean) {
    const modalData: ModalUpdateData = {
      forceUpdate: force,
      currentVersion: environment.version,
      newVersion: this.newVersion,
      updateContent: this.updateContent,
    };

    this.matDialog.open(ModalUpdateVersionComponent, {
      maxWidth: '100vw',
      disableClose: force,
      data: modalData,
    });
  }
}
