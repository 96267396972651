import { ZAPPoint } from './question.model';
import { parseBooleanMember, parseIntMember } from '../utils/parse-functions';

export class Answer {
  id: number;
  content: string;
  type: 'answer.win' | 'answer.fail' | 'answer.need' | 'answer.eliminatory';
  zones: ZAPPoint[][];

  userChecked: boolean;
  randomOrder: number;

  constructor(input: any) {
    parseIntMember(input, 'id');
    parseBooleanMember(input, 'userChecked');
    parseIntMember(input, 'randomOrder');

    if (input.zones) {
      input.zones = input.zones.map((zone) => {
        if (typeof zone === 'string') {
          zone = zone.split(',').map((point) => {
            const coords = point.split(':');
            return {
              x: parseInt(coords[0], 10),
              y: parseInt(coords[1], 10),
            };
          });
        }
        return zone;
      });
    }
    Object.assign(this, input);
  }
}

export class CandidateAnswer {
  question_id: number;
  answerText: string;
  answer_id: number;
  coordinates?: { x: number; y: number };

  correct: 'answer.win' | 'answer.fail' | 'answer.need' | 'answer.eliminatory';

  constructor(input: any) {
    parseIntMember(input, 'question_id');
    parseIntMember(input, 'answer_id');

    Object.assign(this, input);
  }
}
