import { Injectable } from '@angular/core';

import { timer, BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Question } from '../../shared/models/question.model';
import { AncrageProgression } from '../../shared/models/training.model';

import { HttpTrainingService } from './http/http-training.service';
import { LOGIN_STATE, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AncrageService {
  checkTimeSubscription: Subscription;
  previousHour: number;

  private ancrageProgressionSubject = new BehaviorSubject<AncrageProgression>(null);

  constructor(private userService: UserService, private httpTrainingService: HttpTrainingService) {}

  init() {
    this.userService.loginState$.subscribe((loginState) => {
      if (loginState === LOGIN_STATE.LOGGED) {
        this.checkTimeSubscription = timer(60000, 60000).subscribe(() => {
          const now = new Date();
          const hours = now.getUTCHours();
          if (this.previousHour == null) {
            this.previousHour = hours;
          }

          // we just changed from UTC 23h59 to UTC 00h00, so go go update
          if (hours === 0 && this.previousHour === 23) {
            this.updateAncrageProgression();
          }
          this.previousHour = hours;
        });

        this.updateAncrageProgression();
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }

        this.ancrageProgressionSubject.next(null);
      }
    });
  }

  updateAncrageProgression() {
    this.httpTrainingService
      .getAncrageProgression()
      .pipe(
        filter((res) => {
          return res !== null;
        })
      )
      .subscribe((res) => {
        this.ancrageProgressionSubject.next(res);
      });
  }

  getAncrageProgression() {
    return this.ancrageProgressionSubject.pipe(
      filter((res) => {
        return res !== null;
      })
    );
  }

  updateProgressionAfterQuestion(question: Question) {
    if (question.was_planned) {
      const progression = this.ancrageProgressionSubject.getValue();
      if (progression) {
        progression.revisions.total--;
        this.ancrageProgressionSubject.next(progression);
      }
    }
  }

  deleteRevision() {
    const progression = this.ancrageProgressionSubject.getValue();
    if (progression) {
      progression.revisions.total--;
      this.ancrageProgressionSubject.next(progression);
    }
  }
}
