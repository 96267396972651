import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { QueryCollection } from '../../../shared/models/collection.model';
import {
  BlockNotificationsResult,
  EcniNotification,
  NotificationsPreferences,
  SetNotificationsPreferencesResult,
} from '../../../shared/models/notification.model';
import { QueryData } from '../../../shared/models/query-data.model';
import { EcniUser } from './../../../shared/models/ecni-user.model';

import { NotificationEndpoints } from '../../../shared/endpoints/notification.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpNotificationService {
  constructor(private http: HttpClient) {}

  query(options: QueryData) {
    return this.http
      .get(NotificationEndpoints.query, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((raw) => {
            return new EcniNotification(raw);
          });
          return new QueryCollection<EcniNotification>(res);
        })
      );
  }

  read(id: string) {
    return this.http.get(NotificationEndpoints.read(id));
  }

  setPreferences(partialPreferences: Partial<NotificationsPreferences>, user: EcniUser) {
    const preferences: NotificationsPreferences = {
      block_mail:
        typeof partialPreferences.block_mail === 'undefined'
          ? user.preferences?.block_mail
            ? 1
            : 0
          : partialPreferences.block_mail,
      block_push:
        typeof partialPreferences.block_push === 'undefined'
          ? user.preferences?.block_push
            ? 1
            : 0
          : partialPreferences.block_push,

      block_exam_push:
        typeof partialPreferences.block_exam_push === 'undefined'
          ? user.preferences?.block_exam_push
            ? 1
            : 0
          : partialPreferences.block_exam_push,
      block_exam_mail:
        typeof partialPreferences.block_exam_mail === 'undefined'
          ? user.preferences?.block_exam_mail
            ? 1
            : 0
          : partialPreferences.block_exam_mail,
      block_ancrage_push:
        typeof partialPreferences.block_ancrage_push === 'undefined'
          ? user.preferences?.block_ancrage_push
            ? 1
            : 0
          : partialPreferences.block_ancrage_push,
      block_ancrage_mail:
        typeof partialPreferences.block_ancrage_mail === 'undefined'
          ? user.preferences?.block_ancrage_mail
            ? 1
            : 0
          : partialPreferences.block_ancrage_mail,
      deactivate_modal_ancrage_today:
        typeof partialPreferences.deactivate_modal_ancrage_today === 'undefined'
          ? user.preferences?.deactivate_modal_ancrage_today
            ? 1
            : 0
          : partialPreferences.deactivate_modal_ancrage_today,
      block_item_push:
        typeof partialPreferences.block_item_push === 'undefined'
          ? user.preferences?.block_item_push
            ? 1
            : 0
          : partialPreferences.block_item_push,
      block_item_mail:
        typeof partialPreferences.block_item_mail === 'undefined'
          ? user.preferences?.block_item_mail
            ? 1
            : 0
          : partialPreferences.block_item_mail,
      block_challenge_push:
        typeof partialPreferences.block_challenge_push === 'undefined'
          ? user.preferences?.block_challenge_push
            ? 1
            : 0
          : partialPreferences.block_challenge_push,
      block_challenge_mail:
        typeof partialPreferences.block_challenge_mail === 'undefined'
          ? user.preferences?.block_challenge_mail
            ? 1
            : 0
          : partialPreferences.block_challenge_mail,
      block_article_mail:
        typeof partialPreferences.block_article_mail === 'undefined'
          ? user.preferences?.block_article_mail
            ? 1
            : 0
          : partialPreferences.block_article_mail,
      block_article_push:
        typeof partialPreferences.block_article_push === 'undefined'
          ? user.preferences?.block_article_push
            ? 1
            : 0
          : partialPreferences.block_article_push,
      block_marketing_mail:
        typeof partialPreferences.block_marketing_mail === 'undefined'
          ? user.preferences?.block_marketing_mail
            ? 1
            : 0
          : partialPreferences.block_marketing_mail,
      block_marketing_push:
        typeof partialPreferences.block_marketing_push === 'undefined'
          ? user.preferences?.block_marketing_push
            ? 1
            : 0
          : partialPreferences.block_marketing_push,

      play_challenge_sound:
        typeof partialPreferences.play_challenge_sound === 'undefined'
          ? !user.preferences ||
            user.preferences.challenge_play_sound === null ||
            user.preferences.challenge_play_sound === true
            ? 1
            : 0
          : partialPreferences.play_challenge_sound,

      dark_mode:
        typeof partialPreferences.dark_mode === 'undefined'
          ? user.preferences?.dark_mode
            ? user.preferences.dark_mode
            : 'LIGHT'
          : partialPreferences.dark_mode,

      use_old_item_number:
        typeof partialPreferences.use_old_item_number === 'undefined'
          ? !user.preferences ||
            user.preferences.use_old_item_number === null ||
            user.preferences.use_old_item_number === true
            ? 1
            : 0
          : partialPreferences.use_old_item_number,

      hide_items_in_exam_preview:
        typeof partialPreferences.hide_items_in_exam_preview === 'undefined'
          ? user.preferences?.hide_items_in_exam_preview
            ? 1
            : 0
          : partialPreferences.hide_items_in_exam_preview,

      additional_time_in_exam:
        typeof partialPreferences.additional_time_in_exam === 'undefined'
          ? user.preferences?.additional_time_in_exam
            ? 1
            : 0
          : partialPreferences.additional_time_in_exam,
    };

    return this.http.post(NotificationEndpoints.setPreferences, preferences).pipe(
      map((res) => {
        return new SetNotificationsPreferencesResult(res);
      })
    );
  }

  registerDevice(device: string, token: string) {
    return this.http.post(NotificationEndpoints.registerDevice, {
      content: token,
      type: device,
    });
  }

  blockNotifications(token: string) {
    return this.http.get(NotificationEndpoints.blockNotifications, { params: { token } }).pipe(
      map((res) => {
        return new BlockNotificationsResult(res);
      })
    );
  }
}
