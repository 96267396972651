import { Injectable } from '@angular/core';

import { timer, BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChallengeSummary } from '../../shared/models/challenge.model';

import { HttpChallengeService } from './http/http-challenge.service';
import { LOGIN_STATE, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  checkTimeSubscription: Subscription;
  private challengeSummarySubject = new BehaviorSubject<{
    automaticUpdate: boolean;
    challengeSummary: ChallengeSummary;
  }>(null);

  constructor(
    private userService: UserService,
    private httpChallengeService: HttpChallengeService
  ) {}

  init() {
    this.userService.loginState$.subscribe((loginState) => {
      if (loginState === LOGIN_STATE.LOGGED) {
        // update toutes les 5 minutes
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateChallengeSummary(true);
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.challengeSummarySubject.next(null);
      }
    });
  }

  updateChallengeSummary(automaticUpdate: boolean) {
    this.httpChallengeService.getSummary().subscribe((res) => {
      this.challengeSummarySubject.next({
        automaticUpdate,
        challengeSummary: res,
      });
    });
  }

  getChallengeSummary() {
    return this.challengeSummarySubject.pipe(
      filter((res) => {
        return res !== null;
      })
    );
  }
}
