import { parseIntMember, parseBooleanMember, parseDateMember } from '../utils/parse-functions';
import { Fac } from './fac.model';
import { CityFac } from './cityFac.model';
import { Year } from './year.model';

export class EcniUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  pictureUrl: string;

  private: boolean;
  hasPassword: boolean;

  createdAt: Date;
  subStart: Date;
  subEnd: Date;
  subEndMasterclass: Date;
  subEndElite: Date;

  address: string;
  cp: number;
  city: string;
  cellphone: string;
  country: string;

  fac: Fac;
  cityfac: CityFac;
  year: Year;

  paid: boolean;
  daltonien: boolean;
  randomAnswers: boolean;

  facebookAccessToken: string;
  facebookId: string;

  preferences: UserPreferences;

  followed_by_user: boolean;
  followed_by_user_need_approval: boolean;

  followIsLoading: boolean;

  supermemo_enabled: boolean;
  supermemo_migrated_at: Date;
  supermemo_on: boolean;

  constructor(input: any) {
    // api typo fix
    if (input.picture_url && !input.pictureUrl) {
      input.pictureUrl = input.picture_url;
    }

    if (input.fac && !input.fac.id) {
      input.fac = {
        name: input.fac,
      };
    }

    if (input.city && !input.cityfac) {
      input.cityfac = {
        name: input.city,
      };
    }

    if (input.year && !input.year.id) {
      input.year = {
        name: input.year,
      };
    }

    if (input.cityFac && !input.cityfac) {
      input.cityfac = input.cityFac;
    }

    parseIntMember(input, 'id');
    parseIntMember(input, 'cp');
    parseBooleanMember(input, 'paid');
    parseBooleanMember(input, 'daltonien');
    parseBooleanMember(input, 'randomAnswers');

    parseBooleanMember(input, 'private');
    parseBooleanMember(input, 'hasPassword');

    parseDateMember(input, 'createdAt');
    parseDateMember(input, 'subStart');
    parseDateMember(input, 'subEnd');
    parseDateMember(input, 'subEndMasterclass');
    parseDateMember(input, 'subEndElite');

    parseBooleanMember(input, 'randomAnswers');

    parseBooleanMember(input, 'followed_by_user');
    parseBooleanMember(input, 'followed_by_user_need_approval');

    parseBooleanMember(input, 'supermemo_enabled');
    parseDateMember(input, 'supermemo_migrated_at');
    parseBooleanMember(input, 'supermemo_on');

    Object.assign(this, input);

    if (
      (!this.pictureUrl ||
        this.pictureUrl === 'https://files.edn.fr/uploads/users/' ||
        this.pictureUrl === 'https://files.edn.fr/img/someone.jpg' ||
        this.pictureUrl === 'https://files.edn.fr/img/someone.jpg' ||
        this.pictureUrl === 'https://files.ecni.fr/uploads/users/' ||
        this.pictureUrl === 'https://files.ecni.fr/img/someone.jpg' ||
        this.pictureUrl === 'https://files.ecni.fr/img/someone.jpg' ||
        this.pictureUrl === '/img/someone.jpg') &&
      this.id
    ) {
      this.pictureUrl = `assets/images/thumbnails/someone-${(this.id % 4) + 1}.jpg`;
    }

    if (this.fac) {
      this.fac = new Fac(this.fac);
    }

    if (this.cityfac) {
      this.cityfac = new CityFac(this.cityfac);
    }

    if (this.year) {
      this.year = new Year(this.year);
    }

    if (this.preferences) {
      this.preferences = new UserPreferences(this.preferences);
    }
  }
}

export class UserPreferences {
  block_mail: boolean;
  block_push: boolean;

  block_exam_mail: boolean;
  block_exam_push: boolean;
  block_ancrage_mail: boolean;
  block_ancrage_push: boolean;
  deactivate_modal_ancrage_today: boolean;
  block_item_mail: boolean;
  block_item_push: boolean;
  block_challenge_mail: boolean;
  block_challenge_push: boolean;
  block_article_mail: boolean;
  block_article_push: boolean;
  block_marketing_push: boolean;
  block_marketing_mail: boolean;

  challenge_play_sound: boolean;
  dark_mode: 'LIGHT' | 'DARK' | 'DEFAULT';
  use_old_item_number: boolean;
  additional_time_in_exam: boolean;
  hide_items_in_exam_preview: boolean;

  constructor(input: any) {
    parseBooleanMember(input, 'deactivate_modal_ancrage_today');
    parseBooleanMember(input, 'challenge_play_sound');
    parseBooleanMember(input, 'additional_time_in_exam');
    parseBooleanMember(input, 'hide_items_in_exam_preview');

    Object.assign(this, input);
  }
}
