import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTags',
})
export class StripTagsPipe implements PipeTransform {
  transform(html: string, withBr?: boolean): string {
    const tmpDiv = document.createElement('div');
    if (!html) {
      html = '';
    }
    if (withBr) {
      html = html.replace(/<\/p>/gi, '\n</p>');
      html = html.replace(/<\/div>/gi, '\n</div>');
      html = html.replace(/<li>/gi, '<li>- ');
      html = html.replace(/<\/li>/gi, '\n</li>');
      html = html.replace(/<\/br>/gi, '\n</br>');
      html = html.replace(/<\br>/gi, '\n<br>');
    }
    tmpDiv.innerHTML = html;
    return tmpDiv.textContent || tmpDiv.innerText || '';
  }
}
