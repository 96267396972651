import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCopyRestricted]',
})
export class CopyRestrictedDirective {
  @HostBinding('class.select-text') canSelect = true;
  @Input() @HostBinding('class.nnki-no-select') copyBlocked = true;

  constructor() {}

  @HostListener('copy', ['$event'])
  preventCopy(event: Event) {
    if (this.copyBlocked) {
      event.preventDefault();
    }
  }
}
