import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { finalize } from 'rxjs/operators';

import { EcniUser } from './../../shared/models/ecni-user.model';

import { ModalUserProfileComponent } from '../../shared/modals/modal-user-profile/modal-user-profile.component';
import { HttpSocialService } from './http/http-social.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EcniTweetsService {
  public myUserFollowedChangedEmitter = new EventEmitter<number>();
  public myUserFollowersChangedEmitter = new EventEmitter<number>();
  public myUserWaitingChangedEmitter = new EventEmitter<number>();

  constructor(
    private userService: UserService,
    private httpSocialService: HttpSocialService,
    private matDialog: MatDialog
  ) {}

  emitMyUserFollowedChanged(val: number) {
    this.myUserFollowedChangedEmitter.emit(val);
  }

  emitMyUserFollowersChanged(val: number) {
    this.myUserFollowersChangedEmitter.emit(val);
  }

  emitMyUserWaitingChanged(val: number) {
    this.myUserWaitingChangedEmitter.emit(val);
  }

  openUserProfileModale(userId: number) {
    this.matDialog.open(ModalUserProfileComponent, {
      maxWidth: '100vw',
      data: {
        userId,
      },
    });
  }

  follow(user: EcniUser) {
    if (user && this.userService.loggedUserValue.id !== user.id) {
      user.followIsLoading = true;
      this.httpSocialService
        .follow(user.id)
        .pipe(
          finalize(() => {
            user.followIsLoading = false;
          })
        )
        .subscribe((res) => {
          if (res.success) {
            if (res.message === 'APPROVED') {
              user.followed_by_user = true;
              user.followed_by_user_need_approval = false;
              this.emitMyUserFollowedChanged(1);
            } else if (res.message === 'NEED_APPROVAL') {
              user.followed_by_user = false;
              user.followed_by_user_need_approval = true;
            }
          }
        });
    }
  }

  unfollow(user: EcniUser) {
    user.followIsLoading = true;
    this.httpSocialService
      .unfollow(user.id)
      .pipe(
        finalize(() => {
          user.followIsLoading = false;
        })
      )
      .subscribe((res) => {
        if (res.success) {
          user.followed_by_user = false;
          user.followed_by_user_need_approval = false;
          this.emitMyUserFollowedChanged(-1);
        }
      });
  }
}
