import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Device, DeviceInfo } from '@capacitor/device';
import { InAppReview } from '@capacitor-community/in-app-review';
import { timer, BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { HttpPremiumService } from './http/http-premium.service';
@Injectable({
  providedIn: 'root',
})
export class IsApplicationService {
  renderer: Renderer2;

  private isPremiumEnabled$ = new BehaviorSubject<boolean>(true);

  platform: DeviceInfo['platform'];

  shouldRateApp = false;

  isMobileDevice = false;

  constructor(
    private httpPremiumService: HttpPremiumService,
    rendererFactory: RendererFactory2,
    private router: Router
  ) {
    if (this.isApplication()) {
      Device.getInfo().then((info) => {
        this.platform = info.platform;
      });
    }

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    const match = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });

    if (match || this.isApplication()) {
      this.renderer = rendererFactory.createRenderer(null, null);
      this.renderer.addClass(document.body, '-mobile-device');

      this.isMobileDevice = true;

      if (!this.isApplication()) {
        this.renderer.addClass(document.body, '-mobile-browser');
      }
    }

    // si app ouverte depuis +20 minutes : proposer à l'utilisateur de noter à la prochaine navigation
    if (this.isApplication()) {
      timer(1000 * 60 * 20)
        .pipe(first())
        .subscribe(() => {
          this.shouldRateApp = true;
        });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.shouldRateApp) {
          InAppReview.requestReview();
          this.shouldRateApp = false;
        }
      });
    }
  }

  isAppIos() {
    if (this.isApplication()) {
      return !this.platform || this.platform === 'ios';
    } else {
      return false;
    }
  }

  isAppAndroid() {
    if (this.isApplication()) {
      return !this.platform || this.platform === 'android';
    } else {
      return false;
    }
  }

  isApplication() {
    if (environment.env === 'mobile') {
      return true;
    } else {
      return false;
    }
  }

  updateIsIosPremiumEnabled() {
    this.httpPremiumService.isIOSPremiumEnabled().subscribe((res) => {
      this.isPremiumEnabled$.next(res);
    });
  }

  reenablePremium() {
    this.isPremiumEnabled$.next(true);
  }

  get isPremiumEnabled() {
    // return false;
    return this.isPremiumEnabled$.getValue();
  }
}
