import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Metadata } from './../models/collection.model';

import { BaseDirective } from './base.directive';

@Directive({
  selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective extends BaseDirective implements OnInit {
  @Input() scrollContainer: string;
  @Input() scrollContent = 'appComponentContent';

  @Input() inversedScroll = false;

  @Output() needToScroll = new EventEmitter();

  currentPage = 1;
  totalPages: number = null;
  isScrolling = false;
  scrollErrored = false;

  isInitialized = false;

  constructor() {
    super();
  }

  ngOnInit() {
    timer(500, 500)
      .pipe(takeUntil(this.alive$))
      .subscribe(() => {
        if (this.shouldScroll()) {
          this.needToScroll.emit();
        }
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.shouldScroll()) {
      this.needToScroll.emit();
    }
  }

  resetScrollProperties() {
    this.currentPage = 1;
    this.isScrolling = false;

    this.totalPages = null;
    this.scrollErrored = false;
  }

  canScroll() {
    return (
      (this.totalPages === null || this.totalPages >= this.currentPage) &&
      !this.isScrolling &&
      !this.scrollErrored
    );
  }

  initiateScroll() {
    this.isInitialized = true;
    this.isScrolling = true;
  }

  endScroll(metadata: Metadata) {
    if (this.totalPages === null) {
      this.totalPages = metadata.totalPages;
    }

    this.currentPage++;
    this.isScrolling = false;
  }

  scrollHasErrored() {
    this.scrollErrored = true;
    this.isScrolling = false;
  }

  shouldScroll() {
    let scrollContainerComparison: number;

    // le container n'existe pas encore
    if (this.scrollContainer && !document.getElementById(this.scrollContainer)) {
      return false;
    }

    // le container existe
    if (this.scrollContainer && document.getElementById(this.scrollContainer)) {
      scrollContainerComparison =
        document.getElementById(this.scrollContainer).scrollTop +
        document.getElementById(this.scrollContainer).clientHeight;
    }
    // scroll général sur la fenêtre
    else {
      scrollContainerComparison = window.scrollY + window.innerHeight;
    }
    const scrollContent = document.getElementById(this.scrollContent);

    if (!this.inversedScroll) {
      if (scrollContent.clientHeight - scrollContainerComparison <= 200) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        scrollContainerComparison - document.getElementById(this.scrollContainer).clientHeight <=
        200
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  get showInitialLoader() {
    if (!this.isInitialized) {
      return null;
    } else {
      return this.isScrolling && this.currentPage === 1;
    }
  }

  get showScrollLoader() {
    if (!this.isInitialized) {
      return null;
    } else {
      return this.isScrolling && this.currentPage > 1;
    }
  }

  get showLoader() {
    if (!this.isInitialized) {
      return null;
    } else {
      return this.isScrolling;
    }
  }

  get initialized() {
    if (!this.isInitialized) {
      return null;
    } else {
      return this.isInitialized;
    }
  }
}
