export class LocaleKeys {
  static tokenId = 'ECNI_AUTH_id_token';
  static refreshTokenId = 'ECNI_AUTH_id_refresh_token';
  static tokenExpiresAt = 'ECNI_AUTH_id_token_expiration';
  static authProfile = 'ECNI_AUTH_current_user_profile';

  static rememberMe = 'ECNI_PWD_rememberMe';
  static rememberMePassword = 'ECNI_PWD_rememberMe_password2';
  static rememberMeUsername = 'ECNI_PWD_rememberMe_username';

  static maintenanceKilled = 'ECNI_MTN_maintenance_killed';

  static lastVersion = 'ECNI_VERSION_last';

  static cookiesAccepted = 'ECNI_COOKIES_accepted3';
  static cookiesLastRefusedDate = 'ECNI_COOKIES_lastCookiesRefusedDate';

  static notifsDeviceRegistered = 'ECNI_NOTIF_notifs_device_registered';
  static notifsLastRefusedDate = 'ECNI_NOTIF_lastNotificationsRefusedDate';

  static screenshotsLastDate = 'ECNI_SCREENSHOT_lastScreenshotDate';

  static ancrageTodayShowed = 'ECNI_ANCRAGE_ancrageTodayShowed';

  static navigationBeforeLogin = 'ECNI_NAV_navBeforeLogin';
}
