import { Injectable } from '@angular/core';

import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class DisciplinesService {
  constructor(private languageService: LanguageService) {}

  getIconId(id: number) {
    if (this.languageService.isEcni()) {
      return id;
    } else if (this.languageService.isPrepSSM()) {
      if (id === 26) return 32;
      if (id === 42) return 40;
      return id;
    } else if (this.languageService.isPrepMIR()) {
      if (id === 91) return 39;
      if (id === 60) return 8;
      if (id === 93) return 37;
      if (id === 62) return 1;
      if (id === 63) return 28;
      if (id === 64) return 21;
      if (id === 87) return 12;
      if (id === 65) return 2;
      if (id === 66) return 6;
      if (id === 67) return 3;
      if (id === 68) return 27;
      if (id === 61) return 22;
      if (id === 69) return 10;
      if (id === 70) return 4;
      if (id === 88) return 34;
      if (id === 71) return 11;
      if (id === 72) return 13;
      if (id === 73) return 13;
      if (id === 92) return 34;
      if (id === 74) return 29;
      if (id === 75) return 14;
      if (id === 76) return 5;
      if (id === 77) return 15;
      if (id === 78) return 25;
      if (id === 89) return 16;
      if (id === 79) return 17;
      if (id === 80) return 18;
      if (id === 90) return 27;
      if (id === 81) return 19;
      if (id === 85) return 23;
      if (id === 82) return 24;
      if (id === 83) return 7;
      if (id === 86) return 35;
      if (id === 84) return 20;
      if (id === 94) return 41;
    }
  }
}
