import { Observable, BehaviorSubject } from 'rxjs';
import { parseDateFromTimestampMember } from '../utils/parse-functions';
import { EcniUser } from './ecni-user.model';
import { QueryData } from './query-data.model';

export class MessageGroup {
  code: string;
  read: boolean;
  answerContent: string;

  lastMessage: EcniMessage;
  lastMessageTime: Date;
  previewDiff: number;

  previewTo: string;
  previewText: string;

  readBy: number[];

  users: EcniUser[];

  messages: EcniMessage[] = [];

  isLoading: boolean;

  messagesQueryData: QueryData;
  isScrolling: boolean;

  constructor(input) {
    parseDateFromTimestampMember(input, 'lastMessageTime');

    input.users = input.usersData;

    Object.assign(this, input);

    if (this.lastMessage) {
      this.lastMessage = new EcniMessage(this.lastMessage);

      this.previewDiff =
        (new Date().getTime() - this.lastMessageTime.getTime()) / (24 * 3600 * 1000);
    }

    if (this.users) {
      this.users = this.users
        .filter((user) => !!user)
        .map((elt) => {
          return new EcniUser(elt);
        });
    } else {
      this.users = [];
    }

    this.messagesQueryData = {
      perPage: 20,
      page: 1,
    };
  }

  getUserFromMessage(message: EcniMessage) {
    return this.users.find((user) => {
      return user.id === message.userId;
    });
  }

  getUsersWithoutSelf(self: EcniUser) {
    if (self) {
      return this.users.filter((user) => {
        return user.id !== self.id;
      });
    } else {
      return this.users;
    }
  }
}

export class EcniMessage {
  code: string;
  createdAt: Date;
  message: string;
  userId: number;

  constructor(input) {
    parseDateFromTimestampMember(input, 'createdAt');

    input.userId = input.user;

    Object.assign(this, input);
  }
}

export class MessageGroupPostData {
  users: number[];
  message: string;
}
export class MessageGroupAnswerPostData {
  message: string;
}
