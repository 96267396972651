import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Data } from '@angular/router';
import { RoutingService } from './routing.service';
import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private titleService: Title,
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {}

  init() {
    merge(
      this.routingService.getLastRouteNodeData(this.activatedRoute),
      this.routingService.lastRouteRouterEvents
    ).subscribe((nodeData: Data) => {
      let translateKey = 'landing.title';
      if (nodeData?.navbarTitleKey) {
        translateKey = nodeData.navbarTitleKey;
      }
      this.translate.get(translateKey).subscribe((trad) => {
        this.titleService.setTitle(`${this.languageService.siteName} | ${trad}`);
      });
    });
  }
}
