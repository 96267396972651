import { environment } from '../../../environments/environment';

export class NotificationEndpoints {
  static query = `${environment.api_base_url}/notifications/all`;
  static setPreferences = `${environment.api_base_url}/notifications/preferences`;
  static blockNotifications = `${environment.api_base_url}/no-session/notifications/block`;
  static registerDevice = `${environment.api_base_url}/device_tokens`;

  static read(id: string) {
    return `${environment.api_base_url}/notifications/read/${id}`;
  }
}
