<div class="users-pictures-list">
  <div
    *ngFor="let user of users | slice: 0:2"
    class="nnki-rounded-background-image"
    [style.background-image]="sanitizerService.imageProfile(user.pictureUrl)"
  ></div>
  <div class="user-plus" *ngIf="users.length > 2">+{{ users.length - 2 }}</div>
</div>

<div class="truncate leading-loose">
  <ng-container *ngIf="challenge">
    <span class="hidden sm:inline">
      <ng-container *ngFor="let user of users; let index = index">
        <ng-container *ngIf="index != 0">, </ng-container>
        {{ user.username }}
      </ng-container>
    </span>
    <span class="sm:hidden" *ngIf="challenge.examData.disciplines.length">{{
      challenge.examData.disciplines[0].name
    }}</span>
  </ng-container>

  <ng-container *ngIf="invitation">
    <span>{{ 'challenge.te_lance' | translate: { user: users[0].username } }}<br /></span>
    <span class="text-11 sm:hidden" *ngIf="invitation.examData.disciplines.length">
      {{ invitation.examData.disciplines[0].name }}
    </span>
  </ng-container>
</div>
