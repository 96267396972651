import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { concatMap, debounceTime, finalize, takeUntil } from 'rxjs/operators';

import { ChallengeService } from '../../../core/services/challenge.service';
import { FormUtilsService } from '../../../core/services/form-utils.service';
import { HttpChallengeService } from './../../../core/services/http/http-challenge.service';
import { HttpQueryService } from './../../../core/services/http/http-query.service';
import { UserService } from './../../../core/services/user.service';

import { BaseComponent } from '../../components/inherited/base/base.component';

import { Challenge } from '../../models/challenge.model';
import { Discipline } from '../../models/discipline.model';
import { ModalCandidateChallengeData, ModalCreateChallengeData } from '../../models/modal-data';
import { ExamCustom, ExamCustomPostData } from './../../models/exam-custom.model';

import { ModalCandidateChallengeComponent } from '../modal-candidate-challenge/modal-candidate-challenge.component';
import { ModalPremiumPlusComponent } from '../modal-premium-plus/modal-premium-plus.component';

import { handleApiError } from '../../utils/handle-error';

@Component({
  selector: 'app-modal-create-challenge',
  templateUrl: './modal-create-challenge.component.html',
  styleUrls: ['./modal-create-challenge.component.scss'],
  providers: [FormUtilsService, DatePipe],
})
export class ModalCreateChallengeComponent extends BaseComponent implements OnInit {
  customExamFormStep1: UntypedFormGroup;
  customExamFormStep2: UntypedFormGroup;

  isCreating = false;

  disciplinesSearchControl = new UntypedFormControl();
  disciplinesList: Discipline[] = [];

  isScrollingDisciplines = false;
  initialLoadingDisciplines = true;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateChallengeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalCreateChallengeData,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private httpChallengeService: HttpChallengeService,
    private datePipe: DatePipe,
    private httpQueryService: HttpQueryService,
    private challengeService: ChallengeService,
    private formBuilder: UntypedFormBuilder,
    public formUtilsService: FormUtilsService
  ) {
    super();

    this.customExamFormStep1 = this.formBuilder.group({
      selectedDiscipline: [null, [Validators.required]],
    });

    this.customExamFormStep2 = this.formBuilder.group({
      selectedUsersList: [[], Validators.required],
    });
  }

  ngOnInit(): void {
    this.formUtilsService.initForm(this.customExamFormStep1, 'customExamFormStep1');
    this.formUtilsService.initForm(this.customExamFormStep2, 'customExamFormStep2');

    if (!this.userService.isPremiumPlus()) {
      this.matDialog.open(ModalPremiumPlusComponent, {
        maxWidth: '100vw',
      });
      this.close();
      return;
    }

    this.disciplinesSearchControl.valueChanges
      .pipe(takeUntil(this.alive$), debounceTime(500))
      .subscribe((newValue) => {
        this.disciplinesList = [];
        this.customExamFormStep1.patchValue({
          selectedDiscipline: null,
        });
        this.getDisciplines(newValue);
      });

    this.getDisciplines('');
  }

  close() {
    this.dialogRef.close();
  }

  getDisciplines(search: string) {
    if (!this.isScrollingDisciplines) {
      this.isScrollingDisciplines = true;
      this.initialLoadingDisciplines = false;

      this.httpQueryService
        .queryDisciplines({
          sortBy: 'number',
          sortByOrder: 'ASC',
          minNbQuestions: 1,
          search,
        })
        .pipe(
          finalize(() => {
            this.isScrollingDisciplines = false;
          })
        )
        .subscribe((res) => {
          this.disciplinesList = res.data;
        });
    }
  }

  toggleDisciplineSelected(discipline: Discipline) {
    this.customExamFormStep1.patchValue({
      selectedDiscipline: discipline,
    });
  }

  updateSelectedList(selectedList) {
    this.customExamFormStep2.patchValue({
      selectedUsersList: selectedList,
    });
  }

  submitStep1() {
    this.formUtilsService.setFormSubmitted('customExamFormStep1');

    this.customExamFormStep1.controls.selectedDiscipline.setErrors(null);

    if (!this.customExamFormStep1.value.selectedDiscipline) {
      this.customExamFormStep1.controls.selectedDiscipline.setErrors({
        oneDiscipline: true,
      });
      return;
    }
  }

  createChallenge() {
    this.formUtilsService.setFormSubmitted('customExamFormStep2');

    if (!this.customExamFormStep2.value.selectedUsersList?.length) {
      this.customExamFormStep2.controls.selectedUsersList.setErrors({
        atLeastOneUser: true,
      });
      return;
    }

    if (this.customExamFormStep1.valid && this.customExamFormStep2.valid) {
      this.isCreating = true;

      const selectedDiscipline = this.customExamFormStep1.value.selectedDiscipline;

      const examData: ExamCustomPostData = {
        title: this.translate.instant('challenge.challenge') + ' ' + selectedDiscipline.name,
        nbQuestions: 7,
        countdown: Math.ceil(7 * 0.75),
        sortMode: 'none',
        disciplines: [selectedDiscipline.id],
      };

      let createdExam: ExamCustom;
      let createdChallenge: Challenge;

      this.httpChallengeService
        .createExamChallenge(examData)
        .pipe(
          concatMap((exam) => {
            createdExam = exam;

            const now = new Date();
            const endDate = now.setDate(now.getDate() + 1);
            return this.httpChallengeService.create(
              exam.id,
              this.datePipe.transform(endDate, 'yyyy-MM-dd HH:mm:ss')
            );
          }),
          concatMap((challenge) => {
            createdChallenge = challenge;

            return this.httpChallengeService.inviteUsers(
              challenge.code,
              this.customExamFormStep2.value.selectedUsersList
            );
          })
        )
        .subscribe(
          () => {
            this.toastr.success(this.translate.instant('challenge.succes'));
            this.challengeService.updateChallengeSummary(false);
            this.close();

            const data: ModalCandidateChallengeData = {
              examCode: createdExam.code,
              challengeCode: createdChallenge.code,
            };

            this.matDialog.open(ModalCandidateChallengeComponent, {
              maxWidth: '100vw',
              data,
            });
          },
          (err) => {
            this.isCreating = false;

            const error = handleApiError(err);
            if (error === 'ERR_NO_QUESTION_FOUND') {
              this.toastr.warning(this.translate.instant('custom.err_no_question'));
            } else {
              this.toastr.warning(this.translate.instant('util.error'));
            }
          }
        );
    }
  }

  get showDummiesDisciplines() {
    return this.isScrollingDisciplines || this.initialLoadingDisciplines;
  }
}
