import { parseBooleanMember, parseDateMember } from '../utils/parse-functions';

export class MaintenanceApi {
  success: boolean;
  message: string;
  forceMaintenance: boolean;
  start: Date;
  end: Date;

  constructor(input: any) {
    if (input.message === 0) {
      input.message = '0';
    }

    parseDateMember(input, 'start');
    parseDateMember(input, 'end');
    parseBooleanMember(input, 'success');
    parseBooleanMember(input, 'forceMaintenance');

    Object.assign(this, input);
  }
}

export class MaintenanceStatus {
  isMaintenance: boolean;
  endMaintenance?: number;

  constructor(input: any) {
    Object.assign(this, input);
  }
}
