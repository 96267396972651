import { Injectable } from '@angular/core';

import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import {
  CourseFilters,
  DisciplineFilters,
  EcosFilters,
  ExamFilters,
  ExamRankingFilters,
  FolderDoneFilters,
  ItemFilters,
  ItemImagesFilters,
  MultiRankingFilters,
  PodcastFilters,
  ProgressFilters,
  UnitFoldersFilters,
  VideoFilters,
} from './../../shared/models/filters';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  courseFiltersDefault: CourseFilters = {
    newDocUploaded: -1,
    hasConftubes: -1,
    isTodo: -1,
    isFavorite: -1,
    disciplineId: null,
    sortBy: null,
    sortByOrder: null,
    rating: 0,
  };
  courseFiltersState$: BehaviorSubject<CourseFilters>;

  coursesEcosFiltersDefault: CourseFilters = {
    newDocUploaded: -1,
    hasConftubes: -1,
    isTodo: -1,
    isFavorite: -1,
    disciplineId: 42,
    sortBy: null,
    sortByOrder: null,
    rating: 0,
  };
  coursesEcosFiltersState$: BehaviorSubject<CourseFilters>;

  disciplineFiltersDefault: DisciplineFilters = {
    sortBy: null,
    sortByOrder: null,
    ancrage: 0,
  };
  disciplineFiltersState$: BehaviorSubject<DisciplineFilters>;

  itemFiltersDefault: ItemFilters = {
    disciplineId: null,
    sortBy: null,
    sortByOrder: null,
    ancrage: 0,
  };
  itemFiltersState$: BehaviorSubject<ItemFilters>;

  ecosItemFiltersDefault: ItemFilters = {
    disciplineId: 42,
    sortBy: null,
    sortByOrder: null,
    ancrage: 0,
  };
  ecosItemFiltersState$: BehaviorSubject<ItemFilters>;

  itemImagesFiltersDefault: ItemImagesFilters = {
    disciplineId: null,
    itemId: null,
    sortBy: 'createdAt',
    sortByOrder: 'DESC',
  };
  itemImagesFiltersState$: BehaviorSubject<ItemImagesFilters>;

  ecosFiltersDefault: EcosFilters = {
    classification: '',
    progression: '',
    discipline: null,
    item: null,
    price: '',
    sortBy: null,
    sortByOrder: null,
    grade: 0,
  };
  ecosFiltersState$: BehaviorSubject<EcosFilters>;

  examFiltersDefault: ExamFilters = {
    quizz: false,
    'quizz.iso': false,
    'quizz.mmg': false,
    'quizz.prepacademy': false,
    blanche: false,
    concours: false,
    'concours.quizz': false,
    'concorso.ssm': false,
    masterclass: false,
    imagerie: false,
    lca: false,
    annales: false,
    conftube: false,
    isR2C: -1,
    classification: '',
    price: '',
    progression: '',
    disciplineId: null,
    itemId: null,
    sortBy: null,
    sortByOrder: null,
    grade: 0,
  };
  examFiltersState$: BehaviorSubject<ExamFilters>;

  folderFiltersDefault: FolderDoneFilters = {
    isFavorite: -1,
    disciplineId: null,
    itemId: null,
    sortBy: null,
    sortByOrder: null,
  };
  folderFiltersState$: BehaviorSubject<FolderDoneFilters>;

  examRankingFiltersDefault: ExamRankingFilters = {
    search: null,
    city: null,
    fac: null,
    year: null,
  };
  examRankingFiltersState$: BehaviorSubject<ExamRankingFilters>;

  multiRankingFiltersDefault: MultiRankingFilters = {
    city: null,
    fac: null,
    year: null,
  };
  multiRankingFiltersState$: BehaviorSubject<MultiRankingFilters>;

  edncastFiltersDefault: PodcastFilters = {
    title: '',
    disciplineId: null,
    itemId: null,
  };
  edncastFiltersState$: BehaviorSubject<PodcastFilters>;

  ecocastFiltersDefault: PodcastFilters = {
    title: '',
    disciplineId: null,
    itemId: null,
  };
  ecocastFiltersState$: BehaviorSubject<PodcastFilters>;

  progressFiltersDefault: ProgressFilters = {
    type: null,
    startDate: null,
    endDate: null,
  };
  progressFiltersState$: BehaviorSubject<ProgressFilters>;

  unitFoldersFiltersDefault: UnitFoldersFilters = {
    easy_dp: false,
    mini_dp: false,
    kfp: false,
    tcs: false,
    isR2C: -1,
    classification: '',
    price: '',
    progression: '',
    disciplineId: null,
    itemId: null,
    sortBy: null,
    sortByOrder: null,
    grade: 0,
  };
  unitFoldersFiltersState$: BehaviorSubject<UnitFoldersFilters>;

  videoFiltersDefault: VideoFilters = {
    disciplineId: null,
    itemId: null,
  };
  videoFiltersState$: BehaviorSubject<VideoFilters>;

  constructor() {
    this.courseFiltersState$ = new BehaviorSubject<CourseFilters>(this.courseFiltersDefault);
    this.coursesEcosFiltersState$ = new BehaviorSubject<CourseFilters>(
      this.coursesEcosFiltersDefault
    );
    this.itemImagesFiltersState$ = new BehaviorSubject<ItemImagesFilters>(
      this.itemImagesFiltersDefault
    );

    this.disciplineFiltersState$ = new BehaviorSubject<DisciplineFilters>(
      this.disciplineFiltersDefault
    );
    this.itemFiltersState$ = new BehaviorSubject<ItemFilters>(this.itemFiltersDefault);
    this.ecosItemFiltersState$ = new BehaviorSubject<ItemFilters>(this.ecosItemFiltersDefault);

    this.ecosFiltersState$ = new BehaviorSubject<EcosFilters>(this.ecosFiltersDefault);
    this.examFiltersState$ = new BehaviorSubject<ExamFilters>(this.examFiltersDefault);
    this.folderFiltersState$ = new BehaviorSubject<FolderDoneFilters>(this.folderFiltersDefault);

    this.examRankingFiltersState$ = new BehaviorSubject<ExamRankingFilters>(
      this.examRankingFiltersDefault
    );

    this.multiRankingFiltersState$ = new BehaviorSubject<MultiRankingFilters>(
      this.multiRankingFiltersDefault
    );

    this.edncastFiltersState$ = new BehaviorSubject<PodcastFilters>(this.edncastFiltersDefault);

    this.ecocastFiltersState$ = new BehaviorSubject<PodcastFilters>(this.ecocastFiltersDefault);

    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    this.progressFiltersDefault.startDate = lastMonth;
    this.progressFiltersDefault.endDate = new Date();
    this.progressFiltersState$ = new BehaviorSubject<ProgressFilters>(this.progressFiltersDefault);

    this.unitFoldersFiltersState$ = new BehaviorSubject<UnitFoldersFilters>(
      this.unitFoldersFiltersDefault
    );

    this.videoFiltersState$ = new BehaviorSubject<VideoFilters>(this.videoFiltersDefault);
  }

  resetFilters() {
    this.courseFiltersState$.next(this.courseFiltersDefault);
    this.coursesEcosFiltersState$.next(this.coursesEcosFiltersDefault);

    this.disciplineFiltersState$.next(this.disciplineFiltersDefault);
    this.itemFiltersState$.next(this.itemFiltersDefault);
    this.ecosItemFiltersState$.next(this.ecosItemFiltersDefault);

    this.ecosFiltersState$.next(this.ecosFiltersDefault);
    this.examFiltersState$.next(this.examFiltersDefault);
    this.folderFiltersState$.next(this.folderFiltersDefault);

    this.examRankingFiltersState$.next(this.examRankingFiltersDefault);
    this.edncastFiltersState$.next(this.edncastFiltersDefault);
    this.ecocastFiltersState$.next(this.ecocastFiltersDefault);

    this.multiRankingFiltersState$.next(this.multiRankingFiltersDefault);

    this.progressFiltersState$.next(this.progressFiltersDefault);

    this.unitFoldersFiltersState$.next(this.unitFoldersFiltersDefault);
    this.videoFiltersState$.next(this.videoFiltersDefault);
  }

  areFiltersOriginal(filtersValue, filtersCompare) {
    return isEqual(filtersValue, filtersCompare);
  }
}
