<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -xlarge">
  <button class="mat-modal-closer" (click)="close()">
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div
    class="modal-inside -candidate-challenge block--modal-challenge"
    [class.bg-site-card-gradient-1]="modalMode == 'question-intro' || modalMode == 'question'"
  >
    <app-intro-candidate-challenge
      #introCandidateChallenge
      *ngIf="modalMode == 'intro'"
      [exam]="exam"
      [challengeCode]="challengeCode"
      (launchEmitter)="loadChallenge()"
      (exitEmitter)="launchChallenge()"
    ></app-intro-candidate-challenge>

    <app-intro-question-challenge
      #introQuestionChallenge
      *ngIf="modalMode == 'question-intro' && currentQuestion"
      [exam]="exam"
      [currentQuestion]="currentQuestion"
      (exitEmitter)="launchQuestionContent()"
    ></app-intro-question-challenge>

    <app-question-challenge
      #questionChallenge
      *ngIf="modalMode == 'question' && currentQuestion"
      [exam]="exam"
      [currentQuestion]="currentQuestion"
      [progressBarPercentage]="progressBarPercentage"
      [timeRemaining]="getTimeRemaining()"
      [validatingAnswer]="validatingAnswer"
      (questionValidatedEmitter)="validateAnswer()"
    ></app-question-challenge>

    <app-end-challenge
      #endChallenge
      *ngIf="modalMode == 'end'"
      [challengeCode]="challengeCode"
      (closeEmitter)="close()"
    ></app-end-challenge>
  </div>
</div>
