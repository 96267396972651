import { Injectable } from '@angular/core';

import { fromEvent, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternetConnectionService {
  isConnected$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  init() {
    fromEvent(window, 'online').subscribe(() => {
      this.isConnected$.next(true);
    });

    fromEvent(window, 'offline').subscribe(() => {
      this.isConnected$.next(false);
    });
  }

  closeAlert() {
    this.isConnected$.next(true);
  }
}
