import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

export function getLastRouteNode(route: ActivatedRoute | ActivatedRouteSnapshot) {
  let children = route.children;
  if (!children.length) {
    return null;
  }
  let node = children;
  while (children.length) {
    node = children;
    children = children[0].children;
  }
  return node[0];
}
