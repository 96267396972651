import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { QueryCollection } from '../../../shared/models/collection.model';
import { QueryData } from '../../../shared/models/query-data.model';
import {
  EcniMessage,
  MessageGroup,
  MessageGroupAnswerPostData,
  MessageGroupPostData,
} from './../../../shared/models/message-group.model';

import { MessageGroupEndpoints } from '../../../shared/endpoints/message-group.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpMessageGroupService {
  constructor(private http: HttpClient) {}

  query(options: QueryData) {
    return this.http
      .get(MessageGroupEndpoints.query, {
        params: options as any,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((raw) => {
            return new MessageGroup(raw);
          });
          return new QueryCollection<MessageGroup>(res);
        })
      );
  }

  getMessages(code: string, options: QueryData) {
    return this.http
      .get(MessageGroupEndpoints.messages(code), {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data
            .map((raw) => {
              return new EcniMessage(raw);
            })
            .reverse();
          return new QueryCollection<EcniMessage>(res);
        })
      );
  }

  read(code: string) {
    return this.http.get(MessageGroupEndpoints.read(code));
  }

  reply(code: string, answerContent: MessageGroupAnswerPostData) {
    return this.http.post(MessageGroupEndpoints.messages(code), answerContent).pipe(
      map((res: any) => {
        return new EcniMessage(res);
      })
    );
  }

  post(messageGroupPostData: MessageGroupPostData) {
    return this.http.post(MessageGroupEndpoints.query, messageGroupPostData).pipe(
      map((res: any) => {
        return new MessageGroup(res);
      })
    );
  }

  getNumberUnread() {
    return this.http.get(MessageGroupEndpoints.unread).pipe(
      map((res: any) => {
        return res.data as number;
      })
    );
  }

  leave(code: string, userId: number) {
    return this.http.delete(MessageGroupEndpoints.user(code, userId));
  }
}
