import { Injectable } from '@angular/core';

import { Browser } from '@capacitor/browser';

import { IsApplicationService } from './is-application.service';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(private isApplicationService: IsApplicationService) {}

  openBlank(url: string) {
    if (this.isApplicationService.isApplication()) {
      Browser.open({
        url,
      });
    } else {
      window.open(url, '_blank');
    }
  }
}
