import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AvatarService } from '../../../core/services/avatar.service';
import { IsApplicationService } from '../../../core/services/is-application.service';
import { SanitizerService } from './../../../core/services/sanitizer.service';

import { ChallengeRanking, ChallengeRankingLevel } from '../../models/challenge.model';
import { ModalListImagesData } from '../../models/modal-data';
import { EcniUser } from './../../models/ecni-user.model';

import { ItemImage } from '../../models/item.model';
import { ModalListImagesComponent } from '../../modals/modal-list-images/modal-list-images.component';

@Component({
  selector: 'app-user-sunray',
  templateUrl: './user-sunray.component.html',
  styleUrls: ['./user-sunray.component.scss'],
})
export class UserSunrayComponent implements OnInit {
  @Input() user: EcniUser;
  @Input() userRanking: ChallengeRanking;
  @Input() rankingLevels: ChallengeRankingLevel[];

  @Input() canChangePhoto = false;

  constructor(
    public sanitizerService: SanitizerService,
    public isApplicationService: IsApplicationService,
    public avatarService: AvatarService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {}

  openModalImage() {
    if (this.user) {
      const modalData: ModalListImagesData = {
        images: [new ItemImage({ url: this.user.pictureUrl })],
        currentImageIndex: 0,
      };

      this.matDialog.open(ModalListImagesComponent, {
        maxWidth: '100vw',
        data: modalData,
      });
    }
  }
}
