export function isOnDismissedElement(event) {
  if (!event || !event.srcEVent || !event.srcEvent.path) {
    return true;
  }

  let isOnDismiss = false;
  event.srcEvent.path.forEach((element) => {
    if (element.localName === 'swiper') {
      isOnDismiss = true;
    }
  });
  return isOnDismiss;
}
