import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appGradientHidden]',
})
export class GradientHiddenDirective implements OnInit, AfterViewInit {
  @Input() displayedInit = false;
  @Input() heightInit = 180;

  @HostBinding('class.-displayed') displayed = false;
  @HostBinding('style.height.px') height;

  constructor(private elementRef: ElementRef) {
    const gradientMask = document.createElement('div');
    gradientMask.classList.add('gradient_mask');
    if (this.elementRef) {
      this.elementRef.nativeElement.appendChild(gradientMask);
    }
  }

  ngOnInit() {
    this.displayed = this.displayedInit;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.height = this.elementRef.nativeElement.clientHeight;
      if (this.height > this.heightInit) {
        this.height = this.heightInit;
      } else {
        this.displayed = true;
      }
    });
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (this.elementRef) {
      const element = this.elementRef.nativeElement;
      const sectionHeight = element.scrollHeight;
      element.style.height = sectionHeight + 'px';
      const transitionFunction = (e) => {
        element.removeEventListener('transitionend', transitionFunction);
        element.style.height = null;
      };

      element.addEventListener('transitionend', transitionFunction);

      this.displayed = true;
    }
  }
}
