<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -large">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles">
      <div
        class="absolute w-[150%] top-0 bottom-0 rounded-b-full -left-1/4 premiumplus-gradient"
      ></div>

      <div
        class="relative h-full p-8 text-center flex flex-col justify-center items-center text-white space-y-6"
        *ngIf="!isApplicationService.isAppIos()"
      >
        <div class="text-26 font-bold">
          {{ 'pricing.premium_plus' | translate }}
        </div>

        <div class="flex justify-center items-center space-x-4">
          <div class="text-16 font-semibold">
            {{ 'pricing.a_partir_de' | translate }}
          </div>

          <div class="text-31 font-extrabold my-8">
            <ng-container *ngIf="offer">
              {{ Math.floor(offer.price / offer.nbMonth) }}&nbsp;€</ng-container
            >
            <ng-container *ngIf="!offer">
              <div class="spinner_100_container">
                <app-loader-dots [white]="true"></app-loader-dots>
              </div>
            </ng-container>
          </div>
          <div class="text-16 font-semibold">/&nbsp;{{ 'premium.par_mois' | translate }}</div>
        </div>

        <div class="text-14 text-center">
          {{ 'pricing.paiement_3_fois' | translate }}
        </div>
      </div>
    </div>

    <div class="modal-text">
      <div class="h-[50px]"></div>
      <div class="modal-title text-orange-two" *ngIf="!isApplicationService.isAppIos()">
        {{ 'root.contenu_premium_plus' | translate }}
      </div>

      <div class="modal-theme">
        <p *ngIf="!isApplicationService.isAppIos()" [innerHtml]="'root.acces' | translate"></p>

        <p *ngIf="isApplicationService.isAppIos()">
          {{ 'cordova.message_ios' | translate }}
        </p>
      </div>

      <div class="modal-buttons" *ngIf="!isApplicationService.isAppIos()">
        <a
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-black btn-nnki-uppercase"
          (click)="close()"
          [routerLink]="['/app/premium/pricing/forfait-premium']"
        >
          {{ 'modal_pub.decouvrir' | translate }}
        </a>
      </div>

      <div class="h-[60px] sm:hidden"></div>
    </div>
  </div>
</div>
