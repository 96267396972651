import { parseIntMember } from '../utils/parse-functions';

export class Histogram {
  grades: Slice[];

  totalCandidates: number;
  maxSliceValue: number;
  maxPercentValue: number;

  vAxis: number[];

  constructor(input: any) {
    Object.assign(this, input);

    if (this.grades) {
      this.grades = this.grades.map((slice) => {
        return new Slice(slice);
      });
    } else {
      this.grades = [];
    }

    this.totalCandidates = 0;
    this.maxSliceValue = 0;
    this.grades.forEach((slice) => {
      this.totalCandidates += slice.nb_candidates;
      this.maxSliceValue = Math.max(this.maxSliceValue, slice.nb_candidates);
    });

    this.maxPercentValue = this.maxSliceValue / this.totalCandidates;

    this.vAxis = [];
    const maxVAxis = Math.min(1, this.maxPercentValue * 1.05) * 100;
    for (let i = 0; i < 5; i++) {
      this.vAxis.push(Math.ceil((maxVAxis / 4) * i));
    }
  }
}

export class Slice {
  label: string;
  nb_candidates: number;

  constructor(input: any) {
    parseIntMember(input, 'nb_candidates');

    Object.assign(this, input);
  }

  getPercentHeight(histogram: Histogram) {
    return (
      (this.nb_candidates /
        histogram.totalCandidates /
        (histogram.vAxis[histogram.vAxis.length - 1] / 100)) *
      100
    );
  }

  getClassColor(histogram: Histogram) {
    if ((this.nb_candidates / histogram.totalCandidates) * 100 < histogram.vAxis[1]) {
      return 'slice-inf';
    } else if (
      (this.nb_candidates / histogram.totalCandidates) * 100 >
      histogram.vAxis[histogram.vAxis.length - 2]
    ) {
      return 'slice-sup';
    } else {
      return 'slice-mid';
    }
  }
}
