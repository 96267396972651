export function supportedScroll(toScroll: Window | HTMLElement, y: number, notSmooth?: boolean) {
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

  if (scrollTo) {
    if (supportsNativeSmoothScroll) {
      if (notSmooth) {
        toScroll.scrollTo({ top: y, behavior: 'auto' });
      } else {
        toScroll.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      toScroll.scrollTo(0, y);
    }
  }
}
