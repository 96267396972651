<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content w-screen sm:w-[calc(100vw-40px)]">
  <button class="mat-modal-closer -xs-black" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside pb-0">
    <app-pdf-viewer
      *ngIf="url"
      [url]="url"
      [showOpenButton]="false"
      [modalFullScreen]="true"
    ></app-pdf-viewer>
  </div>
</div>
