import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { HttpChallengeService } from '../../../../core/services/http/http-challenge.service';
import { SanitizerService } from '../../../../core/services/sanitizer.service';

import { ChallengeAchievement } from '../../../models/challenge.model';

import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';

@Component({
  selector: 'app-challenge-actualities',
  templateUrl: './challenge-actualities.component.html',
  styleUrls: ['./challenge-actualities.component.scss'],
})
export class ChallengeActualitiesComponent implements OnInit, AfterViewInit {
  @Input() userId;
  @Input() inModale = false;

  actualitiesList: ChallengeAchievement[] = [];

  @ViewChild(InfiniteScrollDirective) infiniteScrollDirective: InfiniteScrollDirective;

  constructor(
    private httpChallengeService: HttpChallengeService,
    public sanitizerService: SanitizerService,
    private router: Router,
    private matdialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getTweets();
    });
  }

  getTweets() {
    if (this.infiniteScrollDirective.canScroll()) {
      this.infiniteScrollDirective.initiateScroll();

      this.httpChallengeService
        .getAchievements(this.userId, {
          perPage: 10,
          page: this.infiniteScrollDirective.currentPage,
        })
        .subscribe(
          (res) => {
            this.actualitiesList = this.actualitiesList.concat(res.data);
            this.infiniteScrollDirective.endScroll(res.metadata);
          },
          () => {
            this.infiniteScrollDirective.scrollHasErrored();
          }
        );
    }
  }

  likeTweet(actuality: ChallengeAchievement) {
    if (!actuality.hasLiked) {
      this.httpChallengeService.likeAchievement(actuality.id).subscribe((res) => {
        actuality.hasLiked = true;
        actuality.nbLikes++;
      });
    } else {
      this.httpChallengeService.unlikeAchievement(actuality.id).subscribe((res) => {
        actuality.hasLiked = false;
        actuality.nbLikes--;
      });
    }
  }

  redirectToContent(actuality: ChallengeAchievement) {
    if (actuality.type === 'exam_done' && actuality.exam) {
      this.router.navigate(['/app/exams', actuality.exam, 'exam', 'preview']);
      this.matdialog.closeAll();
    }
  }
}
