import { parseBooleanMember } from '../utils/parse-functions';

export class VersionCheck {
  success: boolean;
  version: EcniVersion;

  constructor(input: any) {
    // api typo fix
    if (input.data) {
      input.version = input.data;
    }

    parseBooleanMember(input, 'success');
    Object.assign(this, input);

    if (this.version) {
      this.version = new EcniVersion(this.version);
    }
  }
}

export class EcniVersion {
  version: string;
  version_mobile: string;
  update_content: string;
  update_content_mobile: string;

  new_available: boolean;
  new_breaking_available: boolean;
  new_mobile_available: boolean;
  new_breaking_mobile_available: string;

  constructor(input: any) {
    parseBooleanMember(input, 'new_available');
    parseBooleanMember(input, 'new_breaking_available');
    parseBooleanMember(input, 'new_mobile_available');
    parseBooleanMember(input, 'new_breaking_mobile_available');
    Object.assign(this, input);
  }
}
