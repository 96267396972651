import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { NavbarInfos } from '../../../shared/models/menu.model';

import { MenuEndpoints } from '../../../shared/endpoints/menu.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpMenuService {
  constructor(private http: HttpClient) {}

  getNavbarInfos() {
    return this.http.get(MenuEndpoints.getNavbarInfos).pipe(
      map((res: any) => {
        return new NavbarInfos(res);
      })
    );
  }
}
