import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';

import { of, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MaintenanceStatus } from '../../shared/models/maintenance.model';
import { ModalMaintenanceData } from '../../shared/models/modal-data';

import { ModalMaintenanceComponent } from '../../shared/modals/modal-maintenance/modal-maintenance.component';
import { HttpMaintenanceService } from './http/http-maintenance.service';

import { LocaleKeys } from '../../shared/utils/locale-keys';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  endMaintenance: number;
  message: string;
  modalOpened = false;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private httpMaintenanceService: HttpMaintenanceService,
    private userService: UserService
  ) {}

  init() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.checkIsMaintenance();
      });
  }

  isMaintenance(): Observable<MaintenanceStatus> {
    if (localStorage.getItem(LocaleKeys.maintenanceKilled) === 'killed') {
      return of({
        isMaintenance: false,
      });
    } else {
      return this.httpMaintenanceService.isMaintenance().pipe(
        map((res) => {
          if (!res.success) {
            return {
              isMaintenance: false,
            };
          } else {
            if (res.message && res.message.length && res.message !== '0') {
              this.message = res.message;
            }
            if (res.forceMaintenance) {
              return {
                isMaintenance: true,
              };
            } else {
              const now = new Date();

              if (now.getTime() >= res.start.getTime() && now.getTime() <= res.end.getTime()) {
                return {
                  isMaintenance: true,
                  endMaintenance: res.end.getTime(),
                };
              } else {
                return {
                  isMaintenance: false,
                };
              }
            }
          }
        })
      );
    }
  }

  checkIsMaintenance() {
    this.isMaintenance().subscribe((res) => {
      if (res.isMaintenance) {
        this.userService.logout();
        this.router.navigate(['/']).then(
          () => {
            this.applyMaintenance(res);
          },
          () => {
            this.applyMaintenance(res);
          }
        );
      }
    });
  }

  applyMaintenance(res: MaintenanceStatus) {
    if (res.endMaintenance) {
      this.endMaintenance = res.endMaintenance;
    } else {
      this.endMaintenance = null;
    }

    if (!this.modalOpened) {
      this.modalOpened = true;
      const modalData: ModalMaintenanceData = {
        endMaintenance: this.endMaintenance,
        message: this.message,
      };

      this.matDialog.open(ModalMaintenanceComponent, {
        maxWidth: '100vw',
        data: modalData,
        disableClose: true,
      });
    }
  }
}
