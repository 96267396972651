import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-oval-header-card',
  templateUrl: './oval-header-card.component.html',
  styleUrls: ['./oval-header-card.component.scss'],
})
export class OvalHeaderCardComponent implements OnInit {
  @Input() type: 'premium' | 'large';

  constructor() {}

  ngOnInit() {}
}
