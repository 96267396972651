import { Component, Input, OnInit } from '@angular/core';

import { DisciplinesService } from '../../../../core/services/disciplines.service';
import { SanitizerService } from './../../../../core/services/sanitizer.service';

import { Discipline } from '../../../models/discipline.model';

@Component({
  selector: 'app-discipline-challenge',
  templateUrl: './discipline-challenge.component.html',
  styleUrls: ['./discipline-challenge.component.scss'],
})
export class DisciplineChallengeComponent implements OnInit {
  @Input() discipline: Discipline;
  @Input() selected: boolean;

  constructor(
    public sanitizerService: SanitizerService,
    public disciplinesService: DisciplinesService
  ) {}

  ngOnInit(): void {}
}
