import { environment } from '../../../environments/environment';

export class ExamEndpoints {
  static query = `${environment.api_base_url}/examens`;
  static queryLanding = `${environment.api_base_url}/landing/examens`;
  static queryCustom = `${environment.api_base_url}/examens-custom`;
  static getActivity = `${environment.api_base_url}/examens/activity`;
  static createExamCustom = `${environment.api_base_url}/examens-custom`;

  static get(id: number) {
    return `${environment.api_base_url}/examens/${id}`;
  }

  static getCandidates(id: number) {
    return `${environment.api_base_url}/examens/${id}/candidates`;
  }

  static start(id: number) {
    return `${environment.api_base_url}/examens/${id}/start`;
  }

  static getCandidate(id: number) {
    return `${environment.api_base_url}/candidates/${id}`;
  }

  static retakeCandidate(id: number) {
    return `${environment.api_base_url}/candidates/retake/${id}`;
  }

  static postCandidateAnswer(candidateId: number, questionId: number) {
    return `${environment.api_base_url}/candidates/${candidateId}/questions/${questionId}`;
  }

  static postConftubeAnswer(candidateId: number, questionId: number) {
    return `${environment.api_base_url}/conftubes/${candidateId}/questions/${questionId}`;
  }

  static pauseCandidate(id: number) {
    return `${environment.api_base_url}/candidates/${id}/pause`;
  }

  static closeCandidate(id: number) {
    return `${environment.api_base_url}/candidates/${id}/done`;
  }

  static getQuestionsAndFolders(id: number) {
    return `${environment.api_base_url}/examens/${id}/questions`;
  }

  static getFeedbacksQI(id: number) {
    return `${environment.api_base_url}/examens/${id}/feedbacks`;
  }

  static getStudentsRanking(id: number) {
    return `${environment.api_base_url}/exam/${id}/stats`;
  }

  static getFacRanking(id: number) {
    return `${environment.api_base_url}/exam/${id}/stats/fac`;
  }

  static rateExam(examId: number) {
    return `${environment.api_base_url}/examens/${examId}/rating`;
  }

  static rateFolder(examId: number, folderId: number) {
    return `${environment.api_base_url}/exam/${examId}/folders/${folderId}/rating`;
  }

  static rateConftube(examId: number) {
    return `${environment.api_base_url}/conftubes/${examId}/rating`;
  }

  static deleteCandidate(id: number) {
    return `${environment.api_base_url}/candidates/${id}`;
  }

  static getCustom(code: string) {
    return `${environment.api_base_url}/examens-custom/${code}`;
  }

  static getCandidatesCustom(code: string) {
    return `${environment.api_base_url}/examens-custom/${code}/candidates`;
  }

  static startCustom(code: string) {
    return `${environment.api_base_url}/examens-custom/${code}/start`;
  }

  static getQuestionsAndFoldersCustom(code: string) {
    return `${environment.api_base_url}/examens-custom/${code}/questions`;
  }

  static getFeedbacksQICustom(code: string) {
    return `${environment.api_base_url}/examens-custom/${code}/feedbacks`;
  }

  static getHistogram(type: string, id: number) {
    return `${environment.api_base_url}/histo/${type}/${id}`;
  }

  static deleteExamCustom(code: string) {
    return `${environment.api_base_url}/examens-custom/${code}`;
  }
}
