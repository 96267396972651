import { Injectable } from '@angular/core';

import { ExamCustom } from '../../shared/models/exam-custom.model';
import { Exam } from '../../shared/models/exam.model';
import { QueryData } from '../../shared/models/query-data.model';
import { ExamCandidates } from './../../shared/models/candidate.model';
import { HttpEcosService } from './http/http-ecos.service';

import { HttpExamCustomService } from './http/http-exam-custom.service';
import { HttpExamService } from './http/http-exam.service';

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  constructor(
    private httpExamCustomService: HttpExamCustomService,
    private httpExamService: HttpExamService,
    private httpEcosService: HttpEcosService
  ) {}

  isExamCustom(exam: Exam | ExamCustom) {
    if (exam instanceof ExamCustom) {
      return true;
    } else {
      return false;
    }
  }

  getExamIdOrCode(exam: Exam | ExamCustom, examIdentifier?: number | string) {
    if (exam) {
      if (this.isExamCustom(exam)) {
        return (exam as ExamCustom).code ? (exam as ExamCustom).code : '';
      } else {
        return exam.id;
      }
    } else {
      return examIdentifier;
    }
  }

  getExamObservable(examIdentifier: string | number, isEcos?: boolean) {
    if (!isEcos) {
      if (typeof examIdentifier === 'string') {
        return this.httpExamCustomService.get(examIdentifier);
      } else {
        return this.httpExamService.get(examIdentifier);
      }
    } else {
      return this.httpEcosService.get(examIdentifier as number);
    }
  }

  getExamCandidatesObservable(exam: Exam | ExamCustom) {
    if (this.isExamCustom(exam)) {
      return this.httpExamCustomService.getCandidates((exam as ExamCustom).code);
    } else {
      return this.httpExamService.getCandidates(exam.id);
    }
  }

  getExamQuestionsObservable(exam: Exam | ExamCustom) {
    if (this.isExamCustom(exam)) {
      return this.httpExamCustomService.getQuestionsAndFolders((exam as ExamCustom).code);
    } else {
      return this.httpExamService.getQuestionsAndFolders(exam.id);
    }
  }

  getExamFeedbacksQIObservable(exam: Exam | ExamCustom, options: QueryData) {
    if (this.isExamCustom(exam)) {
      return this.httpExamCustomService.getFeedbacksQI((exam as ExamCustom).code, options);
    } else {
      return this.httpExamService.getFeedbacksQI(exam.id, options);
    }
  }

  isCandidateRetake(examCandidates: ExamCandidates) {
    let pausedCandidateId: number = null;

    if (examCandidates.currentCandidate) {
      // il y a un currentCandidate
      pausedCandidateId = examCandidates.currentCandidate.id;
    } else {
      // on examine tous les candidates un par un
      if (examCandidates.first) {
        if (!examCandidates.first.finishedAt) {
          // le first n'est pas terminé : ajouter le candidate id à l'url et retake
          pausedCandidateId = examCandidates.first.id;
        } else {
          // le first est terminé, on vérifie les autres candidates
          if (examCandidates.otherCandidates && examCandidates.otherCandidates.length) {
            examCandidates.otherCandidates.forEach((candidate) => {
              if (!candidate.finishedAt && !pausedCandidateId) {
                // ce candidate n'est pas terminé
                pausedCandidateId = candidate.id;
              }
            });
          }
        }
      }
    }
    return pausedCandidateId;
  }
}
