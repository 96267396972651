<div class="grid grid-cols-1 justify-items-center">
  <div
    class="nnki-rounded-background-image cursor-pointer w-24"
    [style.background-image]="sanitizerService.imageProfile(student.pictureUrl)"
    (click)="ecniTweetsService.openUserProfileModale(student.id)"
  ></div>

  <div class="text-center mt-4 max-w-full">
    <div
      class="text-14 font-extrabold mb-2 truncate cursor-pointer"
      (click)="ecniTweetsService.openUserProfileModale(student.id)"
    >
      {{ student.username }}
    </div>
    <div class="truncate text-11 font-semibold">
      {{ student.cityfac?.name }} -
      {{ student.year?.name }}
    </div>
  </div>
</div>

<div
  class="my-4 flex items-center"
  [ngClass]="{
    'flex-col space-y-2': !waitingRequest,
    'flex-row justify-center space-x-2': waitingRequest
  }"
  [class.-waitingRequest]="waitingRequest"
>
  <ng-container *ngIf="student.id != userService.loggedUserValue?.id">
    <ng-container *ngIf="!waitingRequest">
      <ng-container *ngIf="!student.followIsLoading">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-primary h-12 text-11 w-44"
          *ngIf="!student.followed_by_user && !student.followed_by_user_need_approval"
          (click)="ecniTweetsService.follow(student)"
        >
          {{ 'twit.sabonner' | translate }}
        </button>

        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-white h-12 text-11 w-44"
          *ngIf="!student.followed_by_user && student.followed_by_user_need_approval"
          (click)="ecniTweetsService.unfollow(student)"
        >
          {{ 'friend.attente' | translate }}
        </button>

        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-white h-12 text-11 w-44"
          *ngIf="student.followed_by_user"
          (click)="ecniTweetsService.unfollow(student)"
        >
          {{ 'twit.se_desabonner' | translate }}
        </button>
      </ng-container>

      <div class="spinner_100_container" *ngIf="student.followIsLoading">
        <app-loader-dots></app-loader-dots>
      </div>

      <button
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-purple btn-nnki-icon-left btn-nnki-uppercase h-12 text-11 w-44"
        *ngIf="
          student.id != userService.loggedUserValue?.id && isApplicationService.isPremiumEnabled
        "
        (click)="modalService.openModalChallenge(student)"
      >
        <svg-icon
          src="assets/images/svg-icons/icon-challenge.svg"
          [svgStyle]="{ 'width.px': '16' }"
        ></svg-icon>
        {{ 'challenge.defier' | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="waitingRequest">
      <ng-container *ngIf="!student.followIsLoading">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-icon btn-nnki-white h-12 text-11 w-20 border-green-three text-green-three"
          (click)="approveRequest()"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-check-light.svg"
            [svgStyle]="{ 'width.px': '14' }"
          ></svg-icon>
        </button>
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-icon btn-nnki-white h-12 text-11 w-20 border-red-one text-red-one"
          (click)="rejectRequest()"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-times.svg"
            [svgStyle]="{ 'width.px': '12' }"
          ></svg-icon>
        </button>
      </ng-container>

      <div class="spinner_100_container" *ngIf="student.followIsLoading">
        <app-loader-dots></app-loader-dots>
      </div>
    </ng-container>
  </ng-container>
</div>
