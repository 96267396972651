import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import {
  ChargeParameters,
  ChargeResult,
  Coupon,
  Offer,
  ScaConfirm,
  Transaction,
} from '../../../shared/models/premium.model';

import { PremiumEndpoints } from './../../../shared/endpoints/premium.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpPremiumService {
  constructor(private http: HttpClient) {}

  getOffers() {
    return this.http.get(PremiumEndpoints.getOffers).pipe(
      map((res: any) => {
        res = res.map((offerRaw) => {
          return new Offer(offerRaw);
        });
        return res as Offer[];
      })
    );
  }

  verifyCoupon(couponId: string) {
    return this.http.post(PremiumEndpoints.verifyCoupon, { couponId }).pipe(
      map((res: any) => {
        const coupon = new Coupon(res);
        return coupon;
      })
    );
  }

  chargePremium(id: number, chargeParameter: ChargeParameters) {
    return this.http.post(PremiumEndpoints.chargePremium(id), chargeParameter).pipe(
      map((res: any) => {
        return new ChargeResult(res);
      })
    );
  }

  chargeConversion(id: number, chargeParameter: ChargeParameters) {
    return this.http.post(PremiumEndpoints.chargeConversion(id), chargeParameter).pipe(
      map((res: any) => {
        return new ChargeResult(res);
      })
    );
  }

  scaConfirm(id: string) {
    return this.http.get(PremiumEndpoints.scaConfirm(id)).pipe(
      map((res: any) => {
        return new ScaConfirm(res);
      })
    );
  }

  patchSubscription(paymentToken: string) {
    return this.http.put(PremiumEndpoints.patchSubscription, { paymentToken });
  }

  getTransactions() {
    return this.http.get(PremiumEndpoints.transactions).pipe(
      map((res: any) => {
        res = res.map((raw) => {
          return new Transaction(raw);
        });
        return res as Transaction[];
      })
    );
  }

  isIOSPremiumEnabled() {
    return this.http.get(PremiumEndpoints.isIOSPremiumEnabled).pipe(
      map((res: any) => {
        return !res.data.disableIOSPremium as boolean;
      })
    );
  }
}
