import { ExamCandidates, Candidate } from './candidate.model';
import {
  parseIntMember,
  parseBooleanMember,
  parseFloatMember,
  parseDateMember,
} from '../utils/parse-functions';
import { Discipline } from './discipline.model';
import { ConftubeAnswersCorrection, Question } from './question.model';
import { Folder } from './folder.model';
import { Item } from './item.model';
import { FolderFaq } from './feedback.model';
import { Histogram } from './histogram.model';
import { EcosContext, EcosDebrief, EcosSection } from './ecos.model';

export class Exam {
  id: number;
  title: string;
  type: string;
  isR2C: boolean;

  createdAt: Date;
  publishedAt: Date;
  updatedAt: Date;

  theme: string;
  nbFolders: number;
  nbVideo: number;
  nbQuestions: number;
  nbCandidates: number;
  duration: number;
  disciplines: Discipline[];
  items: Item[];
  children: Exam[];

  classification: 'easy' | 'moderate' | 'hard';
  disciplineMain: Discipline;
  mot: string;

  start: Date;
  end: Date;
  visible: boolean;
  hasAccess: boolean;
  correctionAccess: boolean;
  statAccess: boolean;
  correctionDate: Date;
  correctionJusquau: Date;
  statDate: Date;

  parentExam: Exam;

  firstCandidateId: number;
  position: string;
  grade: number;
  points: number;
  pointsMax: number;

  done: boolean;
  pausedCandidateId: number;

  countDown: number;

  questions: Question[];
  folders: Folder[];

  embed1: string;
  embed2: string;

  histogram: Histogram;

  video_preview: string;
  views: number;

  credits: number;
  isElite: boolean;

  // ecos
  pictogram: string;
  patientName: string;
  patientAge: string;
  ecosContext: EcosContext;
  preparationTime: number;

  ecosDebrief: EcosDebrief;
  ecosSections: EcosSection[];
  pointsRangA = 0;
  pointsMaxRangA = 0;

  constructor(input: any) {
    // api typo fix
    if (input.created_at && !input.createdAt) {
      input.createdAt = input.created_at;
    }

    if (input.updated_at && !input.updatedAt) {
      input.updatedAt = input.updated_at;
    }

    if (input.disciplineMain_id && !input.disciplineMain) {
      input.disciplineMain = {
        id: input.disciplineMain_id,
        name: input.disciplineMain_name,
      };
    }

    if (input.discipline_main && !input.disciplineMain) {
      input.disciplineMain = {
        id: input.discipline_main.id,
        name: input.discipline_main.name,
      };
    }

    if (input.categories && !input.disciplines) {
      input.disciplines = input.categories;
    }

    if (input.nbfolders && !input.nbFolders) {
      input.nbFolders = input.nbfolders;
    }

    if (input.nbQuestionsIso && !input.nbQuestions) {
      input.nbQuestions = input.nbQuestionsIso;
    }

    if (input.childs && input.childs.length) {
      input.children = input.childs;
    }
    if (!input.children) {
      input.children = [];
    }

    if (typeof input.gradeMax !== 'undefined' && typeof input.pointsMax === 'undefined') {
      input.pointsMax = input.gradeMax;
    }

    if (input.price && !input.credits) {
      input.credits = input.price;
    }

    if (input.ecos_context && !input.ecosContext) {
      input.ecosContext = input.ecos_context;
    }

    if (input.ecos_debrief && !input.ecosDebrief) {
      input.ecosDebrief = input.ecos_debrief;
    }

    if (input.ecos_sections && !input.ecosSections) {
      input.ecosSections = input.ecos_sections;
    }

    // fill data
    if (!input.publishedAt) {
      input.publishedAt = input.createdAt;
    }

    parseDateMember(input, 'start');
    parseDateMember(input, 'end');
    parseDateMember(input, 'correctionDate');
    parseDateMember(input, 'correctionJusquau');
    parseDateMember(input, 'statDate');
    parseDateMember(input, 'createdAt');
    parseDateMember(input, 'updatedAt');
    parseDateMember(input, 'publishedAt');

    parseIntMember(input, 'id');
    parseIntMember(input, 'nbFolders');
    parseIntMember(input, 'nbVideo');
    parseIntMember(input, 'nbQuestions');
    parseIntMember(input, 'nbCandidates');
    parseIntMember(input, 'duration');

    parseBooleanMember(input, 'visible');
    parseBooleanMember(input, 'hasAccess');
    parseBooleanMember(input, 'correctionAccess');
    parseBooleanMember(input, 'statAccess');
    parseIntMember(input, 'firstCandidateId');
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'points');
    parseFloatMember(input, 'pointsMax');
    parseBooleanMember(input, 'done');
    parseIntMember(input, 'pausedCandidateId');
    parseIntMember(input, 'countDown');

    parseIntMember(input, 'credits');
    parseBooleanMember(input, 'isElite');

    Object.assign(this, input);

    if (this.disciplines) {
      this.disciplines = this.disciplines.map((category) => {
        return new Discipline(category);
      });
    } else {
      this.disciplines = [];
    }

    if (this.items) {
      this.items = this.items.map((item) => {
        return new Item(item);
      });
    } else {
      this.items = [];
    }

    if (this.disciplineMain) {
      this.disciplineMain = new Discipline(this.disciplineMain);
    }

    if (this.children) {
      this.children = this.children.map((child) => {
        return new Exam(child);
      });
    } else {
      this.children = [];
    }

    if (this.parentExam) {
      this.parentExam = new Exam(this.parentExam);
    }

    if (this.questions) {
      this.questions = this.questions.map((question) => {
        return new Question(question);
      });
    } else {
      this.questions = [];
    }

    if (this.folders) {
      this.folders = this.folders.map((folder) => {
        return new Folder(folder);
      });
    } else {
      this.folders = [];
    }

    if (!this.video_preview) {
      this.video_preview = 'assets/assets-site/images/logos/icon.svg';
    }
  }

  get classificationWidth() {
    if (this.classification === 'easy') {
      return 33;
    } else if (this.classification === 'moderate') {
      return 66;
    } else if (this.classification === 'hard') {
      return 100;
    } else {
      return null;
    }
  }

  get tooltipClassification() {
    if (this.classification === 'easy') {
      return 'filters.facile';
    } else if (this.classification === 'moderate') {
      return 'filters.moderee';
    } else if (this.classification === 'hard') {
      return 'filters.difficile';
    } else {
      return null;
    }
  }

  get grantedAccess() {
    if (this.isElite) {
      return 'elite';
    } else if (this.credits > 0) {
      return 'premium_plus';
    } else {
      return 'free';
    }
  }

  get isInFreeDateInterval() {
    const currentDate = new Date();
    if (this.start <= currentDate && this.end >= currentDate) {
      return true;
    } else {
      return false;
    }
  }
}

export class ExamType {
  type: string;
  titleKey: string;
  icon: string;
  color: string;
}

export class QuestionsAndFolders {
  folders: FolderFaq[];
  questions: Question[];

  constructor(input: any) {
    if (input.folders) {
      const foldersInput = input.folders;
      input.folders = [];
      Object.keys(foldersInput).forEach((key) => {
        input.folders.push(foldersInput[key]);
      });
    }
    Object.assign(this, input);

    if (this.folders) {
      this.folders = this.folders.map((folder) => {
        return new FolderFaq(folder);
      });
    } else {
      this.folders = [];
    }

    if (this.questions) {
      this.questions = this.questions.map((question, index) => {
        question.number = index;
        return new Question(question);
      });
    } else {
      this.questions = [];
    }
  }
}

export class ConftubeCorrection {
  question: Question;
  answers: ConftubeAnswersCorrection;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.question) {
      this.question = new Question(this.question);
    }

    if (this.answers) {
      this.answers = new ConftubeAnswersCorrection(this.answers);
    }
  }
}

export class MultiExamData {
  parentExam: Exam;
  parentCandidate: ExamCandidates;
  childrenCandidates: Candidate[];
}

export class RateFolder {
  rating: number;
  comment?: string;
}
