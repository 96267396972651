import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { QueryCollection } from '../../../shared/models/collection.model';
import {
  FacebookImportResult,
  FollowResult,
  SocialStats,
} from '../../../shared/models/social.model';
import { EcniUser } from './../../../shared/models/ecni-user.model';
import { QueryData } from './../../../shared/models/query-data.model';

import { SocialEndpoints } from '../../../shared/endpoints/social.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpSocialService {
  constructor(private http: HttpClient) {}

  getStats(id: number) {
    return this.http.get(SocialEndpoints.stats(id)).pipe(
      map((res: any) => {
        return new SocialStats(res);
      })
    );
  }

  getFollowed(id: number, options: QueryData) {
    return this.http
      .get(SocialEndpoints.getFollowed(id), {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((userRaw) => {
            return new EcniUser(userRaw);
          });
          return new QueryCollection<EcniUser>(res);
        })
      );
  }

  getFollowers(id: number, options: QueryData) {
    return this.http
      .get(SocialEndpoints.getFollowers(id), {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((userRaw) => {
            return new EcniUser(userRaw);
          });
          return new QueryCollection<EcniUser>(res);
        })
      );
  }

  getFollowersRequests(options: QueryData) {
    return this.http
      .get(SocialEndpoints.getFollowersRequests, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((userRaw) => {
            return new EcniUser(userRaw);
          });
          return new QueryCollection<EcniUser>(res);
        })
      );
  }

  follow(id: number) {
    return this.http.get(SocialEndpoints.follow(id)).pipe(
      map((res: any) => {
        return new FollowResult(res);
      })
    );
  }

  unfollow(id: number) {
    return this.http.get(SocialEndpoints.unfollow(id)).pipe(
      map((res: any) => {
        return new FollowResult(res);
      })
    );
  }

  approve(id: number) {
    return this.http.get(SocialEndpoints.approve(id)).pipe(
      map((res: any) => {
        return new FollowResult(res);
      })
    );
  }

  reject(id: number) {
    return this.http.get(SocialEndpoints.reject(id)).pipe(
      map((res: any) => {
        return new FollowResult(res);
      })
    );
  }

  facebookImport() {
    return this.http.get(SocialEndpoints.facebookImport).pipe(
      map((res: any) => {
        return new FacebookImportResult(res);
      })
    );
  }
}
