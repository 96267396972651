import { environment } from '../../../environments/environment';

export class TrainingEndpoints {
  static trainingDisciplines = `${environment.api_base_url}/trainings/disciplines`;
  static trainingItems = `${environment.api_base_url}/trainings/items`;
  static stopTrainingSession = `${environment.api_base_url}/trainings/questions/stop`;
  static continuousQuestion = `${environment.api_base_url}/trainings/questions`;
  static ancrageQuestion = `${environment.api_base_url}/ancrage/question`;
  static trainingStatus = `${environment.api_base_url}/trainings/questions/status`;
  static getAncrageProgression = `${environment.api_base_url}/ancrage/progression/2`;
  static getActivity = `${environment.api_base_url}/trainings/activity`;
  static resetAncrage = `${environment.api_base_url}/ancrage/reset/all`;
  static addCommentRelevance = `${environment.api_base_url}/question/comment-on-question-relevance`;

  static continuousQuestionById(id: number) {
    return `${environment.api_base_url}/trainings/questions/${id}`;
  }

  static ancrageQuestionById(id: number) {
    return `${environment.api_base_url}/ancrage/question/${id}`;
  }

  static deleteQuestionAncrage(id: number) {
    return `${environment.api_base_url}/ancrage/question/${id}/unwanted`;
  }

  static resetProgression(type: 'disciplines' | 'items', id: number) {
    return `${environment.api_base_url}/trainings?${type}=${id}`;
  }

  static deleteRevision(id: number) {
    return `${environment.api_base_url}/questions/${id}/revisions`;
  }

  static judgeRelevance(id: number) {
    return `${environment.api_base_url}/questions/${id}/judgedAsRelevantOrNot/increment`;
  }
}
