import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalMaintenanceData } from '../../models/modal-data';

@Component({
  selector: 'app-modal-maintenance',
  templateUrl: './modal-maintenance.component.html',
  styleUrls: ['./modal-maintenance.component.scss'],
})
export class ModalMaintenanceComponent implements OnInit {
  endMaintenance: number;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ModalMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalMaintenanceData
  ) {
    this.endMaintenance = this.data.endMaintenance;
    this.message = this.data.message;
  }

  ngOnInit(): void {}
}
