import { environment } from '../../../environments/environment';

export class EcosEndpoints {
  static query = `${environment.api_base_url}/ecos`;

  static get(id: number) {
    return `${environment.api_base_url}/ecos/${id}`;
  }

  static start(id: number) {
    return `${environment.api_base_url}/ecos/${id}/start`;
  }

  static doAction(id: number) {
    return `${environment.api_base_url}/ecos/candidates/${id}/actions`;
  }

  static close(id: number) {
    return `${environment.api_base_url}/ecos/candidates/${id}/done`;
  }

  static getCandidate(id: number) {
    return `${environment.api_base_url}/ecos/candidates/${id}`;
  }
}
