import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-infinite-scroll',
  templateUrl: './loader-infinite-scroll.component.html',
  styleUrls: ['./loader-infinite-scroll.component.scss'],
})
export class LoaderInfiniteScrollComponent implements OnInit {
  @Input() showInitialLoader: boolean;
  @Input() showScrollLoader: boolean;

  constructor() {}

  ngOnInit(): void {}
}
