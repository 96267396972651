<div class="relative flex flex-col sm:block grow -mb-32 md:mb-0 h-auto md:h-[560px] lg:h-[500px]">
  <div class="spinner_element_container" *ngIf="firstLoading">
    <app-loader-hashtag></app-loader-hashtag>
  </div>

  <ng-container *ngIf="!firstLoading">
    <div class="user-panel" [class.-inside]="animationState.panelLeft">
      <app-user-sunray
        [user]="userService.loggedUserValue"
        [userRanking]="userRanking"
        [rankingLevels]="rankingLevels"
        [class.-inside]="animationState.user"
      ></app-user-sunray>
    </div>

    <div class="opponents-panel" [class.-inside]="animationState.panelRight">
      <ng-container *ngIf="challenge">
        <div
          class="opponent-block"
          *ngFor="
            let challenger of challenge.getChallengersWithoutSelf(userService.loggedUserValue?.id)
              | slice: 0:3;
            let index = index
          "
          [class.-inside]="animationState['challenger' + index]"
        >
          <div class="user-image-sunray">
            <div class="sunray"></div>
            <div
              class="nnki-rounded-background-image cursor-pointer"
              [style.background-image]="sanitizerService.imageProfile(challenger.user.pictureUrl)"
            ></div>

            <div class="user-position" *ngIf="challenger.user.global?.rank">
              <svg-icon
                src="assets/images/svg-icons/icon-medal.svg"
                [svgStyle]="{ 'width.px': '13' }"
              ></svg-icon>
              {{ challenger.user.global.rank | number: '1.0-0':'fr' }}
            </div>
          </div>

          <div class="user-infos">
            <div class="user-username">
              {{ challenger.user.username }}
            </div>
            <div class="user-fac">
              {{ challenger.user.cityfac?.name + ' - ' + challenger.user.year?.name }}
            </div>

            <div class="user-points" *ngIf="challenger.user.global?.score">
              {{
                (challenger.user.global.score | number: '1.0-0':'fr') + ('examens.pts' | translate)
              }}
            </div>
          </div>
        </div>

        <div
          class="more-opponents-block"
          *ngIf="challenge.getChallengersWithoutSelf(userService.loggedUserValue?.id).length > 3"
          [class.-inside]="animationState.moreChallengers"
        >
          <div class="more-background"></div>
          <div class="more-numbers">
            + {{ challenge.getChallengersWithoutSelf(userService.loggedUserValue?.id).length - 3 }}
          </div>
          <div class="more-names">
            <ng-container
              *ngFor="
                let challenger of challenge.getChallengersWithoutSelf(
                  userService.loggedUserValue?.id
                ) | slice: 3;
                let index = index
              "
            >
              <ng-container *ngIf="index > 0">, </ng-container>
              {{ challenger.user.username }}
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="spinner_element_container" *ngIf="!challenge">
        <app-loader-dots></app-loader-dots>
      </div>
    </div>

    <div
      class="discipline-badge absolute top-12 left-0"
      *ngIf="exam?.disciplines?.length"
      [class.-inside]="animationState.discipline"
    >
      <div class="badge-content">
        <svg-icon
          src="assets/images/illustrations/disciplines/{{
            disciplinesService.getIconId(exam.disciplines[0].id)
          }}svg.svg"
        ></svg-icon>
        <span>{{ exam.disciplines[0].name }}</span>
      </div>
    </div>

    <div
      class="versus-badge"
      [class.-inside]="animationState.versus"
      [class.-heartbeat]="animationState.versusHeartbeat"
    >
      VS
      <svg-icon
        src="assets/images/svg-illustrations/icon-flash.svg"
        [svgStyle]="{ 'width.px': '64' }"
      ></svg-icon>

      <app-loader-dots *ngIf="isLoading"></app-loader-dots>
    </div>
  </ng-container>
</div>
