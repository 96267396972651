import { Location } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, throwError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, exhaustMap, filter, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { LoginResult } from '../../shared/models/auth.model';
import { ModalWarningData } from '../../shared/models/modal-data';

import { ModalPremiumPlusComponent } from '../../shared/modals/modal-premium-plus/modal-premium-plus.component';
import { ModalWarningComponent } from '../../shared/modals/modal-warning/modal-warning.component';
import { LOGIN_STATE, UserService } from '../services/user.service';
import { LanguageService } from './../services/language.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  alertIsDisplayed = false;

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private location: Location,
    private languageService: LanguageService,
    private toastr: ToastrService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.userService.isStillLoggedIn() &&
      this.userService.accessToken &&
      request.url.indexOf(environment.maintenance_endpoint) === -1
    ) {
      request = this.addToken(request, this.userService.accessToken);
    }

    return next.handle(request).pipe(
      catchError((error: HttpEvent<any>) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          return this.handle400Error(request, next, error);
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          return this.handle403Error(request, next, error);
        } else {
          if (error instanceof HttpErrorResponse) {
            console.error(`[HTTP ERROR ${error.status}]`, error);
          } else {
            console.error('[UNDEFINED REQUEST ERROR]', error);
          }
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle400Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpError: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    if (this.userService.loginState$.getValue() === LOGIN_STATE.LOGGED) {
      if (httpError.error.error === 'ERR_CONCURRENT_LOGIN_FOUND') {
        this.userService.logout();
        this.translate.get('root.limite_connexion').subscribe((trad) => {
          this.toastr.warning(trad);
        });
      } else if (httpError.error.error === 'ERR_NO_PHPSESSID_HEADER') {
        this.userService.logout();
      }
    }
    return throwError(httpError);
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpError: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.userService.refreshAuthToken().pipe(
        exhaustMap((tokens: LoginResult) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.access_token);
          return next.handle(this.addToken(request, tokens.access_token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        exhaustMap((accessToken: string) => {
          return next.handle(this.addToken(request, accessToken));
        })
      );
    }
  }

  private handle403Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpError: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    let navigateBack = false;
    if (
      (httpError.error.error === 'ERR_FORBIDDEN_PREMIUM_PLUS_REQUIRED' ||
        httpError.error.error === 'ERR_FORBIDDEN_PREMIUM_REQUIRED') &&
      this.languageService.premiumEnabled
    ) {
      this.matDialog.open(ModalPremiumPlusComponent, {
        maxWidth: '100vw',
      });
      navigateBack = true;
    } else if (httpError.error.error === 'ERR_FORBIDDEN_EXAM_NOT_VISIBLE') {
      forkJoin([
        this.translate.get('util.error2'),
        this.translate.get('error.visible'),
        this.translate.get('util.ok'),
      ]).subscribe(([trad1, trad2, trad3]) => {
        const modalData: ModalWarningData = {
          title: trad1,
          content: trad2,
          buttonText: trad3,
        };

        this.matDialog.open(ModalWarningComponent, {
          maxWidth: '100vw',
          data: modalData,
        });
      });

      navigateBack = true;
    } else if (httpError.error.error === 'ERR_FORBIDDEN_PREMIUM_OR_PASS_REQUIRED') {
      forkJoin([
        this.translate.get('root.interdit'),
        this.translate.get('root.interdit2'),
        this.translate.get('util.ok'),
      ]).subscribe(([trad1, trad2, trad3]) => {
        const modalData: ModalWarningData = {
          title: trad1,
          content: trad2,
          buttonText: trad3,
        };

        this.matDialog.open(ModalWarningComponent, {
          maxWidth: '100vw',
          data: modalData,
        });
      });
      navigateBack = true;
    } else if (httpError.error.error === 'ERR_NO_QUESTION_FOUND') {
      // do nothing
    } else if (!httpError.error.error) {
      this.userService.logout();
    }

    if (navigateBack) {
      this.location.back();
    }

    return throwError(httpError);
  }
}
