import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IsApplicationService } from '../../../../core/services/is-application.service';
import { LinkService } from '../../../../core/services/link.service';

import { EcniDocument } from '../../../models/ecni-document.model';
import {
  ModalQuestionImagesApplicationData,
  ModalListImagesData,
} from '../../../models/modal-data';

import { ModalListImagesApplicationComponent } from '../../../modals/modal-list-images-application/modal-list-images-application.component';
import { ModalListImagesComponent } from '../../../modals/modal-list-images/modal-list-images.component';

@Component({
  selector: 'app-question-images',
  templateUrl: './question-images.component.html',
  styleUrls: ['./question-images.component.scss'],
})
export class QuestionImagesComponent implements OnInit {
  @Input() documents: EcniDocument[];

  constructor(
    private isApplicationService: IsApplicationService,
    private matDialog: MatDialog,
    private linkService: LinkService
  ) {}

  ngOnInit(): void {}

  openModalImage(index: number) {
    if (!this.isApplicationService.isApplication()) {
      const modalData: ModalListImagesData = {
        images: this.documents,
        currentImageIndex: index,
      };

      this.matDialog.open(ModalListImagesComponent, {
        maxWidth: '100vw',
        data: modalData,
      });
    } else {
      if (this.documents.length === 1) {
        this.linkService.openBlank(this.documents[0].imageUrl);
      } else {
        const modalData: ModalQuestionImagesApplicationData = {
          images: this.documents,
        };

        this.matDialog.open(ModalListImagesApplicationComponent, {
          maxWidth: '100vw',
          data: modalData,
        });
      }
    }
  }
}
