import { Item } from './item.model';
import { parseBooleanMember, parseDateMember, parseIntMember } from '../utils/parse-functions';
import { Folder } from './folder.model';
import { Question } from './question.model';
import { EcniUser } from './ecni-user.model';
import { EcosAction } from './ecos.model';

export class Feedback {
  id: number;

  title: string;
  content: string;
  user: EcniUser;
  createdAt: Date;
  read: boolean;

  opened: boolean;
  answerContent: string;

  folder: Folder;
  question: Question;
  item: Item;
  ecosAction_id: number;
  ecosAction: EcosAction;

  feedbackAnswers: FeedbackAnswer[];

  pictureUrl: string;
  previewPictureUrl: string;
  previewTo: string;
  previewHour: Date;
  previewText: string;
  previewDiff: number;

  constructor(input: any) {
    // api typo fix
    if (input.created_at) {
      input.createdAt = input.created_at;
    }

    if (input.feedback_answers) {
      input.feedbackAnswers = input.feedback_answers;
    }

    if (input.feedbacksAnswers) {
      input.feedbackAnswers = input.feedbacksAnswers;
    }

    parseIntMember(input, 'id');
    parseDateMember(input, 'createdAt');

    parseBooleanMember(input, 'read');
    parseBooleanMember(input, 'opened');

    parseIntMember(input, 'ecosAction_id');

    Object.assign(this, input);

    if (this.user) {
      this.user = new EcniUser(this.user);
    }

    if (this.folder) {
      this.folder = new Folder(this.folder);
    }

    if (this.question) {
      this.question = new Question(this.question);
    }

    if (this.item) {
      this.item = new Item(this.item);
    }

    if (this.feedbackAnswers) {
      this.feedbackAnswers = this.feedbackAnswers.map((feedback_answer) => {
        return new FeedbackAnswer(feedback_answer);
      });
    } else {
      this.feedbackAnswers = [];
    }
  }
}

export class FolderFaq extends Folder {
  feedbackQuestions: Feedback[];
  questions: Question[];
  index: number;

  constructor(input: any) {
    super(input);

    if (this.feedbackQuestions) {
      this.feedbackQuestions = this.feedbackQuestions.map((feedbackQuestion, index) => {
        return new Feedback(feedbackQuestion);
      });
    } else {
      this.feedbackQuestions = [];
    }

    if (this.questions) {
      this.questions = this.questions.map((question, index) => {
        question.number = index;
        return new Question(question);
      });
    } else {
      this.questions = [];
    }
  }
}

export class FeedbackAnswer {
  id: number;
  content: string;
  author: EcniUser;
  createdAt: Date;

  pictureUrl: string;

  constructor(input: any) {
    // api typo fix
    if (input.created_at) {
      input.createdAt = input.created_at;
    }

    if (input.author_id || input.username) {
      input.author = new EcniUser({
        id: input.author_id,
        username: input.username,
      });
    }

    parseIntMember(input, 'id');
    parseDateMember(input, 'createdAt');
    parseIntMember(input, 'author_id');

    Object.assign(this, input);
  }
}

export class FeedbackPostData {
  title: string;
  content: string;
  itemId?: number;
  questionId?: number;
  folderId?: number;
  examId?: number;
  ecosActionId?: number;
}

export class FeedbackAnswerPostData {
  content: string;
}
