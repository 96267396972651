<div class="intro-question-challenge">
  <div class="intro-background" [class.-inside]="animationState.background"></div>

  <div class="intro-content">
    <div
      class="discipline-badge mb-20"
      *ngIf="exam?.disciplines?.length"
      [class.-inside]="animationState.discipline == 'inside'"
      [class.-gone]="animationState.discipline == 'gone'"
    >
      <div class="badge-content">
        <svg-icon
          src="assets/images/illustrations/disciplines/{{
            disciplinesService.getIconId(exam.disciplines[0].id)
          }}svg.svg"
        ></svg-icon>
        <span>{{ exam.disciplines[0].name }}</span>
      </div>
    </div>

    <div class="question-number" [class.-inside]="animationState.questionNumber">
      {{ 'question.question' | translate }} {{ currentQuestion.questionIndex + 1 }}
    </div>

    <div class="question-total" [class.-inside]="animationState.questionTotal">
      {{
        'challenge.question_number'
          | translate
            : {
                numQuestion: currentQuestion.questionIndex + 1,
                totalQuestion: exam.questions.length
              }
      }}
    </div>

    <img
      src="assets/assets-site/images/svg-illustrations/flash-intro-question-challenge.svg"
      class="flash-img"
      [class.-inside]="animationState.flashImg == 'inside'"
      [class.-gone]="animationState.flashImg == 'gone'"
    />
  </div>
</div>
