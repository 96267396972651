<ng-container *ngIf="text">
  {{ text }}
</ng-container>

<div
  *ngIf="!text"
  class="dummy-rectangle"
  [style.width.px]="dummyWidth"
  [style.height.px]="dummyHeight"
  [style.margin]="dummyCentered ? 'auto' : null"
></div>
