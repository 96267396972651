import { RoutingService } from './core/services/routing.service';
import { SeoService } from './core/services/seo.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
// import * as Sentry from '@sentry/browser';
import { locale } from 'moment';
import { forkJoin, merge } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { AncrageService } from './core/services/ancrage.service';
import { AppPushNotificationsService } from './core/services/app-push-notifications.service';
import { AppUpdateService } from './core/services/app-update.service';
import { BackButtonService } from './core/services/back-button.service';
import { ChallengeService } from './core/services/challenge.service';
import { ColorService } from './core/services/color.service';
import { CookiesService } from './core/services/cookies.service';
import { FiltersService } from './core/services/filters.service';
import { InternetConnectionService } from './core/services/internet-connection.service';
import { IsApplicationService } from './core/services/is-application.service';
import { LanguageService } from './core/services/language.service';
import { MaintenanceService } from './core/services/maintenance.service';
import { MessagingService } from './core/services/messaging.service';
import { NavbarSearchService } from './core/services/navbar-search.service';
import { NotificationsService } from './core/services/notifications.service';
import { SiteUpdateService } from './core/services/site-update.service';
import { LOGIN_STATE, UserService } from './core/services/user.service';
import { VersionService } from './core/services/version.service';

import { ModalWarningData } from './shared/models/modal-data';

import { ModalWarningComponent } from './shared/modals/modal-warning/modal-warning.component';

import { LocaleKeys } from './shared/utils/locale-keys';
import { ModalActions } from './shared/utils/modal-actions';
import { screenWidthConstants } from './shared/utils/screen-sizes';
import { env } from 'process';

locale(environment.locale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  currentModule = '';

  loginState = LOGIN_STATE.ATTEMPT_TO_RECOVER;
  LOGIN_STATE = LOGIN_STATE;

  appIsLoading = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private ancrageService: AncrageService,
    public isApplicationService: IsApplicationService,
    private appPushNotificationsService: AppPushNotificationsService,
    private translate: TranslateService,
    private maintenanceService: MaintenanceService,
    private appUpdateService: AppUpdateService,
    private siteUpdateService: SiteUpdateService,
    private matDialog: MatDialog,
    private filtersService: FiltersService,
    private navbarSearchService: NavbarSearchService,
    public versionService: VersionService,
    private challengeService: ChallengeService,
    private colorService: ColorService,
    private meta: Meta,
    private messagingService: MessagingService,
    private notificationsService: NotificationsService,
    private ngZone: NgZone,
    private backButtonService: BackButtonService,
    public internetConnectionService: InternetConnectionService,
    public languageService: LanguageService,
    public cookiesService: CookiesService,
    private seoService: SeoService
  ) {
    this.versionService.init();

    this.translate.setDefaultLang(environment.defaultLanguage);

    // Sentry.configureScope((scope) => {
    //   scope.setTag('locale', environment.defaultLanguage);
    // });

    if (this.isApplicationService.isApplication()) {
      SplashScreen.hide();
      StatusBar.setBackgroundColor({
        color: this.colorService.getSiteMainColor(),
      });

      // handle deeplinks
      App.addListener('appUrlOpen', (data: any) => {
        this.ngZone.run(() => {
          const slug = data.url.split(environment.domain).pop();
          if (slug) {
            if (!this.userService.loggedUserValue) {
              this.userService.saveNavBeforeLogin(slug);
            }
            this.router.navigateByUrl(slug);
          }
        });
      });
    }

    const lastVersion = localStorage.getItem(LocaleKeys.lastVersion);
    if (lastVersion !== environment.version) {
      localStorage.setItem(LocaleKeys.lastVersion, environment.version);
    }

    this.colorService.init();
    this.appUpdateService.init();
    this.siteUpdateService.init();
    this.maintenanceService.init();
    this.ancrageService.init();
    this.challengeService.init();
    this.messagingService.init();
    this.notificationsService.init();
    this.appPushNotificationsService.init();
    this.backButtonService.init();
    this.internetConnectionService.init();
    this.cookiesService.init();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (typeof (window as any).Intercom === 'function') {
          (window as any).Intercom('update', {
            last_request_at: new Date().getTime() / 1000,
          });
        }
        if (event.url.indexOf('/app/') !== -1) {
          const splittedUrl = event.url.split('/');
          if (this.currentModule !== splittedUrl[2]) {
            this.currentModule = splittedUrl[2];
            this.cleanFilters();
          }
        }
      });

    this.userService.loginState$.subscribe((loginState) => {
      this.loginState = loginState;

      if (this.loginState !== LOGIN_STATE.ATTEMPT_TO_RECOVER) {
        setTimeout(() => {
          this.appIsLoading = false;
        }, 1000);
      }

      if (this.loginState === LOGIN_STATE.LOGGED) {
        if (!this.userService.loggedUserValue.paid) {
          forkJoin([
            this.translate.get('root.mensualite'),
            this.translate.get('root.mensualite2'),
            this.translate.get('root.parametres'),
          ]).subscribe(([trad1, trad2, trad3]) => {
            const modalData: ModalWarningData = {
              title: trad1,
              content: trad2,
              buttonText: trad3,
              closeAction: ModalActions.actionNavigation,
              routerLink: ['/app/settings'],
            };

            this.matDialog.open(ModalWarningComponent, {
              maxWidth: '100vw',
              data: modalData,
            });
          });
        }
      }
    });
  }

  ngOnInit() {
    this.translate.get('meta.description').subscribe((trad) => {
      this.meta.updateTag({ name: 'description', content: trad });
    });

    this.seoService.init();
  }

  // for intercom : when messenger is added to the dom, we need to change its bottom/top css properties for the iphone notch
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.isApplicationService.isApplication()) {
        const safeTop = getComputedStyle(document.documentElement).getPropertyValue(
          '--safe-area-inset-top'
        );
        const safeBottom = getComputedStyle(document.documentElement).getPropertyValue(
          '--safe-area-inset-bottom'
        );
        setInterval(() => {
          const intercomContainer = document.getElementById('intercom-container');
          if (intercomContainer) {
            const intercomMessengerFrame = document.getElementsByClassName(
              'intercom-messenger-frame'
            );
            if (intercomMessengerFrame.length) {
              (
                intercomMessengerFrame[0] as any
              ).style.cssText = `background-color: ${this.colorService.getSiteMainColor()}`;

              const iframe = intercomMessengerFrame[0].firstChild;

              if (iframe) {
                (
                  iframe as any
                ).style.cssText = `top: ${safeTop} !important; bottom: ${safeBottom} !important; height: calc(100% - ${safeTop} - ${safeBottom}) !important`;
              }
            }
          }
        }, 1000);
      }
    }, 5000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.userService.computeIntercomDisplay();
  }

  cleanFilters() {
    this.filtersService.resetFilters();
    this.navbarSearchService.resetSearch();
  }
}
