import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { BackButtonService } from '../../core/services/back-button.service';
import { IsApplicationService } from './../../core/services/is-application.service';

import { BaseDirective } from './base.directive';

@Directive({
  selector: '[appPanel]',
})
export class PanelDirective extends BaseDirective implements OnInit, OnDestroy {
  creationDate = new Date().getTime();

  @Output() closePanelEmitter = new EventEmitter();

  constructor(
    private backButtonService: BackButtonService,
    private isApplicationService: IsApplicationService
  ) {
    super();
  }

  ngOnInit() {
    if (this.isApplicationService.isApplication()) {
      this.backButtonService.pushFilterState(this.creationDate);

      this.backButtonService.closePanel$.pipe(takeUntil(this.alive$)).subscribe((res) => {
        if (res === this.creationDate) {
          this.closePanelEmitter.emit();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.isApplicationService.isApplication()) {
      this.backButtonService.removePanelFromState(this.creationDate);
    }
  }
}
