import { Exam } from './exam.model';

export class ExamCustom extends Exam {
  code: string;
  subjects: ExamCustomSubject[];

  constructor(input) {
    super(input);

    this.hasAccess = true;

    this.subjects = [];
    if (this.disciplines && this.disciplines.length) {
      this.subjects = this.subjects.concat(
        this.disciplines.map((discipline) => {
          return {
            name: discipline.name,
            routerLink: ['/app/exams/examens'],
            queryParams: { disciplineId: discipline.id },
            type: 'discipline',
          };
        })
      );
    }
    if (this.items && this.items.length) {
      this.subjects = this.subjects.concat(
        this.items.map((item) => {
          return {
            name: item.title,
            routerLink: ['/app/courses/course', item.id],
            type: 'item',
          };
        })
      );
    }
  }
}

export class ExamCustomSubject {
  name: string;
  routerLink: (string | number)[];
  queryParams?: {
    disciplineId: number;
  };
  type: 'item' | 'discipline';
}

export class ExamCustomPostData {
  title: string;
  nbQuestions: number;
  countdown: number;
  sortMode: 'none' | 'discriminating' | 'wrong_answers' | 'never_done';
  items?: number[];
  disciplines?: number[];
}
