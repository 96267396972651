<div
  class="nnki-rounded-background-image mb-4 relative w-[55px]"
  [style.background-image]="
    sanitizerService.toBackground(
      'assets/images/illustrations/disciplines/' +
        disciplinesService.getIconId(discipline.id) +
        'svg.svg'
    )
  "
>
  <div
    class="mask"
    *ngIf="selected"
    class="absolute top-0 bottom-0 right-0 left-0 bg-theme-gray-twentytwo"
  ></div>

  <div
    class="tick"
    *ngIf="selected"
    class="absolute rounded-full w-[33px] h-[33px] -right-2 bottom-0 bg-green-three flex-center"
  >
    <svg-icon
      class="text-theme-white"
      src="assets/images/svg-icons/icon-tick-2.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </div>
</div>

<div class="text-13 font-bold truncate max-w-full">
  {{ discipline.name }}
</div>
