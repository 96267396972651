import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ChallengeService } from '../../../../core/services/challenge.service';
import { HttpChallengeService } from '../../../../core/services/http/http-challenge.service';
import { SanitizerService } from '../../../../core/services/sanitizer.service';
import { SoundService } from '../../../../core/services/sound.service';
import { UserService } from '../../../../core/services/user.service';

import { Challenge, ChallengeCandidate } from '../../../models/challenge.model';
import { ModalCreateChallengeData } from '../../../models/modal-data';

import { ModalCreateChallengeComponent } from '../../modal-create-challenge/modal-create-challenge.component';

@Component({
  selector: 'app-end-challenge',
  templateUrl: './end-challenge.component.html',
  styleUrls: ['./end-challenge.component.scss'],
})
export class EndChallengeComponent implements OnInit {
  @Input() challengeCode: string;
  @Output() closeEmitter = new EventEmitter();

  challenge: Challenge;
  userCandidate: ChallengeCandidate;

  constructor(
    private httpChallengeService: HttpChallengeService,
    public sanitizerService: SanitizerService,
    public userService: UserService,
    private matDialog: MatDialog,
    private challengeService: ChallengeService,
    private soundService: SoundService
  ) {}

  ngOnInit(): void {
    this.httpChallengeService.getChallenge(this.challengeCode).subscribe((res) => {
      this.challenge = res;

      this.userCandidate = this.challenge.challengers.find((challenger) => {
        return challenger.user.id === this.userService.loggedUserValue.id;
      }).challengeCandidate;
    });

    this.challengeService.updateChallengeSummary(false);

    if (this.soundService.canPlayChallengeSounds()) {
      this.soundService.initSound('challenge-applause', 'assets/sounds/applause.mp3', false);
      this.soundService.playSound('challenge-applause');
      this.soundService.fadeSound('challenge-applause');
    }
  }

  relaunchChallenge() {
    const modalData: ModalCreateChallengeData = {
      usersSelected: this.challenge.challengers
        .filter((challenger) => {
          return challenger.user.id !== this.userService.loggedUserValue.id;
        })
        .map((challenger) => {
          return challenger.user;
        }),
    };

    this.matDialog.open(ModalCreateChallengeComponent, {
      maxWidth: '100vw',
      data: modalData,
    });

    this.close();
  }

  close() {
    this.closeEmitter.emit();
  }

  launchAnimationsEntry() {}
}
