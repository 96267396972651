import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { timer } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';

import { DisciplinesService } from '../../../../core/services/disciplines.service';
import { LanguageService } from './../../../../core/services/language.service';

import { ExamCustom } from '../../../models/exam-custom.model';
import { Question } from './../../../models/question.model';

@Component({
  selector: 'app-intro-question-challenge',
  templateUrl: './intro-question-challenge.component.html',
  styleUrls: ['./intro-question-challenge.component.scss'],
})
export class IntroQuestionChallengeComponent implements OnInit {
  @Input() exam: ExamCustom;
  @Input() currentQuestion: Question;

  @Output() exitEmitter = new EventEmitter();

  animationState = {
    background: false,
    discipline: null,
    questionNumber: false,
    questionTotal: false,
    flashImg: null,
  };

  constructor(
    public disciplinesService: DisciplinesService,
    public languageService: LanguageService
  ) {}

  ngOnInit(): void {}

  launchAnimationsEntry() {
    timer(0)
      .pipe(
        concatMap(() => {
          this.animationState.background = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.discipline = 'inside';
          return timer(100);
        }),
        concatMap(() => {
          this.animationState.questionNumber = true;
          return timer(100);
        }),
        concatMap(() => {
          this.animationState.questionTotal = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.flashImg = 'inside';
          return timer(1000);
        }),
        concatMap(() => {
          this.animationState.discipline = 'gone';
          this.animationState.questionNumber = false;
          this.animationState.questionTotal = false;
          this.animationState.flashImg = 'gone';
          return timer(500);
        }),
        concatMap(() => {
          this.animationState.background = false;
          return timer(300);
        }),
        concatMap(() => {
          this.exitEmitter.emit();
          return timer(300);
        }),
        first()
      )
      .subscribe();
  }
}
