import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';

import { AuthenticateGuard } from './core/guards/authenticate.guard';
import { IsDesktopGuard } from './core/guards/is-desktop.guard';

const routes: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./lazy-modules/logged-area/logged-area.module').then((m) => m.LoggedAreaModule),
    canActivate: [AuthenticateGuard],
  },
  {
    path: 'ios',
    loadChildren: () =>
      import('./lazy-modules/ios-area/ios-area.module').then((m) => m.IosAreaModule),
    canActivate: [IsDesktopGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./lazy-modules/public-area/public-area.module').then((m) => m.PublicAreaModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: environment.use_hash_for_routing,
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
