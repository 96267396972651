import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { environment } from '../../../../environments/environment';

import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';

import { ModalUpdateData } from '../../models/modal-data';

@Component({
  selector: 'app-modal-update-version',
  templateUrl: './modal-update-version.component.html',
  styleUrls: ['./modal-update-version.component.scss'],
})
export class ModalUpdateVersionComponent implements OnInit, OnDestroy {
  environment = environment;

  acceptUpdate = false;

  constructor(
    public dialogRef: MatDialogRef<ModalUpdateVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalUpdateData,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    let acceptStr = 'refuse update site';
    if (this.acceptUpdate) {
      acceptStr = 'accept update site';
    }

    this.googleAnalyticsService.sendEvent(
      acceptStr,
      'update',
      'from ' + this.data.currentVersion + ' to ' + this.data.newVersion
    );
  }

  updateSite() {
    this.acceptUpdate = true;
    window.location.href = window.location.href;
    window.location.reload();
  }

  close() {
    this.dialogRef.close();
  }
}
