import { environment } from '../../../environments/environment';

export class ChallengeEndpoints {
  static createExamChallenge = `${environment.api_base_url}/examens-challenge`;
  static challenges = `${environment.api_base_url}/challenges`;
  static getInvitationsForUser = `${environment.api_base_url}/challenges/invitations/self`;
  static getSummary = `${environment.api_base_url}/challenges/summary`;
  static getOverallRankings = `${environment.api_base_url}/challenges-ranking/disciplines`;

  static invite(code: string) {
    return `${environment.api_base_url}/challenges/invitations/${code}/invite`;
  }

  static getInvitationsForChallenge(code: string) {
    return `${environment.api_base_url}/challenges/invitations/${code}`;
  }

  static acceptInvitation(id: string) {
    return `${environment.api_base_url}/challenges/invitations/${id}/accept`;
  }

  static refuseInvitation(id: string) {
    return `${environment.api_base_url}/challenges/invitations/${id}/decline`;
  }

  static challenge(code: string) {
    return `${environment.api_base_url}/challenges/${code}`;
  }

  static getUserRanking(disciplineId: number | 'overall', userId: number | 'self') {
    return `${environment.api_base_url}/challenges-ranking/disciplines/${disciplineId}/${userId}`;
  }

  static getUserVictories(userId: number | 'self') {
    return `${environment.api_base_url}/challenges-ranking/victories/${userId}`;
  }

  static getUserRankingLevels(userId: number | 'self') {
    return `${environment.api_base_url}/challenges-ranking/disciplines/${userId}`;
  }

  static getAchievements(userId: number | 'self') {
    return `${environment.api_base_url}/challenges/achievements/user/${userId}`;
  }

  static likeAchievement(id: string) {
    return `${environment.api_base_url}/challenges/achievements/${id}/like`;
  }

  static unlikeAchievement(id: string) {
    return `${environment.api_base_url}/challenges/achievements/${id}/unlike`;
  }

  static shareExamDone(id: number) {
    return `${environment.api_base_url}/challenges/achievements/exam/${id}`;
  }
}
