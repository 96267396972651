<ng-container *ngIf="!errorSafari && !errorIOS && !errorIOS">
  <div appPanel *ngIf="isFullScreen" (closePanelEmitter)="exitFullScreen()"></div>

  <div
    *ngIf="!modalFullScreen"
    class="px-4 sm:px-0"
    [ngClass]="{
      'sm:mx-8': signalButton,
      'my-8': !fixedActionButtons,
      'fixed sm:static bottom-0 left-0 right-safeIntercom py-4 sm:py-0 sm:my-8 bg-theme-white sm:bg-transparent shadow sm:shadow-none z-1':
        fixedActionButtons
    }"
  >
    <div
      class="grid gap-4 grid-flow-col justify-start auto-cols-fr sm:auto-cols-auto pb-safeBottom sm:pb-0"
    >
      <div
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        (click)="openFullScreen()"
        class="btn-nnki btn-nnki-primary btn-nnki-icon-left"
        [class.col-span-2]="signalButton"
      >
        <svg-icon
          src="assets/images/svg-icons/icon-full-screen.svg"
          [svgStyle]="{ 'width.px': '20' }"
        ></svg-icon>
        {{ 'pdf.plein_ecran' | translate }}
      </div>
      <div
        *ngIf="showOpenButton"
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        (click)="linkService.openBlank(url)"
        class="btn-nnki btn-nnki-primary btn-nnki-icon-left"
      >
        <svg-icon
          src="assets/images/svg-icons/icon-pdf.svg"
          [svgStyle]="{ 'width.px': '20' }"
        ></svg-icon>
        {{ 'examens.ouvrir_pdf' | translate }}
      </div>
      <button
        *ngIf="signalButton"
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-primary btn-nnki-icon-left"
        (click)="signalButtonEmitter.emit()"
      >
        <svg-icon
          src="assets/images/svg-icons/icon-ask-question.svg"
          [svgStyle]="{ 'width.px': '16' }"
        ></svg-icon>
        {{ 'item.signaler' | translate }}
      </button>
    </div>
  </div>

  <div id="pdfViewer" *ngIf="!iosFullScreenOpened">
    <ngx-extended-pdf-viewer
      class="block overflow-hidden"
      [class.h-0]="loading"
      [src]="url"
      [useBrowserLocale]="true"
      [height]="isFullScreen ? '100vh' : initialHeight"
      (pdfLoaded)="pdfLoaded()"
      (pdfLoadingFailed)="pdfFailed()"
      [showPropertiesButton]="false"
      [showDownloadButton]="isApplicationService.isApplication() ? false : showOpenButton"
      [showOpenFileButton]="false"
      [showPrintButton]="isApplicationService.isApplication() ? false : showOpenButton"
      [showDrawEditor]="!isApplicationService.isApplication()"
      [showTextEditor]="!isApplicationService.isApplication()"
      [showPresentationModeButton]="false"
      [showRotateButton]="!modalFullScreen"
      [showSecondaryToolbarButton]="!modalFullScreen"
      [textLayer]="true"
      [zoom]="'page-width'"
      [ignoreKeys]="showOpenButton ? [] : ['CTRL+S', 'CTRL+P', 'CMD+S', 'CMD+P']"
      [filenameForDownload]="filenameForDownload"
    ></ngx-extended-pdf-viewer>
  </div>

  <div class="h-14 mb-safeBottom" *ngIf="addPaddingActionButtons"></div>

  <div
    class="spinner_100_container height-[80vh]"
    *ngIf="loading && !errorSafari && !errorIOS && !errorLoading"
  >
    <app-loader-dots></app-loader-dots>
  </div>
</ng-container>

<p *ngIf="errorSafari">
  {{ 'compatibility.browser_pdf' | translate }}
</p>

<p *ngIf="errorIOS">
  {{ 'compatibility.application_pdf' | translate }}
</p>

<p *ngIf="errorLoading">
  {{ 'compatibility.pdf_loading' | translate }}
</p>
