import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utf8_encode',
})
export class Utf8EncodePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/Ã€/g, 'À')
      .replace(/Ã¤/g, 'ä')
      .replace(/Ã¦/g, 'À')
      .replace(/Ã§/g, 'ç')
      .replace(/Ã‡/g, 'Ç')
      .replace(/Ã©/g, 'é')
      .replace(/Ã‰/g, 'É')
      .replace(/Ã¨/g, 'è')
      .replace(/Ãˆ/g, 'È')
      .replace(/Ãª/g, 'ê')
      .replace(/ÃŠ/g, 'Ê')
      .replace(/Ã«/g, 'ë')
      .replace(/Ã‹/g, 'Ë')
      .replace(/Ã¯/g, 'ï')
      .replace(/Å“/g, 'œ')
      .replace(/Å’/g, 'Œ')
      .replace(/Ãº/g, 'ú')
      .replace(/Ã¹/g, 'ù')
      .replace(/â€™/g, '’')
      .replace(/â€š/g, '‚')
      .replace(/â€ž/g, '„');
  }
}
