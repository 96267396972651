import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';
import { UserService } from '../../../../core/services/user.service';

import { Challenge, ChallengeInvitation } from '../../../models/challenge.model';

@Component({
  selector: 'app-challenge-description',
  templateUrl: './challenge-description.component.html',
  styleUrls: ['./challenge-description.component.scss'],
})
export class ChallengeDescriptionComponent implements OnInit {
  @Input() challenge: Challenge;
  @Input() invitation: ChallengeInvitation;

  constructor(public userService: UserService, public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}

  get users() {
    if (this.challenge) {
      return this.challenge
        .getChallengersWithoutSelf(this.userService.loggedUserValue?.id)
        .map((challenger) => {
          return challenger.user;
        });
    } else if (this.invitation) {
      return [this.invitation.challenge.author];
    } else {
      return [];
    }
  }
}
