<div class="p-8" *ngIf="!actualitiesList.length && !infiniteScrollDirective?.showLoader">
  {{ 'consensus.pas_resultats' | translate }}
</div>

<div class="grid grid-cols-[auto_1fr_auto_auto]">
  <ng-container *ngFor="let actuality of actualitiesList">
    <div class="pl-6 sm:pl-16">
      <div
        class="nnki-rounded-background-image w-16"
        [style.background-image]="sanitizerService.imageProfile(actuality?.user?.pictureUrl)"
      ></div>
    </div>

    <div>
      <div class="text-theme-gray-five text-12 font-semibold mb-2">
        {{ actuality.createdAt | amTimeAgo }}
      </div>
      <div
        class="text-14 font-semibold"
        (click)="redirectToContent(actuality)"
        [class.cursor-pointer]="actuality.type == 'exam_done'"
      >
        <p [innerHTML]="actuality?.message"></p>
      </div>
    </div>

    <div>
      <svg-icon
        *ngIf="actuality?.badge"
        src="assets/images/svg-illustrations/{{ actuality?.badge }}"
        [svgStyle]="{ 'width.px': '25' }"
      ></svg-icon>
    </div>

    <div
      class="pr-6 sm:pr-16 flex-row items-center justify-end cursor-pointer"
      (click)="likeTweet(actuality)"
    >
      <span class="text-14 font-extrabold mr-3" *ngIf="actuality?.nbLikes">
        {{ actuality?.nbLikes }}
      </span>
      <svg-icon
        [class.text-red-one]="actuality?.hasLiked"
        src="assets/images/svg-icons/{{
          actuality?.hasLiked ? 'icon-heart-solid' : 'icon-heart-stroke'
        }}.svg"
        [svgStyle]="{ 'width.px': '23' }"
      ></svg-icon>
    </div>
  </ng-container>
</div>

<app-loader-infinite-scroll
  [showInitialLoader]="infiniteScrollDirective?.showInitialLoader"
  [showScrollLoader]="infiniteScrollDirective?.showScrollLoader"
></app-loader-infinite-scroll>

<div
  appInfiniteScroll
  (needToScroll)="getTweets()"
  [scrollContainer]="inModale ? 'modalUserProfile' : null"
  [scrollContent]="inModale ? 'modalInsideUserProfile' : 'appComponentContent'"
></div>
