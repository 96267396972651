import {
  AfterViewInit,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { HttpSocialService } from './../../../core/services/http/http-social.service';
import { ModalService } from './../../../core/services/modal.service';

import { QueryCollection } from '../../models/collection.model';
import { EcniUser } from '../../models/ecni-user.model';
import { ModalListFriendsData } from '../../models/modal-data';
import { QueryData } from './../../models/query-data.model';

import { InfiniteScrollDirective } from '../../directives/infinite-scroll.directive';

@Component({
  selector: 'app-modal-list-friends',
  templateUrl: './modal-list-friends.component.html',
  styleUrls: ['./modal-list-friends.component.scss'],
})
export class ModalListFriendsComponent implements OnInit, OnDestroy, AfterViewInit {
  listFriends: EcniUser[] = [];
  totalResults;

  @ViewChild(InfiniteScrollDirective) infiniteScrollDirective: InfiniteScrollDirective;

  @HostBinding('attr.id') modalId = 'modalListFriends';

  constructor(
    public dialogRef: MatDialogRef<ModalListFriendsComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalListFriendsData,
    private httpSocialService: HttpSocialService,
    private translate: TranslateService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.modalService.addModalToStack(this.dialogRef);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getListFriends();
    });
  }

  ngOnDestroy() {
    this.modalService.removeModalFromStack(this.dialogRef);
  }

  getListFriends() {
    if (this.infiniteScrollDirective.canScroll()) {
      this.infiniteScrollDirective.initiateScroll();

      let httpRequest: Observable<QueryCollection<EcniUser>>;
      const args: QueryData = {
        sortBy: 'username',
        sortByOrder: 'ASC',
        page: this.infiniteScrollDirective.currentPage,
        perPage: 20,
      };
      if (this.modalData.type === 'followings') {
        httpRequest = this.httpSocialService.getFollowed(this.modalData.userId, args);
      } else if (this.modalData.type === 'followers') {
        httpRequest = this.httpSocialService.getFollowers(this.modalData.userId, args);
      } else if (this.modalData.type === 'waitingRequest') {
        httpRequest = this.httpSocialService.getFollowersRequests(args);
      }

      httpRequest.subscribe(
        (res) => {
          this.listFriends = this.listFriends.concat(res.data);
          this.infiniteScrollDirective.endScroll(res.metadata);
          this.totalResults = res.metadata.totalItems;
        },
        () => {
          this.infiniteScrollDirective.scrollHasErrored();
        }
      );
    }
  }

  waitingRequestProcessed(userRemoved) {
    this.listFriends = this.listFriends.filter((student) => {
      return student.id !== userRemoved.id;
    });

    if (!this.listFriends.length) {
      this.close();
    }
  }

  get titleModale() {
    if (this.modalData.type === 'followings') {
      return this.translate.instant('twit.abonnements');
    } else if (this.modalData.type === 'followers') {
      return this.translate.instant('twit.abonnes');
    } else if (this.modalData.type === 'waitingRequest') {
      return this.translate.instant('friend.en_attente');
    }
  }

  close() {
    this.dialogRef.close();
  }
}
