import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { map } from 'rxjs/operators';

import { HttpPremiumService } from '../../../core/services/http/http-premium.service';
import { IsApplicationService } from '../../../core/services/is-application.service';
import { PricingService } from '../../../core/services/pricing.service';

import { Offer, SortedOffers } from './../../models/premium.model';

@Component({
  selector: 'app-modal-premium-plus',
  templateUrl: './modal-premium-plus.component.html',
  styleUrls: ['./modal-premium-plus.component.scss'],
})
export class ModalPremiumPlusComponent implements OnInit {
  offer: Offer;

  Math = Math;

  constructor(
    public dialogRef: MatDialogRef<ModalPremiumPlusComponent>,
    public isApplicationService: IsApplicationService,
    private pricingService: PricingService
  ) {}

  ngOnInit(): void {
    this.pricingService.getSortedOffers().subscribe((res) => {
      if (res.premium_plus.length) {
        this.offer = res.premium_plus[res.premium_plus.length - 1];
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
