import { Item } from './item.model';
import { parseBooleanMember, parseFloatMember, parseIntMember } from '../utils/parse-functions';
import { Discipline } from './discipline.model';
import { AnswersCorrection, Question } from './question.model';

export class TrainingDiscipline extends Discipline {
  progressionPercentage: number;
  ancrageProgression: number;
  successPercentage: number;
  duration: number;
  nbQuestions: number;
  candidate_unfinished: boolean;

  constructor(input: any) {
    parseIntMember(input, 'progressionPercentage');
    parseIntMember(input, 'ancrageProgression');
    parseIntMember(input, 'successPercentage');
    parseIntMember(input, 'duration');
    parseIntMember(input, 'nbQuestions');
    parseBooleanMember(input, 'candidate_unfinished');

    super(input);
  }
}

export class TrainingItem extends Item {
  progressionPercentage: number;
  ancrageProgression: number;
  successPercentage: number;
  duration: number;
  nbQuestions: number;
  candidate_unfinished: boolean;
  disciplines: Discipline[];

  constructor(input: any) {
    parseIntMember(input, 'progressionPercentage');
    parseIntMember(input, 'ancrageProgression');
    parseIntMember(input, 'successPercentage');
    parseIntMember(input, 'duration');
    parseIntMember(input, 'nbQuestions');
    parseBooleanMember(input, 'candidate_unfinished');

    super(input);

    if (this.disciplines) {
      this.disciplines = this.disciplines.map((category) => {
        return new Discipline(category);
      });
    } else {
      this.disciplines = [];
    }
  }
}

export class TrainingParamsQuestions {
  onlyRevision?: number;
  onlyRevisionToday?: number;
  onlyRevisionLate?: number;
  noRevision?: number;
  options?: string;
  difficulty?: 'EASY' | 'MEDIUM' | 'HARD' | 'EXPERT';
  type?: 'one' | 'many' | 'match' | 'shortMenu' | 'imageRecognition';
  rank?: 'A' | 'B';
  lisa?: '0' | '1';
  isPediatric?: '0' | '1';
  fromAnnals?: '0' | '1';

  disciplines?: string;
  items?: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class TrainingParamsAnswers {
  id?: number;
  answer?: string;
  duration?: number;

  disciplines?: string;
  items?: string;

  constructor(input: any) {
    parseIntMember(input, 'id');
    parseIntMember(input, 'duration');

    Object.assign(this, input);
  }
}

export class TrainingStatus {
  grade: number;
  gradeMax: number;
  nbQuestions: number;
  success: number;

  constructor(input: any) {
    parseIntMember(input, 'grade');
    parseIntMember(input, 'gradeMax');
    parseIntMember(input, 'nbQuestions');
    parseFloatMember(input, 'success');

    Object.assign(this, input);
  }
}

export class TrainingCorrection {
  question: Question;
  answers: AnswersCorrection;
  reussite: number;

  constructor(input: any) {
    parseIntMember(input, 'reussite');
    Object.assign(this, input);

    if (this.question) {
      this.question = new Question(this.question);
    }

    if (this.answers) {
      this.answers = new AnswersCorrection(this.answers);
    }
  }
}

export class AncrageProgression {
  revisions: AncrageRevisions;
  next: AncrageNext;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.revisions) {
      this.revisions = new AncrageRevisions(this.revisions);
    }

    if (this.next) {
      this.next = new AncrageNext(this.next);
    }
  }
}

export class AncrageRevisions {
  late: number;
  today: number;
  total: number;

  constructor(input: any) {
    parseIntMember(input, 'late');
    parseIntMember(input, 'today');
    parseIntMember(input, 'number');
    Object.assign(this, input);
  }
}

export class AncrageNext {
  tomorrow: number;
  next_seven_days: number;
  total: number;

  constructor(input: any) {
    parseIntMember(input, 'tomorrow');
    parseIntMember(input, 'next_seven_days');
    parseIntMember(input, 'number');
    Object.assign(this, input);

    this.next_seven_days -= this.tomorrow;
  }
}
