import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import Bowser from 'bowser';

import { LinkService } from '../../../core/services/link.service';

import { ModalPdfFullScreenData } from '../../models/modal-data';

import { ModalPdfFullscreenComponent } from '../../modals/modal-pdf-fullscreen/modal-pdf-fullscreen.component';
import { IsApplicationService } from '../../../core/services/is-application.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() url;
  @Input() showOpenButton = true;
  @Input() fixedActionButtons = false;
  @Input() addPaddingActionButtons = true;
  @Input() signalButton = false;
  @Input() modalFullScreen = false;
  @Input() initialHeight = '80vh';
  @Input() filenameForDownload: string;

  @Output() signalButtonEmitter = new EventEmitter();

  loading = true;
  errorSafari: boolean;
  errorIOS: boolean;
  errorLoading: boolean;

  isFullScreen = false;

  iosFullScreenOpened = false;

  constructor(
    public linkService: LinkService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    public isApplicationService: IsApplicationService
  ) {}

  ngOnInit(): void {
    document.onfullscreenchange = () => {
      this.isFullScreen = !this.isFullScreen;
    };

    const browser = Bowser.getParser(window.navigator.userAgent);

    if (browser.getBrowserName() === 'Safari') {
      let version;
      if (browser.getBrowserVersion()) {
        version = browser.getBrowserVersion().split('.');
      }

      if (version?.length) {
        if (+version[0] < 15) {
          this.errorSafari = true;
        }
      } else {
        if (browser.getOSName() === 'iOS') {
          if (browser.getOSVersion()) {
            const osVersion = browser.getOSVersion().split('.');
            if (osVersion?.length) {
              if (+osVersion[0] < 15) {
                this.errorIOS = true;
              }
            }
          }
        }
      }
    }
  }

  pdfLoaded() {
    this.loading = false;
  }

  pdfFailed() {
    this.errorLoading = true;
  }

  openFullScreen() {
    const viewer = document.getElementById('pdfViewer');
    if (viewer && viewer.requestFullscreen) {
      viewer.requestFullscreen().then(
        () => {},
        () => {
          this.toastr.warning(this.translate.instant('util.error'));
        }
      );
    } else {
      this.iosFullScreenOpened = true;

      const modalData: ModalPdfFullScreenData = {
        url: this.url,
      };

      const dialogRef = this.matDialog.open(ModalPdfFullscreenComponent, {
        maxWidth: '100vw',
        data: modalData,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.iosFullScreenOpened = false;
      });
    }
  }

  exitFullScreen() {
    if (
      document.exitFullscreen &&
      (document.fullscreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).mozFullScreenElement)
    ) {
      document.exitFullscreen();
    }
  }
}
