import { parseBooleanMember, parseIntMember } from '../utils/parse-functions';

export class Recomed {
  id: number;
  url: string;
  title: string;
  src: string;
  year: number;
  type: string;

  viewerjs: boolean;

  constructor(input: any) {
    // api typo fix
    if (input.name && !input.title) {
      input.title = input.name;
    }

    parseIntMember(input, 'id');
    parseIntMember(input, 'year');
    parseBooleanMember(input, 'viewerjs');
    parseBooleanMember(input, 'is_recomed');

    Object.assign(this, input);
  }
}

export class RecomedSearch {
  downloads: Recomed[];

  constructor(input: any) {
    Object.assign(this, input);

    if (this.downloads) {
      this.downloads = this.downloads.map((recomed) => {
        return new Recomed(recomed);
      });
    } else {
      this.downloads = [];
    }
  }
}
