<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -large">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles -noImage">
      <div class="background -purple"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
    </div>

    <div class="modal-text">
      <div class="modal-picto -picto-svg -purple">
        <svg-icon
          src="assets/images/svg-icons/icon-challenge.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title">
        {{ 'challenge.modal_badge.titre' | translate }}
      </div>

      <div class="modal-theme">
        <p>{{ 'challenge.modal_badge.texte' | translate }}</p>
      </div>

      <div class="grid gap-4 sm:gap-8 grid-flow-col auto-cols-min">
        <div class="badge">
          <img src="assets/images/svg-illustrations/icon-challenge-1.svg" />
          <div class="badge-title">
            {{ 'challenge.modal_badge.niveau' | translate: { level: 1 } }}
          </div>
        </div>

        <div class="badge">
          <img src="assets/images/svg-illustrations/icon-challenge-2.svg" />
          <div class="badge-title">
            {{ 'challenge.modal_badge.niveau' | translate: { level: 2 } }}
          </div>
        </div>

        <div class="badge">
          <img src="assets/images/svg-illustrations/icon-challenge-3.svg" />
          <div class="badge-title">
            {{ 'challenge.modal_badge.niveau' | translate: { level: 3 } }}
          </div>
        </div>

        <div class="badge">
          <img src="assets/images/svg-illustrations/icon-challenge-4.svg" />
          <div class="badge-title">
            {{ 'challenge.modal_badge.niveau' | translate: { level: 4 } }}
          </div>
        </div>
      </div>

      <div class="modal-buttons">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
          (click)="close()"
        >
          {{ 'util.fermer' | translate }}
        </button>
      </div>

      <div class="h-[60px] sm:hidden"></div>
    </div>
  </div>
</div>
