<div appPanel (closePanelEmitter)="close(false)"></div>

<div class="modal_content">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles -noImage">
      <div class="background -green"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
    </div>

    <div class="modal-text">
      <div class="modal-picto -picto-svg -green">
        <svg-icon
          src="assets/images/svg-icons/icon-notifications.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title">
        {{ 'mailbox.notifications' | translate }}
      </div>

      <div class="modal-theme">
        <p>{{ 'notif.add1' | translate }}</p>
        <p>{{ 'notif.add2' | translate }}</p>
      </div>

      <div class="modal-buttons">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
          (click)="close(true)"
        >
          {{ 'notif.activer' | translate }}
        </button>

        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-transparent btn-nnki-uppercase"
          (click)="close(false)"
        >
          {{ 'notif.non' | translate }}
        </button>
      </div>

      <div class="h-[110px] sm:hidden"></div>
    </div>
  </div>
</div>
