import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

export class FormUtilElement {
  form: UntypedFormGroup;
  formIsSubmitted: boolean;
  hideErrors: boolean[];
}

@Injectable({
  providedIn: 'root',
})
export class FormUtilsService {
  forms: FormUtilElement[] = [];

  constructor(private translate: TranslateService) {}

  initForm(form: UntypedFormGroup, formIndex?: string) {
    if (!formIndex) formIndex = 'form';

    const formUtil: FormUtilElement = {
      form,
      formIsSubmitted: false,
      hideErrors: [],
    };

    Object.keys(form.controls).forEach((control) => {
      formUtil.hideErrors[control] = false;
    });

    this.forms[formIndex] = formUtil;
  }

  formIsSubmitted(formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    return this.forms[formIndex].formIsSubmitted;
  }

  setFormSubmitted(formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    this.forms[formIndex].formIsSubmitted = true;
  }

  unsubmitForm(formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    this.forms[formIndex].formIsSubmitted = false;
    Object.keys(this.forms[formIndex].form.controls).forEach((control) => {
      this.forms[formIndex].hideErrors[control] = false;
    });
  }

  showErrorMessage(controlName: string, formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    const control = this.forms[formIndex]?.form?.controls[controlName];
    if (control) {
      if (this.forms[formIndex].hideErrors[controlName]) {
        return false;
      } else if (
        (control.touched || this.forms[formIndex].formIsSubmitted) &&
        this.getErrorMessage(controlName, formIndex)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  getErrorMessage(controlName: string, formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    const control: UntypedFormControl = this.forms[formIndex].form?.controls[controlName];
    if (control) {
      if (control.hasError('minlength')) {
        return this.translate.instant('form.minlength', {
          minlength: control.getError('minlength').requiredLength,
        });
      } else if (control.hasError('min')) {
        return this.translate.instant('form.min', {
          val: control.getError('min').min,
        });
      } else if (control.hasError('max')) {
        return this.translate.instant('form.max', {
          val: control.getError('max').max,
        });
      } else if (control.hasError('email')) {
        return this.translate.instant('settings.error_email');
      } else if (control.hasError('pattern')) {
        if (control.getError('pattern').requiredPattern === '^true$') {
          return this.translate.instant('form.requis');
        }
      } else if (control.hasError('notSame')) {
        return this.translate.instant('settings.mdp_same');
      } else if (control.hasError('atLeastOneSubject')) {
        return this.translate.instant('training.error1');
      } else if (control.hasError('atLeastOneUser')) {
        return this.translate.instant('challenge.aumoinsun');
      } else if (control.hasError('oneDiscipline')) {
        return this.translate.instant('transversal.matiere');
      } else if (control.hasError('maxFive')) {
        return this.translate.instant('transversal.5_max');
      }
    }
    return null;
  }

  onInputFocus(controlName: string, formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    this.forms[formIndex].hideErrors[controlName] = true;
  }

  onInputBlur(controlName: string, formIndex?: string) {
    if (!formIndex) formIndex = 'form';
    this.forms[formIndex].hideErrors[controlName] = false;
  }
}
