import { parseIntMember, parseBooleanMember } from '../utils/parse-functions';

export class SocialStats {
  followings: number;
  followers: number;

  constructor(input: any) {
    parseIntMember(input, 'followings');
    parseIntMember(input, 'followers');
    Object.assign(this, input);
  }
}

export class FollowResult {
  success: boolean;
  message?: string;

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    Object.assign(this, input);
  }
}

export class FacebookImportResult {
  success: boolean;
  // nnkitodo better typing
  imported: any[];

  constructor(input: any) {
    parseBooleanMember(input, 'success');
    Object.assign(this, input);
  }
}
