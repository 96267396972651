import { ExamCustom } from './exam-custom.model';
import { Discipline } from './discipline.model';
import { EcniUser } from './ecni-user.model';
import {
  parseIntMember,
  parseDateFromTimestampMember,
  parseBooleanMember,
} from '../utils/parse-functions';

export class Challenge {
  id: string;
  code: string;
  author: EcniUser;
  examData: ExamCustom;
  challengers: Challenger[];
  challengeCandidate: ChallengeCandidate;
  finishedAt: Date;

  isDeleting: boolean;

  constructor(input: any) {
    parseIntMember(input, 'nbQuestions');
    parseDateFromTimestampMember(input, 'finishedAt');

    if (input.authorData) {
      input.author = input.authorData;
    }

    Object.assign(this, input);

    if (this.author) {
      this.author = new EcniUser(this.author);
    }

    if (this.examData) {
      this.examData = new ExamCustom(this.examData);
    }

    if (this.challengers) {
      this.challengers = this.challengers.map((user) => {
        return new Challenger(user);
      });
    } else {
      this.challengers = [];
    }

    // add author to challengers if not already there
    const author = this.challengers.find((challenger) => {
      return challenger.user.id === this.author.id;
    });
    if (!author) {
      this.challengers.push(
        new Challenger({
          user: this.author,
          challengeCandidate: null,
          isAuthor: true,
        })
      );
    }

    this.challengers = this.challengers.sort((a, b) => {
      let aScore, bScore;
      if (!a.invitationAccepted && !a.isAuthor) {
        aScore = -2;
      } else if (!a.challengeCandidate || !a.challengeCandidate.finishedAt) {
        aScore = -1;
      } else {
        aScore = a.challengeCandidate.grade;
      }

      if (!b.invitationAccepted && !b.isAuthor) {
        bScore = -2;
      } else if (!b.challengeCandidate || !b.challengeCandidate.finishedAt) {
        bScore = -1;
      } else {
        bScore = b.challengeCandidate.grade;
      }

      return aScore > bScore ? -1 : 1;
    });

    if (this.challengeCandidate) {
      this.challengeCandidate = new ChallengeCandidate(this.challengeCandidate);
    }
  }

  getUserChallengeCandidate(userId: number) {
    const challenger = this.challengers.find((challenger) => {
      if (challenger.user.id === userId) {
        return true;
      }
    });
    if (challenger) {
      return challenger.challengeCandidate;
    } else {
      return null;
    }
  }

  getUserChallengePosition(userId: number) {
    let challengerIndex = this.challengers.findIndex((challenger) => {
      if (challenger.user.id === userId) {
        return true;
      }
    });
    // gère le cas des ex aeaco
    while (
      challengerIndex > 0 &&
      this.challengers[challengerIndex]?.challengeCandidate?.grade ===
        this.challengers[challengerIndex - 1]?.challengeCandidate?.grade
    ) {
      challengerIndex--;
    }
    return challengerIndex + 1;
  }

  getChallengeNbCandidatesFinished() {
    let nbCandidates = 0;
    this.challengers.forEach((challenger) => {
      if (challenger.challengeCandidate && challenger.challengeCandidate.finishedAt) {
        nbCandidates++;
      }
    });
    return nbCandidates;
  }

  isFinished() {
    const now = new Date();
    return now > this.finishedAt;
  }

  getChallengersWithoutSelf(selfId: number) {
    if (selfId) {
      return this.challengers.filter((challenger) => {
        return challenger.user.id !== selfId;
      });
    } else {
      return this.challengers;
    }
  }
}

export class ChallengeCandidate {
  candidate: number;
  finishedAt: number;
  grade: number;
  challengeCode: string;
  disciplines: number[];

  constructor(input: any) {
    if (input.finished_at && !input.finishedAt) {
      input.finishedAt = input.finished_at;
    }

    parseIntMember(input, 'candidate');
    parseIntMember(input, 'finishedAt');
    parseIntMember(input, 'grade');

    Object.assign(this, input);
  }
}

export class ChallengeInvitation {
  id: string;
  challenge: Challenge;
  examData: ExamCustom;

  isAnswering: boolean;

  constructor(input: any) {
    Object.assign(this, input);

    if (this.challenge) {
      this.challenge = new Challenge(this.challenge);
    }

    if (this.examData) {
      this.examData = new ExamCustom(this.examData);
    }
  }
}

export class Challenger {
  invitationAccepted: boolean;
  user: ChallengeEcniUser;
  challengeCandidate: ChallengeCandidate;
  isAuthor: boolean;

  constructor(input: any) {
    parseBooleanMember(input, 'isAuthor');
    Object.assign(this, input);

    if (input.invitation && input.invitation.accepted) {
      this.invitationAccepted = true;
    } else {
      this.invitationAccepted = false;
    }

    if (this.user) {
      this.user = new ChallengeEcniUser(this.user);
    }

    if (this.challengeCandidate) {
      this.challengeCandidate = new ChallengeCandidate(this.challengeCandidate);
    }
  }
}

export class ChallengeEcniUser extends EcniUser {
  global: {
    rank: number;
    score: number;
  };

  constructor(input) {
    super(input);
  }
}

export class ChallengeSummary {
  challenges: number;
  invitations: number;

  // front created
  nbAvailable: number;

  constructor(input: any) {
    parseIntMember(input, 'challenges');
    parseIntMember(input, 'invitations');
    Object.assign(this, input);

    this.nbAvailable = this.challenges + this.invitations;
  }
}

export class ChallengeHomeRanking {
  rankingDisciplines: ChallengeHomeRankingDiscipline[];
  rankingOverall: ChallengeHomeRankingOverall;

  constructor(input: any) {
    this.rankingDisciplines = [];
    input.forEach((ranking) => {
      if (ranking.id === 'overall') {
        this.rankingOverall = new ChallengeHomeRankingOverall(ranking);
      } else {
        if (ranking.rankings.length) {
          this.rankingDisciplines.push(new ChallengeHomeRankingDiscipline(ranking));
        }
      }
    });
  }
}

export class ChallengeHomeRankingDiscipline {
  discipline: Discipline;
  rankings: ChallengeRanking[];

  constructor(input: any) {
    Object.assign(this, input);

    this.discipline = new Discipline({
      id: input.id,
      name: input.name,
    });

    if (this.rankings) {
      this.rankings = this.rankings.map((ranking) => {
        return new ChallengeRanking(ranking);
      });
    } else {
      this.rankings = [];
    }
  }
}

export class ChallengeHomeRankingOverall {
  rankings: ChallengeRanking[];

  constructor(input: any) {
    Object.assign(this, input);

    if (this.rankings) {
      this.rankings = this.rankings.map((ranking) => {
        return new ChallengeRanking(ranking);
      });
    } else {
      this.rankings = [];
    }
  }
}

export class ChallengeRanking {
  rank: number;
  score: number;
  user: EcniUser;

  constructor(input: any) {
    if (input.user_info) {
      input.user = input.user_info;
    }

    parseIntMember(input, 'rank');
    parseIntMember(input, 'score');

    Object.assign(this, input);

    if (this.user) {
      this.user = new EcniUser(this.user);
    }
  }
}

export class ChallengeRankingLevel {
  level: number;
  name: string;

  constructor(input: any) {
    parseIntMember(input, 'level');
    Object.assign(this, input);
  }
}

export class ChallengeVictories {
  wins: number;
  loses: number;
  total: number;

  constructor(input: any) {
    parseIntMember(input, 'wins');
    parseIntMember(input, 'loses');
    parseIntMember(input, 'total');

    Object.assign(this, input);
  }
}

export class ChallengeAchievement {
  badge: string;
  createdAt: Date;
  id: string;
  message: string;
  user: EcniUser;

  nbLikes: number;
  hasLiked: boolean;

  type: string;
  exam?: number;

  constructor(input: any) {
    if (input.user_info) {
      input.user = input.user_info;
    }

    parseDateFromTimestampMember(input, 'createdAt');
    parseIntMember(input, 'nbLikes');
    parseBooleanMember(input, 'hasLiked');

    parseIntMember(input, 'exam');

    Object.assign(this, input);

    if (this.user) {
      this.user = new EcniUser(this.user);
    }
  }
}

export class ChallengePostData {
  examId: number;
  finishedAt: string;
}

export class ChallengeInvitePostData {
  users: string;
}
