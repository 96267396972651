import { Question } from './question.model';
import {
  parseIntMember,
  parseBooleanMember,
  parseFloatMember,
  parseDateMember,
} from '../utils/parse-functions';
import { EcniDocument } from './ecni-document.model';
import { Item } from './item.model';
import { Histogram } from './histogram.model';

export class Folder {
  id: number;
  name: string;
  numName: string;
  content: string;
  questions: Question[];
  hasQI: boolean;
  folderIndex: number;
  grade: number;
  points: number;
  pointsMax: number;
  updatedAt: Date;
  items: Item[];

  isCompleted: boolean;

  documentsImg: EcniDocument[];
  documentsPdf: EcniDocument[];
  documentsVideo: EcniDocument[];
  documents: EcniDocument[];
  contentPdfUrl: string;

  questionsAnsweredCount: number;

  type: 'folder' | 'qi';

  userGrade: number;

  histogram: Histogram;

  pointsRangA = 0;
  pointsMaxRangA = 0;

  constructor(input: any) {
    parseIntMember(input, 'id');
    parseBooleanMember(input, 'hasQi');
    parseIntMember(input, 'folderIndex');
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'points');
    parseFloatMember(input, 'pointsMax');
    parseDateMember(input, 'updatedAt');

    parseBooleanMember(input, 'isCompleted');

    parseIntMember(input, 'questionsAnsweredCount');

    parseIntMember(input, 'userGrade');

    if (input.title) {
      input.name = input.title;
    }

    Object.assign(this, input);

    if (this.questions) {
      this.questions = this.questions.map((question) => {
        return new Question(question);
      });
    } else {
      this.questions = [];
    }

    if (this.items) {
      this.items = this.items.map((item) => {
        return new Item(item);
      });
    } else {
      this.items = [];
    }

    if (this.documentsImg) {
      this.documentsImg = this.documentsImg.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsImg = [];
    }

    if (this.documentsPdf) {
      this.documentsPdf = this.documentsPdf.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsPdf = [];
    }

    if (this.documentsVideo) {
      this.documentsVideo = this.documentsVideo.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsVideo = [];
    }

    if (this.documents) {
      this.documents = this.documents.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documents = [];
    }
  }
}
