import { environment } from '../../../environments/environment';

export class PremiumEndpoints {
  static getOffers = `${environment.api_base_url}/offers`;
  static verifyCoupon = `${environment.api_base_url}/coupons`;
  static patchSubscription = `${environment.api_base_url}/subscriptions/source`;
  static transactions = `${environment.api_base_url}/transactions`;
  static isIOSPremiumEnabled = `${environment.api_base_url}/offers/enable`;

  static chargePremium(id: number) {
    return `${environment.api_base_url}/offers/premium/${id}/charge`;
  }

  static chargeConversion(id: number) {
    return `${environment.api_base_url}/offers/conversion/${id}/charge`;
  }

  static scaConfirm(id: string) {
    return `${environment.api_base_url}/offers/sca/intent/confirm/${id}`;
  }
}
