import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalPdfFullScreenData } from '../../models/modal-data';

@Component({
  selector: 'app-modal-pdf-fullscreen',
  templateUrl: './modal-pdf-fullscreen.component.html',
  styleUrls: ['./modal-pdf-fullscreen.component.scss'],
})
export class ModalPdfFullscreenComponent implements OnInit {
  url: string;

  constructor(
    public dialogRef: MatDialogRef<ModalPdfFullscreenComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalPdfFullScreenData
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.url = this.modalData.url;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
