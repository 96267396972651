<div *ngIf="documents.length">
  <ng-container *ngFor="let document of documents | slice: 0:1">
    <img
      class="w-full rounded relative cursor-pointer"
      (click)="openModalImage(0)"
      [src]="document.fileUrl_thumb"
      [appFallback]="document.fileUrl"
      alt=""
    />
  </ng-container>

  <div class="mt-4 grid gap-4 grid-cols-4">
    <div
      class="rounded overflow-hidden cursor-pointer aspect-w-1 aspect-h-1"
      (click)="openModalImage(i + 1)"
      *ngFor="let image of documents | slice: 1:4; let i = index"
    >
      <img
        class="object-cover object-center"
        [src]="image.fileUrl_icon"
        [appFallback]="image.fileUrl"
        alt=""
      />
    </div>

    <div
      class="rounded cursor-pointer bg-theme-white border border-theme-gray-three text-site-main-one font-extrabold flex-center"
      *ngIf="documents.length > 4"
      (click)="openModalImage(4)"
    >
      <span>+{{ documents.length - 4 }} </span>
    </div>
  </div>
</div>
