import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { CorrectionCandidate, StartExam } from '../../../shared/models/candidate.model';
import { QueryCollection } from '../../../shared/models/collection.model';
import { Exam } from '../../../shared/models/exam.model';
import { ExamQueryData } from '../../../shared/models/query-data.model';
import { EcosEndpoints } from '../../../shared/endpoints/ecos.endpoints';
import { EcosDoActionResult } from '../../../shared/models/ecos.model';

@Injectable({
  providedIn: 'root',
})
export class HttpEcosService {
  constructor(private http: HttpClient) {}

  query(options: ExamQueryData) {
    return this.http
      .get(EcosEndpoints.query, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((examRaw) => {
            return new Exam(examRaw);
          });
          return new QueryCollection<Exam>(res);
        })
      );
  }

  get(id: number) {
    return this.http.get(EcosEndpoints.get(id)).pipe(
      map((res: any) => {
        const exam = new Exam(res.data);
        return exam;
      })
    );
  }

  start(id: number) {
    return this.http.get(EcosEndpoints.start(id)).pipe(
      map((res: any) => {
        const exam = new StartExam(res.data);
        return exam;
      })
    );
  }

  doAction(
    id: number,
    actionMode: 'question' | 'examine' | 'perform' | 'diagnose' | 'care' | 'explain',
    actionContent: string
  ) {
    return this.http
      .post(EcosEndpoints.doAction(id), {
        type: actionMode,
        text: actionContent,
      })
      .pipe(
        map((res: any) => {
          return res.data as EcosDoActionResult;
        })
      );
  }

  close(id: number) {
    return this.http.put(EcosEndpoints.close(id), {});
  }

  getCandidate(id: number) {
    return this.http.get(EcosEndpoints.getCandidate(id)).pipe(
      map((res: any) => {
        return new CorrectionCandidate(res.data);
      })
    );
  }
}
