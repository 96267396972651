<div
  class="absolute top-0 bottom-0 right-0 left-0 overflow-hidden"
  [class.-premium]="type === 'premium'"
>
  <div
    class="oval opacity-25"
    [ngClass]="{
      'w-[35px] h-[35px] border-[6px] left-[86px] bottom-[-17px]': type !== 'premium',
      'w-[100px] h-[100px] border-[13px] right-[-30px] bottom-[-53px]': type === 'premium'
    }"
  ></div>
  <div
    class="oval opacity-20"
    [ngClass]="{
      'w-[119px] h-[119px] border-[13px] right-[132px] top-[-82px]': type !== 'premium',
      'w-[80px] h-[80px] border-[9px] left-[-35px] top-[-30px]': type === 'premium'
    }"
  ></div>
  <div
    class="oval opacity-[0.15]"
    [ngClass]="{
      'w-[60px] h-[60px] border-[11px] right-[-17px] bottom-[-32px]': type !== 'premium',
      'w-[35px] h-[35px] border-[5px] left-[10px] bottom-[-20px]': type === 'premium'
    }"
  ></div>
</div>
