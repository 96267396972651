import { DecimalPipe } from '@angular/common';
import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { timer, BehaviorSubject, Subscription } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ExamService } from '../../../core/services/exam.service';
import { HttpExamCustomService } from '../../../core/services/http/http-exam-custom.service';
import { HttpExamService } from '../../../core/services/http/http-exam.service';
import { PusherService } from '../../../core/services/pusher.service';
import { ChallengeService } from './../../../core/services/challenge.service';
import { SoundService } from './../../../core/services/sound.service';

import { BaseComponent } from '../../components/inherited/base/base.component';

import { Exam } from '../../models/exam.model';
import {
  ModalCandidateChallengeData,
  ModalWarningData,
  ModalWarningResult,
} from '../../models/modal-data';
import { Question } from '../../models/question.model';
import { ExamCustom } from './../../models/exam-custom.model';

import { ModalWarningComponent } from '../modal-warning/modal-warning.component';

import { getRandomInt } from '../../utils/letters';
import { supportedScroll } from '../../utils/scroll';

@Component({
  selector: 'app-modal-candidate-challenge',
  templateUrl: './modal-candidate-challenge.component.html',
  styleUrls: ['./modal-candidate-challenge.component.scss'],
  providers: [DecimalPipe],
})
export class ModalCandidateChallengeComponent extends BaseComponent implements OnInit, OnDestroy {
  modalMode: 'intro' | 'question-intro' | 'question' | 'end' = 'intro';

  validatingAnswer = false;

  examCode: string;
  exam: ExamCustom;

  challengeCode: string;

  candidateId: number;

  currentQuestion: Question;

  initialTimestamp: number;
  initialCountDown = 0;
  timeElapsedTotal = 0;
  intervalTimeRemaining: Subscription;

  channelName: string;

  isRetake = false;

  examCompleted = false;
  examInProgress = false;

  progressBarPercentage = 0;

  examIsReady = new BehaviorSubject<boolean>(false);

  @ViewChild('introCandidateChallenge') introCandidateChallenge;
  @ViewChild('introQuestionChallenge') introQuestionChallenge;
  @ViewChild('questionChallenge') questionChallenge;
  @ViewChild('endChallenge') endChallenge;

  @HostBinding('attr.id') modalId = 'modalCandidateChallenge';

  constructor(
    public dialogRef: MatDialogRef<ModalCandidateChallengeComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalCandidateChallengeData,
    private httpExamCustomService: HttpExamCustomService,
    private pusher: PusherService,
    private examService: ExamService,
    private matDialog: MatDialog,
    private httpExamService: HttpExamService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private challengeService: ChallengeService,
    private soundService: SoundService,
    private decimalPipe: DecimalPipe,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
    this.dialogRef.disableClose = true;

    this.examCode = this.modalData.examCode;
    this.challengeCode = this.modalData.challengeCode;
  }

  ngOnInit(): void {
    this.httpExamCustomService.get(this.examCode).subscribe((res) => {
      this.exam = res;

      if (this.modalMode === 'intro') {
        setTimeout(() => {
          this.introCandidateChallenge.launchAnimationsEntry();
        });
      }
    });

    if (this.soundService.canPlayChallengeSounds()) {
      this.soundService.initSound('challenge-music', 'assets/sounds/dr_house_theme.mp3', true, 0.6);
      this.soundService.initSound(
        'challenge-right-answer',
        'assets/sounds/right_answer.mp3',
        false
      );
      this.soundService.initSound(
        'challenge-wrong-answer',
        'assets/sounds/wrong_answer.mp3',
        false
      );

      this.soundService.playSound('challenge-music');
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.intervalTimeRemaining) {
      this.intervalTimeRemaining.unsubscribe();
    }

    if (this.examInProgress && !this.examCompleted && this.candidateId) {
      this.httpExamService.pauseCandidate(this.candidateId).subscribe(() => {
        this.toastr.success(this.translate.instant('examens.pause_ok'));
      });
    }

    if (this.examInProgress) {
      this.googleAnalyticsService.sendEvent('pause', 'challenge', this.exam.title);
    } else if (this.examCompleted) {
      this.googleAnalyticsService.sendEvent('stop', 'challenge', this.exam.title);
    }

    this.pusher.unsubscribeTo(this.channelName);
    this.soundService.stopSound('challenge-music');

    this.challengeService.updateChallengeSummary(false);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.examInProgress) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  close() {
    let canClose = false;
    if (this.examInProgress) {
      if (confirm(this.translate.instant('quit.confirm'))) {
        canClose = true;
      }
    } else {
      canClose = true;
    }
    if (canClose) {
      this.dialogRef.close();
    }
  }

  loadChallenge() {
    this.channelName = 'presence-' + this.examCode;
    this.pusher.subscribeTo(this.channelName);

    this.examService.getExamCandidatesObservable(this.exam).subscribe((examCandidates) => {
      const pausedCandidateId = this.examService.isCandidateRetake(examCandidates);
      if (pausedCandidateId) {
        this.candidateId = pausedCandidateId;
        this.retakeExam(pausedCandidateId, this.examCode);
      } else {
        this.startNewExam(this.exam);
      }
    });
  }

  startNewExam(examData: Exam) {
    this.httpExamCustomService.start((examData as ExamCustom).code).subscribe((examStart) => {
      this.candidateId = examStart.candidate.id;

      this.initialTimestamp = new Date().getTime();
      this.initialCountDown = examStart.candidate.initialTimeLeft * 60;
      this.timeElapsedTotal = 0;

      this.googleAnalyticsService.sendEvent('start', 'challenge', this.exam.title);

      this.initExam();
    });
  }

  retakeExam(pausedCandidateId: number, examIdentifier: string | number) {
    this.httpExamService.retakeCandidate(pausedCandidateId, examIdentifier).subscribe(
      (retake) => {
        this.isRetake = true;
        this.exam.questions = retake.exam.questions;

        this.initialTimestamp =
          new Date().getTime() -
          (retake.candidate.initialTimeLeft - retake.candidate.timeLeft) * 60 * 1000;
        this.initialCountDown = retake.candidate.initialTimeLeft * 60;
        this.timeElapsedTotal = Math.max(
          (retake.candidate.initialTimeLeft - retake.candidate.timeLeft) * 60,
          0
        );

        this.googleAnalyticsService.sendEvent('retake', 'challenge', this.exam.title);

        this.initExam();
      },
      (err) => {
        this.openModalRetakeError();
      }
    );
  }

  initExam() {
    this.examInProgress = true;

    this.intervalTimeRemaining = timer(0, 1000).subscribe(() => {
      this.computeTimeElapsed();
      this.timeElapsedTotal = (new Date().getTime() - this.initialTimestamp) / 1000;
    });

    // initialisation des questions
    this.exam.questions.forEach((question, idx) => {
      question.questionIndex = idx;

      // initialisation des answers
      question.answers.forEach((answer) => {
        answer.userChecked = false;
        answer.randomOrder = getRandomInt();
      });

      // Check if question was answered
      question.isAnswered = false;

      // Prefill answers from retaked candidate
      if (this.isRetake) {
        if (question.candidateAnswers && question.candidateAnswers.length > 0) {
          question.isAnswered = true;
        }
      }

      // init question documents
      question.documentsImg = [];
      question.documentsPdf = [];
      question.documentsVideo = [];

      if (question.documents) {
        question.documents.forEach((document) => {
          if (document.embed && document.embed.toLowerCase().includes('youtube')) {
            question.documentsVideo.push(document);
          } else if (document.fileUrl && document.fileUrl.toLowerCase().includes('.pdf')) {
            question.documentsPdf.push(document);
          } else if (document.fileName) {
            question.documentsImg.push(document);
          }
        });
      }
    });

    this.examIsReady.next(true);
  }

  computeTimeElapsed() {
    this.progressBarPercentage = Math.min(
      (this.timeElapsedTotal / this.initialCountDown) * 100,
      100
    );

    // if (this.getTimeRemaining() <= 0) {
    //   this.openTimesUpModal();
    //   this.intervalTimeRemaining.unsubscribe();
    // }
  }

  getTimeRemaining() {
    return Math.max(this.initialCountDown - this.timeElapsedTotal, 0);
  }

  validateAnswer() {
    if (!this.validatingAnswer) {
      this.validatingAnswer = true;

      let answerToSend: string;

      if (this.currentQuestion.type === 'question.match') {
        if (this.currentQuestion.userAnswerMatch) {
          answerToSend = this.currentQuestion.userAnswerMatch;
        }
      } else if (this.currentQuestion.type === 'question.imageRecognition') {
        answerToSend = `${this.currentQuestion.userAnswerZAP.x}:${this.currentQuestion.userAnswerZAP.y}`;
      } else {
        const answerToSendArray: number[] = [];
        this.currentQuestion.answers.forEach((answer) => {
          if (answer.userChecked) {
            answerToSendArray.push(answer.id);
          }
        });
        answerToSend = answerToSendArray.toString();
      }

      if (answerToSend && answerToSend.length) {
        this.postAnswer(answerToSend);
      } else {
        this.validatingAnswer = false;
        this.toastr.warning(this.translate.instant('examens.pas_reponse'));
      }
    }
  }

  postAnswer(answer: string) {
    this.httpExamService
      .postCandidateAnswer(this.candidateId, this.currentQuestion.id, answer)
      .subscribe(
        (res) => {
          this.currentQuestion.isAnswered = true;
          this.validatingAnswer = false;
          this.postAnswerFulfilled();

          this.questionChallenge.launchAnimationsAnswer(res);

          setTimeout(() => {
            this.questionChallenge
              .launchAnimationsExit()
              .pipe(first())
              .subscribe(() => {
                const modal = document.getElementById('modalCandidateChallenge');
                if (modal) {
                  supportedScroll(modal, 0);
                }

                this.launchNextQuestionIntro();
              });
          }, 3000);
        },
        () => {
          this.validatingAnswer = false;

          const modalData: ModalWarningData = {
            title: this.translate.instant('examens.error_reponse'),
            content: this.translate.instant('examens.error_reponse2'),
            buttonText: this.translate.instant('util.reessayer'),
          };

          this.matDialog.open(ModalWarningComponent, {
            maxWidth: '100vw',
            data: modalData,
          });
        }
      );
  }

  postAnswerFulfilled() {
    this.googleAnalyticsService.sendEvent('question answered', 'challenge', this.exam.title);
  }

  completeExam() {
    this.examCompleted = true;
    this.examInProgress = false;

    this.httpExamService.closeCandidate(this.candidateId).subscribe(
      () => {
        this.launchEndExam();
      },
      () => {
        this.examCompleted = false;
        this.close();
      }
    );
  }

  openModalRetakeError() {
    const modalData: ModalWarningData = {
      title: this.translate.instant('examens.error_reprise'),
      content:
        this.translate.instant('examens.error_reprise2') +
        '<br><br>' +
        '<em class="text-red-one">' +
        this.translate.instant('examens.code_erreur') +
        ` : ERR_CANT_RETAKE_EXAM</em>`,
      buttonText: this.translate.instant('util.retour'),
    };

    const dialogRef = this.matDialog.open(ModalWarningComponent, {
      maxWidth: '100vw',
      data: modalData,
    });

    dialogRef.afterClosed().subscribe((result: ModalWarningResult) => {
      this.close();
    });
  }

  openTimesUpModal() {
    const modalData: ModalWarningData = {
      title: this.translate.instant('examens.temps_ecoule2'),
      content:
        this.translate.instant('examens.temps_total') +
        ' : ' +
        this.decimalPipe.transform(this.initialCountDown / 60, '0.0-0') +
        ' ' +
        this.translate.instant('examens.minutes') +
        '<br><br>' +
        this.translate.instant('examens.temps_restant') +
        ' : ' +
        this.decimalPipe.transform(this.getTimeRemaining() / 60, '0.0-0') +
        ' ' +
        this.translate.instant('examens.minutes') +
        '<br><br>' +
        this.translate.instant('examens.temps_ecoule3'),
      buttonText: this.translate.instant('examens.voir_resultats'),
    };

    const dialogRef = this.matDialog.open(ModalWarningComponent, {
      maxWidth: '100vw',
      data: modalData,
    });

    dialogRef.afterClosed().subscribe((result: ModalWarningResult) => {
      this.completeExam();
    });
  }

  launchChallenge() {
    this.examIsReady
      .pipe(
        takeUntil(this.alive$),
        filter((isReady) => {
          return isReady;
        })
      )
      .subscribe(() => {
        this.launchNextQuestionIntro();
      });
  }

  launchNextQuestionIntro() {
    this.currentQuestion = this.exam.questions.find((question) => {
      return !question.isAnswered;
    });

    if (!this.currentQuestion) {
      this.completeExam();
    } else {
      this.modalMode = 'question-intro';
      setTimeout(() => {
        this.introQuestionChallenge.launchAnimationsEntry();
      });
    }
  }

  launchQuestionContent() {
    this.modalMode = 'question';
    setTimeout(() => {
      this.questionChallenge.launchAnimationsEntry();
    });
  }

  launchEndExam() {
    this.modalMode = 'end';
    setTimeout(() => {
      this.endChallenge.launchAnimationsEntry();
    });
  }
}
