import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SanitizerService {
  constructor(private domSanitizer: DomSanitizer) {}

  defaultImage() {
    return `assets/images/thumbnails/someone-1.jpg`;
  }

  defaultImageToBackground() {
    return this.toBackground(this.defaultImage());
  }

  toBackground(url: string) {
    const newUrl = 'url(' + url + ')';
    return this.domSanitizer.bypassSecurityTrustStyle(newUrl);
  }

  imageProfile(url: string) {
    if (
      url &&
      url !== 'https://files.edn.fr/uploads/users/' &&
      url !== 'https://files.edn.fr/img/someone.jpg' &&
      url !== 'https://api-dev.edn.fr/img/someone.jpg' &&
      url !== 'https://files.ecni.fr/uploads/users/' &&
      url !== 'https://files.ecni.fr/img/someone.jpg' &&
      url !== 'https://api-dev.ecni.fr/img/someone.jpg' &&
      url !== '/img/someone.jpg'
    ) {
      return this.toBackground(url);
    } else {
      return this.defaultImageToBackground();
    }
  }
}
