import { parseIntMember, parseBooleanMember } from '../utils/parse-functions';
export class EcniDocument {
  id: number;
  embed: string;
  fileName: string;
  fileUrl: string;
  fileUrl_thumb: string;
  fileUrl_icon: string;

  constructor(input: any) {
    // fill data if not existing

    if (!input.fileUrl_thumb) {
      input.fileUrl_thumb = input.fileUrl;
    }

    if (!input.fileUrl_icon) {
      input.fileUrl_icon = input.fileUrl_thumb;
    }

    parseIntMember(input, 'id');

    Object.assign(this, input);
  }

  get imageUrl() {
    return this.fileUrl;
  }

  get url() {
    return this.fileUrl;
  }
}
