import { Injectable, Injector } from '@angular/core';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private injector: Injector) {}

  load(): Promise<boolean> {
    const userService = this.injector.get(UserService);

    return new Promise((resolve, reject) => {
      userService.recoverSession().subscribe(
        () => resolve(true),
        () => resolve(true)
      );
    });
  }
}
