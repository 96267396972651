import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarSearchService {
  private searchValue$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  searchValueObservable$ = this.searchValue$.asObservable();

  constructor() {}

  search(search: string) {
    this.searchValue$.next(search.trim());
  }

  resetSearch() {
    this.searchValue$.next(null);
  }
}
