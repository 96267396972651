import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-component',
  template: '',
})
export class BaseComponent implements OnDestroy {
  protected alive$ = new Subject<boolean>();

  constructor() {}

  ngOnDestroy(): void {
    this.alive$.next(false);
  }
}
