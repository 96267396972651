import {
  parseIntMember,
  parseBooleanMember,
  parseFloatMember,
  parseDateMember,
} from '../utils/parse-functions';
import { Discipline } from './discipline.model';

export class TrainingSessionLog {
  discipline_id: number;
  discipline_name: string;
  item_id: number;
  item_title: string;
  item_number: number;
  createdAt: Date;
  duration: number;
  nbQuestions: number;
  success: number;
  notFinished: boolean;

  constructor(input: any) {
    parseIntMember(input, 'discipline_id');
    parseIntMember(input, 'item_id');
    parseIntMember(input, 'item_number');
    parseIntMember(input, 'duration');
    parseIntMember(input, 'nbQuestions');
    parseIntMember(input, 'success');
    parseBooleanMember(input, 'notFinished');
    parseDateMember(input, 'createdAt');
    Object.assign(this, input);
  }
}
export class ExamSessionLog {
  exam_id: number;
  exam_code: string;
  exam_title: string;
  createdAt: Date;
  exam_type: string;
  exam_disciplineMain_id: number;
  exam_source: 'official' | 'custom';
  grade: number;
  gradeMax: number;
  first: boolean;
  notFinished: boolean;
  tooltip: string;
  positionWorld: string;
  positionCity: string;
  positionFac: string;
  positionPromo: string;

  constructor(input: any) {
    parseIntMember(input, 'exam_disciplineMain_id');
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'gradeMax');
    parseBooleanMember(input, 'first');
    parseBooleanMember(input, 'notFinished');
    parseIntMember(input, 'exam_id');
    parseDateMember(input, 'createdAt');
    Object.assign(this, input);
  }
}

export class FoldersDoneLog {
  id: number;
  title: string;
  firstCandidateId: number;
  candidate_exam_id: number;
  candidate_exam_title: string;
  grade: number;
  disciplines: Discipline[];
  candidates_count: number;
  points: number;
  gradeMax: number;
  is_favorite: boolean;

  constructor(input: any) {
    parseIntMember(input, 'id');
    parseIntMember(input, 'firstCandidateId');
    parseIntMember(input, 'candidate_exam_id');
    parseFloatMember(input, 'grade');
    parseIntMember(input, 'candidates_count');
    parseFloatMember(input, 'points');
    parseFloatMember(input, 'gradeMax');
    parseBooleanMember(input, 'is_favorite');
    Object.assign(this, input);

    if (this.disciplines) {
      this.disciplines = this.disciplines.map((category) => {
        return new Discipline(category);
      });
    } else {
      this.disciplines = [];
    }
  }

  get tooltipFavorite() {
    if (!this.is_favorite) {
      return 'item.mettre_favoris';
    } else {
      return 'item.enlever_favoris';
    }
  }
}
