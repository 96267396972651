import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ExamCandidates, StartExam } from '../../../shared/models/candidate.model';
import { QueryCollection } from '../../../shared/models/collection.model';
import { ExamCustom, ExamCustomPostData } from '../../../shared/models/exam-custom.model';
import { QuestionsAndFolders } from '../../../shared/models/exam.model';
import { Feedback } from '../../../shared/models/feedback.model';
import { ExamQueryData, QueryData } from '../../../shared/models/query-data.model';

import { ExamEndpoints } from '../../../shared/endpoints/exam.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpExamCustomService {
  constructor(private http: HttpClient) {}

  query(options: ExamQueryData) {
    return this.http
      .get(ExamEndpoints.queryCustom, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((examRaw) => {
            return new ExamCustom(examRaw);
          });
          return new QueryCollection<ExamCustom>(res);
        })
      );
  }

  get(code: string) {
    return this.http.get(ExamEndpoints.getCustom(code)).pipe(
      map((res: any) => {
        const exam = new ExamCustom(res);
        return exam;
      })
    );
  }

  start(code: string) {
    return this.http.get(ExamEndpoints.startCustom(code)).pipe(
      map((res: any) => {
        const startExam = new StartExam(res);
        return startExam;
      })
    );
  }

  getCandidates(code: string) {
    return this.http.get(ExamEndpoints.getCandidatesCustom(code)).pipe(
      map((res: any) => {
        const examCandidates = new ExamCandidates(res);
        return examCandidates;
      })
    );
  }

  getQuestionsAndFolders(code: string) {
    return this.http.get(ExamEndpoints.getQuestionsAndFoldersCustom(code)).pipe(
      map((res: any) => {
        const feedbackQuestions = new QuestionsAndFolders({
          folders: [],
          questions: res,
        });
        return feedbackQuestions;
      })
    );
  }

  getFeedbacksQI(code: string, options: QueryData) {
    return this.http
      .get(ExamEndpoints.getFeedbacksQICustom(code), {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((feedbackRaw) => {
            return new Feedback(feedbackRaw);
          });
          return new QueryCollection<Feedback>(res);
        })
      );
  }

  createExamCustom(options: ExamCustomPostData) {
    return this.http.post(ExamEndpoints.createExamCustom, options).pipe(
      map((examRaw: any) => {
        const exam = new ExamCustom(examRaw);
        return exam;
      })
    );
  }

  deleteExamCustom(code: string) {
    return this.http.delete(ExamEndpoints.deleteExamCustom(code));
  }
}
