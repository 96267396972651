import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-challenge-badge-infos',
  templateUrl: './modal-challenge-badge-infos.component.html',
  styleUrls: ['./modal-challenge-badge-infos.component.scss'],
})
export class ModalChallengeBadgeInfosComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ModalChallengeBadgeInfosComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
