import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';

import {
  CorrectionCandidate,
  ExamCandidates,
  RetakeCandidate,
  StartExam,
} from '../../../shared/models/candidate.model';
import { QueryCollection } from '../../../shared/models/collection.model';
import {
  ConftubeCorrection,
  Exam,
  QuestionsAndFolders,
  RateFolder,
} from '../../../shared/models/exam.model';
import { Feedback } from '../../../shared/models/feedback.model';
import { Histogram } from '../../../shared/models/histogram.model';
import {
  ExamQueryData,
  QueryData,
  StudentRankingQueryData,
} from '../../../shared/models/query-data.model';
import { AnswersCorrection } from '../../../shared/models/question.model';
import { FacRanking, StudentRanking } from '../../../shared/models/ranking.model';
import { ExamSessionLog } from '../../../shared/models/session-log.model';

import { ExamEndpoints } from '../../../shared/endpoints/exam.endpoints';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpExamService {
  constructor(private translate: TranslateService, private http: HttpClient) {}

  query(options: ExamQueryData) {
    return this.http
      .get(ExamEndpoints.query, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((examRaw) => {
            return new Exam(examRaw);
          });
          return new QueryCollection<Exam>(res);
        })
      );
  }

  queryLanding() {
    return this.http.get(ExamEndpoints.queryLanding).pipe(
      map((res: any) => {
        res.data = res.data.map((examRaw) => {
          return new Exam(examRaw);
        });
        return new QueryCollection<Exam>(res);
      })
    );
  }

  get(id: number) {
    return this.http.get(ExamEndpoints.get(id)).pipe(
      map((res: any) => {
        const exam = new Exam(res);
        return exam;
      })
    );
  }

  start(id: number) {
    return this.http.get(ExamEndpoints.start(id)).pipe(
      map((res: any) => {
        const startExam = new StartExam(res);
        return startExam;
      })
    );
  }

  startWithParent(id: number, parentCandidateId: number) {
    const options = {
      parentCandidateId,
    };
    return this.http
      .get(ExamEndpoints.start(id), {
        params: options as unknown as HttpParams,
      })
      .pipe(
        map((res: any) => {
          const startExam = new StartExam(res);
          return startExam;
        })
      );
  }

  getCandidates(examId: number) {
    return this.http.get(ExamEndpoints.getCandidates(examId)).pipe(
      map((res: any) => {
        const examCandidates = new ExamCandidates(res);
        return examCandidates;
      })
    );
  }

  retakeCandidate(id: number, examIdentifier: string | number) {
    return this.http.get(ExamEndpoints.retakeCandidate(id)).pipe(
      map((res: any) => {
        if (typeof examIdentifier === 'string' && res.exam) {
          res.exam.code = examIdentifier;
        }
        const retakeCandidate = new RetakeCandidate(res);
        return retakeCandidate;
      })
    );
  }

  getCandidate(candidateId: number) {
    return this.http.get(ExamEndpoints.getCandidate(candidateId)).pipe(
      map((res: any) => {
        const correctionCandidate = new CorrectionCandidate(res);
        return correctionCandidate;
      })
    );
  }

  postCandidateAnswer(candidateId: number, questionId: number, answer: string) {
    return this.http
      .post(ExamEndpoints.postCandidateAnswer(candidateId, questionId), {
        answer,
      })
      .pipe(
        map((res) => {
          return new AnswersCorrection(res);
        }),
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      );
  }

  postConftubeAnswer(candidateId: number, questionId: number, answer: string) {
    return this.http
      .post(ExamEndpoints.postConftubeAnswer(candidateId, questionId), {
        answer,
      })
      .pipe(
        map((trainingCorrectionRaw: any) => {
          const trainingCorrection = new ConftubeCorrection(trainingCorrectionRaw);
          return trainingCorrection;
        }),
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      );
  }

  pauseCandidate(id: number) {
    return this.http.post(ExamEndpoints.pauseCandidate(id), {});
  }

  closeCandidate(candidateId: number) {
    return this.http.post(ExamEndpoints.closeCandidate(candidateId), {});
  }

  getQuestionsAndFolders(id: number) {
    return this.http.get(ExamEndpoints.getQuestionsAndFolders(id)).pipe(
      map((res: any) => {
        const feedbackQuestions = new QuestionsAndFolders(res);
        return feedbackQuestions;
      })
    );
  }

  getFeedbacksQI(id: number, options: QueryData) {
    return this.http
      .get(ExamEndpoints.getFeedbacksQI(id), {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((feedbackRaw) => {
            return new Feedback(feedbackRaw);
          });
          return new QueryCollection<Feedback>(res);
        })
      );
  }

  getStudentsRanking(examId: number, options: StudentRankingQueryData) {
    return this.http
      .get(ExamEndpoints.getStudentsRanking(examId), {
        params: options as unknown as HttpParams,
      })
      .pipe(
        map((res: any) => {
          if (res.success) {
            res.data = res.data.map((studentRankingRaw) => {
              return new StudentRanking(studentRankingRaw);
            });
            return new QueryCollection<StudentRanking>(res);
          } else {
            throw new Error('not available');
          }
        })
      );
  }

  getFacRanking(id: number) {
    return this.http.get(ExamEndpoints.getFacRanking(id)).pipe(
      map((res: any) => {
        const resFormatted = {
          data: res.map((facRankingRaw) => {
            return new FacRanking(facRankingRaw);
          }),
          metadata: {
            totalItems: res.length,
            totalPages: 1,
            itemsPerPage: 0,
            currentPage: 1,
          },
        };
        return new QueryCollection<FacRanking>(resFormatted);
      })
    );
  }

  rateExam(examId: number, rating: number) {
    return this.http.post(ExamEndpoints.rateExam(examId), {
      rating,
    });
  }

  rateFolder(examId: number, folderId: number, data: RateFolder) {
    return this.http.post(ExamEndpoints.rateFolder(examId, folderId), data);
  }

  rateConftube(examId: number, rating: number) {
    return this.http.post(ExamEndpoints.rateConftube(examId), {
      rating,
    });
  }

  deleteCandidate(id: number) {
    return this.http.delete(ExamEndpoints.deleteCandidate(id));
  }

  getActivity(options: QueryData) {
    return this.http
      .get(ExamEndpoints.getActivity, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((sessionRaw) => {
            sessionRaw.tooltip =
              this.translate.instant('preview.general') +
              ' : ' +
              sessionRaw.positionWorld +
              ' - ' +
              this.translate.instant('account.ville') +
              ' : ' +
              sessionRaw.positionCity +
              ' - ' +
              this.translate.instant('account.fac') +
              ' : ' +
              sessionRaw.positionFac +
              ' - ' +
              this.translate.instant('preview.promotion') +
              ' : ' +
              sessionRaw.positionPromo;

            const session = new ExamSessionLog(sessionRaw);

            return session;
          });
          return new QueryCollection<ExamSessionLog>(res);
        })
      );
  }

  getHistogram(type: string, id: number) {
    return this.http.get(ExamEndpoints.getHistogram(type, id)).pipe(
      map((res: any) => {
        return new Histogram(res.data);
      })
    );
  }
}
