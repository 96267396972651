import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';

import { Challenge } from '../../../models/challenge.model';

@Component({
  selector: 'app-challenge-major',
  templateUrl: './challenge-major.component.html',
  styleUrls: ['./challenge-major.component.scss'],
})
export class ChallengeMajorComponent implements OnInit {
  @Input() challenge: Challenge;

  constructor(public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}
}
