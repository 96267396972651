import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../../../core/services/language.service';

@Component({
  selector: 'app-site-logo',
  templateUrl: './site-logo.component.html',
  styleUrls: ['./site-logo.component.scss'],
})
export class SiteLogoComponent implements OnInit {
  @Input() hideText;

  constructor(public languageService: LanguageService) {}

  ngOnInit() {}
}
