import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { timer, BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Challenger } from '../../shared/models/challenge.model';
import { EcniUser } from './../../shared/models/ecni-user.model';
import { MessageGroup } from './../../shared/models/message-group.model';

import { HttpMessageGroupService } from './http/http-message-group.service';
import { LOGIN_STATE, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  checkTimeSubscription: Subscription;
  messageGroupsUnread$ = new BehaviorSubject<number>(null);

  hideMobileNewConversationButton$ = new BehaviorSubject<boolean>(false);
  newMessageGroup$ = new BehaviorSubject<MessageGroup>(null);

  constructor(
    private userService: UserService,
    private httpMessageGroupService: HttpMessageGroupService,
    private router: Router
  ) {}

  init() {
    this.userService.loginState$.subscribe((loginState) => {
      if (loginState === LOGIN_STATE.LOGGED) {
        // update toutes les 5 minutes
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateMessagesUnread();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.messageGroupsUnread$.next(null);
      }
    });
  }

  updateMessagesUnread() {
    this.httpMessageGroupService.getNumberUnread().subscribe((res) => {
      this.messageGroupsUnread$.next(res);
    });
  }

  getMessagesUnread() {
    return this.messageGroupsUnread$.pipe(
      filter((res) => {
        return res !== null;
      })
    );
  }

  createTemporaryMessageGroup(users: EcniUser[]) {
    this.newMessageGroup$.next(
      new MessageGroup({
        usersData: users,
        read: true,
      })
    );

    this.router.navigate(['/app/mailbox/messaging']);
  }

  startMessageGroupFromChallengers(challengers: Challenger[]) {
    this.createTemporaryMessageGroup(
      challengers.map((challenger) => {
        return challenger.user;
      })
    );
  }
}
