<ng-container *ngIf="challenge.challengers[0]?.challengeCandidate?.grade !== null">
  <svg-icon
    class="absolute -left-4 top-0"
    src="assets/images/svg-illustrations/icon-trophy.svg"
    [svgStyle]="{ 'width.px': '23' }"
  ></svg-icon>

  <div
    class="nnki-rounded-background-image w-14 mr-4 shrink-0"
    [style.background-image]="
      sanitizerService.imageProfile(challenge.challengers[0]?.user.pictureUrl)
    "
  ></div>

  <div class="truncate">
    <div class="truncate text-13 font-bold mb-1">{{ challenge.challengers[0]?.user.username }}</div>
    <div class="text-11">
      {{ challenge.challengers[0]?.challengeCandidate.grade | number: '1.0-2':'fr' }}
      {{ 'examens.pts' | translate }}
    </div>
  </div>
</ng-container>
