import { environment } from '../../../environments/environment';

export class SocialEndpoints {
  static getFollowersRequests = `${environment.api_base_url}/follow/self/followers/requests`;
  static facebookImport = `${environment.api_base_url}/follow/facebook/import`;

  static stats(id: number) {
    return `${environment.api_base_url}/follow/${id}/stats`;
  }

  static getFollowed(id: number) {
    return `${environment.api_base_url}/follow/${id}/followings`;
  }

  static getFollowers(id: number) {
    return `${environment.api_base_url}/follow/${id}/followers`;
  }

  static follow(id: number) {
    return `${environment.api_base_url}/follow/${id}/add`;
  }

  static unfollow(id: number) {
    return `${environment.api_base_url}/follow/${id}/delete`;
  }

  static approve(id: number) {
    return `${environment.api_base_url}/follow/${id}/approve`;
  }

  static reject(id: number) {
    return `${environment.api_base_url}/follow/${id}/reject`;
  }
}
