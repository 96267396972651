import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { VersionCheck } from '../../../shared/models/version.model';

import { VersionEndpoints } from '../../../shared/endpoints/version.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpVersionService {
  constructor(private http: HttpClient) {}

  getCurrent() {
    return this.http.get(VersionEndpoints.getCurrent).pipe(
      map((res: any) => {
        return new VersionCheck(res);
      })
    );
  }

  checkVersion(version: string) {
    const options = {
      version,
    };
    return this.http
      .get(VersionEndpoints.checkVersion, {
        params: (options as unknown) as HttpParams,
      })
      .pipe(
        map((res: any) => {
          return new VersionCheck(res);
        })
      );
  }
}
