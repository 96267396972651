import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { CityFac } from '../../../shared/models/cityFac.model';
import { QueryCollection } from '../../../shared/models/collection.model';
import { Discipline } from '../../../shared/models/discipline.model';
import { Fac } from '../../../shared/models/fac.model';
import { Item } from '../../../shared/models/item.model';
import { Numbers } from '../../../shared/models/numbers.model';
import {
  DisciplineQueryData,
  ItemQueryData,
  QueryData,
} from '../../../shared/models/query-data.model';
import { Year } from '../../../shared/models/year.model';

import { QueryEndpoints } from '../../../shared/endpoints/query.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpQueryService {
  constructor(private http: HttpClient) {}

  queryDisciplines(options: DisciplineQueryData) {
    return this.http
      .get(QueryEndpoints.queryDisciplines, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((disciplineRaw) => {
            return new Discipline(disciplineRaw);
          });
          return new QueryCollection<Discipline>(res);
        })
      );
  }

  queryItems(options: ItemQueryData) {
    return this.http
      .get(QueryEndpoints.queryItems, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((itemRaw) => {
            return new Item(itemRaw);
          });
          return new QueryCollection<Item>(res);
        })
      );
  }

  queryCityFacs(options: QueryData) {
    return this.http
      .get(QueryEndpoints.queryCityFacs, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((cityRaw) => {
            return new CityFac(cityRaw);
          });
          return new QueryCollection<CityFac>(res);
        })
      );
  }

  queryFacs(options: QueryData) {
    return this.http
      .get(QueryEndpoints.queryFacs, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data
            .map((facRaw) => {
              return new Fac(facRaw);
            })
            .filter((fac) => fac.id !== 20 && fac.id !== 22);
          // nnkitodo : remove ugly fix to hide paris 5 + paris 7
          return new QueryCollection<Fac>(res);
        })
      );
  }

  queryYears(options: QueryData) {
    return this.http
      .get(QueryEndpoints.queryCityYears, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((yearRaw) => {
            return new Year(yearRaw);
          });
          return new QueryCollection<Year>(res);
        })
      );
  }

  queryNumbers() {
    return this.http.get(QueryEndpoints.queryNumbers).pipe(
      map((numRaw: any) => {
        const num = new Numbers(numRaw);
        return num;
      })
    );
  }
}
