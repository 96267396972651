import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { LocaleKeys } from '../../shared/utils/locale-keys';

declare let Pusher: any;

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: any;

  constructor() {
    this.pusher = new Pusher(environment.pusher_id, {
      cluster: 'eu',
      encrypted: true,
      authEndpoint: environment.api_base_url + '/../pusher/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(LocaleKeys.tokenId),
        },
      },
    });
    this.pusher.logToConsole = true;
  }

  subscribeTo(channelName: string) {
    this.pusher.subscribe(channelName);
  }

  unsubscribeTo(channelName: string) {
    this.pusher.unsubscribe(channelName);
  }
}
