import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { timer } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';

import { DisciplinesService } from '../../../../core/services/disciplines.service';
import { HttpChallengeService } from './../../../../core/services/http/http-challenge.service';
import { SanitizerService } from './../../../../core/services/sanitizer.service';
import { UserService } from './../../../../core/services/user.service';

import { BaseComponent } from '../../../components/inherited/base/base.component';

import {
  Challenge,
  ChallengeRanking,
  ChallengeRankingLevel,
} from '../../../models/challenge.model';
import { ExamCustom } from './../../../models/exam-custom.model';

@Component({
  selector: 'app-intro-candidate-challenge',
  templateUrl: './intro-candidate-challenge.component.html',
  styleUrls: ['./intro-candidate-challenge.component.scss'],
})
export class IntroCandidateChallengeComponent extends BaseComponent implements OnInit {
  @Input() exam: ExamCustom;
  @Input() challengeCode: string;

  @Output() launchEmitter = new EventEmitter();
  @Output() exitEmitter = new EventEmitter();

  challenge: Challenge;

  userRanking: ChallengeRanking;
  rankingLevels: ChallengeRankingLevel[];

  animationState = {
    discipline: false,
    versus: false,
    versusHeartbeat: false,
    user: false,
    challenger0: false,
    challenger1: false,
    challenger2: false,
    moreChallengers: false,
    panelLeft: false,
    panelRight: false,
    button: false,
  };

  isLoading = false;
  firstLoading = true;

  constructor(
    public userService: UserService,
    private httpChallengeService: HttpChallengeService,
    public sanitizerService: SanitizerService,
    public disciplinesService: DisciplinesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.httpChallengeService.getChallenge(this.challengeCode).subscribe((res) => {
      this.challenge = res;
    });

    this.httpChallengeService
      .getUserRanking('overall', this.userService.loggedUserValue.id)
      .subscribe((res) => {
        this.userRanking = res;
      });

    this.httpChallengeService
      .getUserRankingLevels(this.userService.loggedUserValue.id)
      .subscribe((res) => {
        this.rankingLevels = res;
      });
  }

  launchAnimationsEntry() {
    this.firstLoading = false;
    timer(0)
      .pipe(
        concatMap(() => {
          this.animationState.panelLeft = true;
          this.animationState.panelRight = true;
          this.animationState.button = true;
          return timer(100);
        }),
        concatMap(() => {
          this.animationState.discipline = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.user = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.versus = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.challenger0 = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.challenger1 = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.challenger2 = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.moreChallengers = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.versusHeartbeat = true;
          return timer(2000);
        }),
        concatMap(() => {
          this.launchAnimationsExit();
          return timer(150);
        }),
        first()
      )
      .subscribe();
  }

  launchAnimationsExit() {
    this.isLoading = true;
    this.launchEmitter.emit();
    timer(0)
      .pipe(
        concatMap(() => {
          this.animationState.versus = false;
          this.animationState.discipline = false;
          this.animationState.button = false;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.panelLeft = false;
          this.animationState.panelRight = false;
          this.animationState.versusHeartbeat = false;
          return timer(300);
        }),
        concatMap(() => {
          this.exitEmitter.emit();
          return timer(300);
        }),
        first()
      )
      .subscribe();
  }
}
