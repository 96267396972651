import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ModalAcceptNotificationsResult } from '../../models/modal-data';

@Component({
  selector: 'app-modal-accept-notifications',
  templateUrl: './modal-accept-notifications.component.html',
  styleUrls: ['./modal-accept-notifications.component.scss'],
})
export class ModalAcceptNotificationsComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ModalAcceptNotificationsComponent>) {}

  ngOnInit(): void {}

  close(accept: boolean) {
    const modalResult: ModalAcceptNotificationsResult = {
      accept,
    };
    this.dialogRef.close(modalResult);
  }
}
