<div class="user-image-sunray">
  <div class="sunray"></div>
  <div
    class="nnki-rounded-background-image cursor-pointer"
    [style.background-image]="sanitizerService.imageProfile(user?.pictureUrl)"
    (click)="openModalImage()"
  ></div>

  <div class="user-position" *ngIf="userRanking?.rank && userRanking?.rank != -1">
    <svg-icon
      src="assets/images/svg-icons/icon-medal.svg"
      [svgStyle]="{ 'width.px': '13' }"
    ></svg-icon>
    {{ userRanking.rank | number : '1.0-0' : 'fr' }}
  </div>

  <div class="user-badges" *ngIf="rankingLevels">
    <div class="user-badge" *ngFor="let level of rankingLevels.slice(0, 3).reverse()">
      <img src="assets/images/svg-illustrations/icon-challenge-{{ level.level }}.svg" />
    </div>
  </div>

  <div class="user-change-photo" *ngIf="canChangePhoto">
    <input
      type="file"
      name="avatar"
      class="file-input"
      id="avatar"
      (change)="avatarService.uploadAvatarDesktop($event)"
      accept=".jpg,.png,.jpeg"
      *ngIf="!isApplicationService.isApplication()"
    />

    <label
      matRipple
      [matRippleCentered]="false"
      [matRippleUnbounded]="true"
      [matRippleRadius]="50"
      class="btn-nnki btn-nnki-circular -no-border"
      for="avatar"
      (click)="isApplicationService.isApplication() ? avatarService.uploadAvatarMobile() : null"
      *ngIf="!avatarService.loadingUpload"
    >
      <svg-icon
        src="assets/images/svg-icons/icon-camera.svg"
        [svgStyle]="{ 'width.px': '23' }"
      ></svg-icon>
    </label>

    <app-loader-dots *ngIf="avatarService.loadingUpload"></app-loader-dots>
  </div>
</div>

<div class="user-infos">
  <ng-container *ngIf="user">
    <div class="user-username">
      {{ user.username }}
    </div>
    <div class="user-fac">
      {{ user.cityfac?.name + ' - ' + user.year?.name }}
    </div>

    <div class="user-points">
      {{
        userRanking?.score && userRanking?.score != -1
          ? (userRanking.score | number : '1.0-0' : 'fr') + ('examens.pts' | translate)
          : '-'
      }}
    </div>
  </ng-container>

  <div class="spinner_element_container" *ngIf="!user">
    <app-loader-dots></app-loader-dots>
  </div>
</div>
