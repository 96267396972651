import {
  parseIntMember,
  parseBooleanMember,
  parseFloatMember,
  parseDateMember,
  parseDateFromTimestampMember,
} from '../utils/parse-functions';

export class Offer {
  id: number;
  name: string;
  price: number;
  nbMonth: number;
  subscription: null | 'premium_plus' | 'elite';

  calculatedPrice: number;

  constructor(input: any) {
    parseIntMember(input, 'id');
    parseIntMember(input, 'price');
    parseIntMember(input, 'nbMonth');

    Object.assign(this, input);

    this.calculatedPrice = this.price;
  }
}

export class Coupon {
  valid: boolean;
  percent_off: number;

  constructor(input: any) {
    parseBooleanMember(input, 'valid');
    parseFloatMember(input, 'percent_off');
    Object.assign(this, input);
  }
}

export class ChargeParameters {
  paymentToken: string;
  couponId?: string;
  isFacility?: boolean;
}

export class ChargeResult {
  status: string;
  data: {
    client_secret: string;
  };
  intent: {
    status: string;
    data: {
      client_secret: string;
    };
  };

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class ScaConfirm {
  status: string;
  error: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class Transaction {
  createdAt: Date;
  offreText: string;
  amount: number;

  constructor(input: any) {
    parseDateMember(input, 'createdAt');
    parseFloatMember(input, 'amount');
    Object.assign(this, input);
  }
}

export class EcniSubscription {
  start: Date;
  end: Date;
  type: 'free' | 'premium-plus' | 'elite';
  paid: boolean;

  constructor(input: any) {
    if (input.start && input.start.date) {
      input.start = input.start.date;
    }

    if (input.end && input.end.date) {
      input.end = input.end.date;
    }

    parseDateMember(input, 'start');
    parseDateMember(input, 'end');
    parseBooleanMember(input, 'paid');
    Object.assign(this, input);
  }
}

export class Invoice {
  url: string;
  amount: number;

  constructor(input: any) {
    parseIntMember(input, 'amount');
    Object.assign(this, input);
  }
}

export class Refund {
  created: Date;
  amount: number;

  constructor(input: any) {
    parseDateFromTimestampMember(input, 'created');
    parseIntMember(input, 'amount');
    Object.assign(this, input);
  }
}

export interface PricingBlock {
  title: string;
  strong?: boolean;
  text?: string;
}

export interface PricingSection {
  title: string;
  icon: string;
  rows: PricingRow[];
  showAvailable?: boolean;
  availableInFree?: boolean;
  availableInPremiumPlus?: boolean;
  availableInElite?: boolean;
}

export interface PricingRow {
  title: string;
  text?: string;
  availableInFree: boolean;
  availableInPremiumPlus: boolean;
  availableInElite?: boolean;
}

export interface SortedOffers {
  premium_plus: Offer[];
  elite: Offer[];
  conversion: Offer[];
}
